.btn {
    @include bdrad;
    @include osR;
    @include fz-md;
    letter-spacing: 1.21px;
    line-height: 1;
    transition: all .2s ease;
    text-decoration: none;
    -webkit-appearance: none;
    text-align: center;
    position: relative;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    @include xs {
        font-size: 18px; }
    &__icon {
        font-size: 23px;
        @include xs {
            margin-left: 10px;
            font-size: 19px; }
        @include r(340) {
            margin-left: 5px;
            font-size: 18px; }
        .icon-list {
            margin-left: 10px; } }
    &_primary {
        color: $white;
        background-color: $aqua;
        border: 2px solid $aqua;
        padding-left: 30px;
        padding-right: 30px;
        &:hover {
            color: $aqua;
            background-color: $white; } }
    &_width-sm {
        min-width: 130px; }
    &_width-md {
        min-width: 160px; }
    &_width-xl {
        min-width: 180px; }
    &_width-lg {
        min-width: 200px;
        padding: 5px 15px; }
    &_xs {
        @include fz-xs;
        letter-spacing: 1.71px;
        padding: 4px 4px; }
    &_md {
        min-width: 250px;
        padding: 10px 30px;
        letter-spacing: 2.46px;
        @include xs {
            min-width: 240px; } }
    &_full-w {
        max-width: 100%;
        min-width: 100%;
        width: 100%; }
    &_mob-full {
        @include xs {
            min-width: 100%;
            width: 100%; } }
    &_white {
        background-color: $white;
        color: $dark-gray;
        border: 1px solid transparent;
        &:hover {
            background-color: transparent;
            color: $white;
            border: 1px solid $white; } }
    &_transparent {
        background-color: $white;
        color: $dark-gray;
        border: 1px solid $aqua;
        padding: 6px 23px 7px; }
    &_secondary {
        padding: 5px 15px;
        border: 1px solid $aqua;
        background-color: $white;
        color: $dark-gray;
        &:hover, &:active {
            color: $white;
            background-color: $aqua; } }

    &_height-xs {
        min-height: 30px; }
    &_height-sm {
        padding-top: 6px;
        padding-bottom: 6px;
        min-height: 40px; }
    &_height-md {
        padding-top: 9px;
        padding-bottom: 9px;
        min-height: 50px; }
    &_height-lg {
        padding-top: 18px;
        padding-bottom: 16px;
        min-height: 60px; }
    &_height-xl {
        min-height: 80px;
        @include xs {
            padding-top: 9.5px;
            padding-bottom: 13.5px;
            min-height: 45px; } }

    &_font-md {
        font-size: 23px; }
    &_font-sm {
        font-size: 18px; }
    &_font-xs {
        font-size: 16px;
        @include xs {
            font-size: 14px; } }
    &_font-xxs {
        font-size: 14px; }
    &_font-mob-xs {
        @include sm {
            font-size: 16px; }
        @include xs {
            font-size: 14px;
            letter-spacing: 0.94px; } }
    &_dib {
        display: inline-block;
        text-align: center; }
    &_mob-center {
        @include xs {
            display: block;
            margin-left: auto;
            margin-right: auto; } }
    &_center {
        display: block;
        margin: 0 auto; }
    &_centered {
        position: relative;
        left: 50%;
        transform: translateX(-50%); }
    &_font-rail {
        @include railR; }
    &_icon {
        padding-left: 15px;
        padding-right: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        @include r(340) {
            padding-left: 5px; } }
    &_lighter {
        background-color: #99cccc;
        border-color: #99cccc; }
    &_dark-font {
        color: $dark-gray; }
    &_blue,
    &_green {
        padding: 6px 23px 7px;
        background: $blue;
        color: $white;
        letter-spacing: 1.08px;
        transition: all .25s ease;
        border: 1px solid transparent;
        &:hover, &:active, &:focus {
            color: $blue;
            background-color: $white;
            border: 1px solid $blue; } }

    &_green {
        background: $aqua;
        &:hover, &:active, &:focus {
            color: $aqua;
            background-color: $white;
            border: 1px solid $aqua; } }
    &_blue-border {
        border: 1px solid $blue; }
    &_pad-sm {
        padding: 0 15px; }
    &_warning {
        border: 2px solid $red;
        background-color: $white;
        color: $red;
        &:hover {
            color: $white;
            background-color: $red; } }
    &_cancel {
        min-width: 94px;
        min-height: 35px;
        padding: 5px;
        line-height: normal;
        @include railSB;
        font-size: 15px; }
    &_next {
        font-size: 18px;
        @include osR;
        letter-spacing: 1.2px;
        height: 40px;
        width: 75px;
        border: 1px solid transparent;
        background-color: #009999;
        color: $white;
        float: right;
        @include xs {
            font-size: 14px;
            height: 30px;
            width: 65px; }
        &:hover {
            border: 1px solid #3c6996;
            background-color: $white;
            color: $dark-gray; } }
    &_back {
        font-size: 18px;
        @include osR;
        letter-spacing: 1.2px;
        height: 40px;
        width: 75px;
        background-color: transparent;
        color: $dark-gray;
        float: left;
        border: 1px solid #3c6996;
        @include xs {
            font-size: 14px;
            height: 30px;
            width: 65px; }
        &:hover {
            background-color: $white;
            color: $dark-gray; } }
    &_tertiary {
        background: #999;
        border: 2px solid #999;
        color: $dark-gray;
        &:hover {
            background: #fff;
            border: 2px solid #999;
            color: $dark-gray; } }
    &.is-disabled {
        background: #999;
        border: 2px solid #999;
        color: $dark-gray;
        cursor: not-allowed;
        &:hover {
            background: #999;
            border: 2px solid #999;
            color: $dark-gray; } }

    &_margin-bottom {
        margin-bottom: 100px; }

    &_img {
        img {
            max-width: 250px; } }

    &_flex_right {
        margin-left: auto !important; } }


.btn-advice {
    padding-top: 8px;
    text-align: center;
    width: 45px;
    height: 45px;
    border-radius: 50%;
    background: $light-aqua;
    cursor: pointer;
    &__text {
        margin-bottom: 1px;
        line-height: 1;
        font-size: 12px;
        letter-spacing: 0.5px;
        @include osB; } }

.btn-back {
    line-height: 1;
    position: relative;
    @include osR;
    text-decoration: none;
    font-size: 23px;
    color: $purple;
    display: inline-block;
    padding-left: 15px;
    @include xs {
        font-size: 14px; }
    &:before {
        position: absolute;
        content: '';
        width: 10px;
        height: 10px;
        left: 0;
        top: 7px;
        border: 1px solid $purple;
        border-right: none;
        border-top: none;
        transform: rotate(45deg);
        @include xs {
            top: 4px;
            width: 7px;
            height: 7px; } }
    &_spec {
        @include fz-common;
        letter-spacing: 1.08px;
        padding-left: 20px;
        margin: 0 0 50px; }
    &_mob-xs {
        @include rmin(1024) {
            display: none; }
        @include md {
            font-size: 14px;
            letter-spacing: 0.98px;
            padding-left: 12px; }
        &:before {
            width: 9px;
            height: 9px;
            top: 3px; } }
    &_dark-font {
        color: $dark-gray;
        &:before {
            top: 3px;
            width: 12px;
            height: 12px;
            border: 3px solid #9b9b9b;
            border-right: none;
            border-top: none; } }
    &_purple-font {
        color: $purple;
        margin-top: 40px;
        &:before {
            top: 3px;
            width: 12px;
            height: 12px;
            border: 3px solid $purple;
            border-right: none;
            border-top: none; } }

    &_big {
        font-size: 18px; }

    &_messages {
        margin-top: 10px;

        @include xs {
            margin: 0 0 10px 0;
            font-size: 14px;
            &:before {
                top: 2px;
                width: 10px;
                height: 10px; } } } }

.cancellation-btn {
    min-height: 50px;
    width: 177px;
    @include osR;
    font-size: 16px;
    letter-spacing: 1.1px;
    line-height: normal;
    transition: all .2s ease;
    text-decoration: none;
    -webkit-appearance: none;
    text-align: center;
    position: relative;
    cursor: pointer;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border-radius: 20px;
    padding: 0 10px;
    &:hover {
        text-decoration: none !important; }

    @include xs {
        font-size: 14px;
        letter-spacing: 0.94px;
        min-height: 45px; }
    &_primary {
        background-color: $white;
        color: $dark-gray !important;
        &:hover {
            background-color: #009999;
            color: $white !important; } }
    &_secondary {
        background-color: #009999;
        color: $white;
        &:hover {
            background-color: $white;
            color: $dark-gray; } } }


.btn-clear {
    box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.5);
    background-color: #d8d8d8;
    text-decoration: none;
    width: 60px;
    height: 30px;
    @include bdrad;
    font-size: 16px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    letter-spacing: 1.1px;
    @include osR;
    color: $dark-gray;
    cursor: pointer;
    transition: all .15s ease;
    @include xs {
        font-size: 14px;
        letter-spacing: 0.94px; }
    &:active {
        box-shadow: none; } }


.aprove-btns {
    display: flex;
    flex-direction: column;
    align-items: center;

    form {
        width: 100%; }

    .btn {
        width: 120px;
        height: 44px;
        @include md {
            width: 68px;
            height: 35px;
            font-size: 14px; }
        @include xs {
            width: 100%;
            max-width: 262px;
            height: 35px;
            letter-spacing: 1px; } } }
