.review-main {
    $this: &;
    display: flex;
    padding-bottom: 25px;
    padding-top: 40px;
    padding-left: 60px;
    margin-right: 60px;
    border-bottom: 1px solid #979797;
    @include md {
        margin-right: 0;
        padding-right: 30px; }
    @include xs {
        padding-left: 25px;
        padding-right: 25px;
        padding-top: 25px;
 }        // display: block
    &__left {
        width: 120px;
        flex: 0 0 120px;
        margin-right: 30px;
        @include xs {
            margin-right: 25px; }
        a {
            text-decoration: none;
            color: $dark-gray; } }
    &__right {
        flex: 1; }
    &__image {
        width: 120px;
        height: 73px;
        border: solid 1px #979797;
        margin-bottom: 24px;
        margin-right: 30px;
        @include xs {
            margin-bottom: 10px;
            margin-right: 25px; }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover; } }
    &__avatar {
        display: block;
        text-decoration: none;
        width: 100px;
        height: 100px;
        overflow: hidden;
        margin: 0 auto;
        border-radius: 50%;
        @include xs {
            width: 50px;
            height: 50px; }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover; } }
    &__name {
        font-size: 16px;
        @include osR;
        color: $dark-gray;
        letter-spacing: 1.1px;
        text-align: center;
        margin-top: 8px;
        text-decoration: none;
        @include xs {
            font-size: 14px;
            letter-spacing: 0.94px; }
        &_desktop {
            @include md {
                display: none; } }
        &_adapt {
            text-align: left;
            margin-bottom: 3px;
            @include rmin(1024) {
                display: none; } } }

    &__place {
        @include osSB;
        color: $dark-gray;
        font-size: 16px;
        letter-spacing: 1.1px;
        @include xs {
            font-size: 14px;
            letter-spacing: 0.94px; } }
    &__rating {
        margin-bottom: 7px; }
    &__wrapper {
        max-width: 714px;
        &_lg {
            max-width: 755px; }
        &_xl {
            max-width: 865px; } }
    &__btn {
        text-align: right;
        margin-top: 20px;
        @include xs {
            .btn {
                font-size: 14px;
                letter-spacing: 0.94px;
                min-height: 30px;
                padding-left: 10px;
                padding-right: 10px;
                min-width: 100px; } } }
    &__textarea {
        margin-top: 20px;

        .error-text {
            display: none; }

        .has-error {
            textarea {
                background-color: #fff8f9;
                border: 2px solid #d0021b; }

            .error-text {
                display: block; } } }

    &__meta {
        margin-bottom: 8px;
        @include xs {
            margin-bottom: 4px; } }
    &__text {
        margin-top: 30px;
        @include osR;
        color: $dark-gray;
        font-size: 16px;
        letter-spacing: 1.1px;
        position: relative;
        max-height: 90px;
        overflow: hidden;
        @include xs {
            font-size: 14px;
            letter-spacing: 0.94px;
            max-height: 265px;
            margin-top: 15px; }
        p:not(:last-of-type) {
            padding-bottom: 10px; }
        &.is-open {
            max-height: none;
            padding-bottom: 25px; } }
    &__show-more {
        position: absolute;
        right: 0;
        bottom: 0;
        background-color: $white;
        padding: 2px 6px;
        .show-more {
            box-shadow: -12px 0px 15px #fff;
            display: none;
            &.is-open {
                box-shadow: none; } } }
    &_response {
        padding-bottom: 50px;
        display: block;
        @include clr;
        @include xs {
            padding-bottom: 18px; }
        #{$this}__left {
            margin-right: 50px;
            width: 100px;
            float: left;
            @include xs {
                margin-right: 20px;
                width: auto;
                margin-top: 6px; } }
        #{$this}__right, #{$this}__wrapper {
            overflow: hidden; }
        #{$this}__wrapper {
            @include md {
                width: 100%; } }
        #{$this}__meta {
            margin-bottom: 20px;
            color: #9b9b9b;
            letter-spacing: 0.9px;
            @include md {
                margin-bottom: 5px; } }
        #{$this}__text {
            margin-top: 10px; }
        #{$this}__place {
            margin-top: 15px;
            @include xs {
                margin-top: 8px; } }
        #{$this}__name {
            @include xs {
                margin-top: 0; } }
        #{$this}__btn {
            @include xs {
                margin-top: 13px;
                .btn {
                    font-size: 14px;
                    letter-spacing: 0.94px;
                    min-height: 40px;
                    padding-left: 10px;
                    padding-right: 10px;
                    min-width: 100%; } } } }
    &_mob-wrap {
        @include xs {
            display: block;
            #{$this}__left {
                margin-bottom: 10px;
                width: 100%; }
            #{$this}__image {
                width: 100%;
                max-width: 400px;
                height: auto;
                border: none; } } }

    &__reply {
        .review-main {
            border: none;
            padding-left: 30px;
            @include md {
                padding-left: 0;
                padding-right: 0; } } } }
