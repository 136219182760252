.hero {
  padding-top: 5%;
  padding-bottom: 7.8%;
  min-height: 546px;
  position: relative;
  @include md {
    min-height: 450px;
    padding-bottom: 8.5%; }
  @include xs {
    padding-bottom: 40px; }
  &_pb-md-min {
    padding-bottom: 5.5%;
    @include xs {
      padding-bottom: 29px; } }
  &__bg {
    position: absolute;
    left: 0;
    top: 0;
    z-index: -1;
    width: 100%;
    height: 100%;
    background-size: cover;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    transition: all .8s ease;
    @include md {
      opacity: 0.8; } }

  &__title {
    color: $white;
    @include fz-lg;
    @include railB;
    letter-spacing: 1.08px;
    text-align: center;
    max-width: 915px;
    margin: 0 auto;
    margin-bottom: 37px;
    padding-top: 107px;
    line-height: 1.2;
    @include md {
      font-size: 42px;
      line-height: 1.4;
      padding: 100px 30px 30px; }
    @include xs {
      @include fz-lg-m;
      letter-spacing: 3.42px;
      padding-bottom: 0;
      margin-bottom: 30px; }
    &_max {
      max-width: 100%; }
    &_mb {
      margin-bottom: 38px;
      @include r(991) {
        margin-bottom: 0;
        padding-bottom: 24px; } }
    &_ls-xs-min {
      letter-spacing: 1.67px; }
    &_text-sm-left {
      @include sm {
        text-align: left;
        padding-left: 0;
        padding-right: 0; } }
    &_px-sm-0 {
      @include sm {
        padding-left: 0;
        padding-right: 0; } }
    &_pt-sm-min {
      padding-top: 86px; }
    &_lh-xs-min {
      @include xs {
        line-height: 1.2; } }
    &_shadow-tablet {
      @include md {
        text-shadow: 3px 3px 6px rgba(0, 0, 0, 0.5); } } }
  &__form {
    text-align: center;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    @include sm {
      display: block;
      max-width: 240px;
      margin-left: auto;
      margin-right: auto; }
    .field {
      &:not(:last-child) {
        margin-right: 6px;
        @include md {
          margin-right: 4px;
          margin-bottom: 10px; } } } }
  &__btn {
    margin-left: 2px;
    @include md {
      margin-left: 0; }
    @include xs {
      margin-top: 10px; } }
  &_dark-overlay {
    &:before {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background-color: $dark-gray;
      opacity: 0.8;
      z-index: -1; } }
  &__container {
    max-width: 1125px;
    padding: 0 30px;
    margin: 0 auto;
    @include sm {
      padding: 0 15px; } }
  .list-icons {
    @include r(991) {
      padding-left: 33px;
      margin-bottom: 39px; }
    @include sm {
      padding-left: 0;
      margin-bottom: 23px; } }

  &__product-hunt {
    margin: 45px auto 0;
    width: 250px;
    position: relative;
    left: -18px;

    @include sm {
      left: 0; } } }
