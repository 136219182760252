.accordion {
    &__title {
        cursor: pointer;
        padding: 20px 0;
        width: 100%;
        position: relative;
        @include sm {
            padding: 10px 0; }
        &_sm {
            .title {
                min-width: 140px;
                display: inline-block;
                vertical-align: middle;
                margin-right: 10px;
                @include sm {
                    min-width: auto; } } } }
    &__close-icon {
        display: inline-block;
        vertical-align: middle;
        width: 20px;
        height: 20px;
        position: relative;
        transform: scale(1, 1);
        transition: all .25s ease;
        &:before, &:after {
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            height: 2px;
            width: 16px;
            background-color: $dark-gray;
            @include sm {
                width: 10px; } }
        &:before {
            transform: rotate(45deg);
            right: 11px;
            @include sm {
                right: 6px; } }
        &:after {
            transform: rotate(-45deg); }
        @include sm; }

    &__content {
        @include bdrad;
        display: none;
        margin-bottom: 10px;
        padding: 15px 10px;
        border: 1px solid #979797;
        @include xs {
            border: none;
            padding: 0; } }
    &__checkbox {
        display: inline-block;
        vertical-align: middle;
        width: calc(50% - 3px);
        @include md {
            width: 100%; }
        &_sm {
            width: 38%;
            @include md {
                width: 100%; } } }
    &__item {
        border-bottom: 2px solid rgba(119, 119, 119, 0.6);
        &.is-open {
            .accordion__close-icon {
                transform: scale(-1, -1);
                margin-left: -7px;
                @include sm {
                    margin-left: 4px; } } } } }
