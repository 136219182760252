.control-bar {
    border-top: 1px solid #0e76bc;
    border-bottom: 1px solid #0e76bc;
    $this: &;
    &_no-brt {
        border-top: none; }
    &__inner {
        display: flex;
        min-height: 110px;
        align-items: center;
        max-width: 1135px;
        margin: 0 auto;
        padding-top: 15px;
        padding-bottom: 15px;
        flex-wrap: wrap;
        justify-content: space-between;
        @include m {
            padding-left: 6vw;
            padding-right: 6vw; }
        @include md {
            padding-left: 25px;
            padding-right: 25px; }
        @include xs {
            min-height: 100px;
            padding-left: 15px;
            padding-right: 15px; } }
    &__left {
        @include xs {
            width: 100%; } }
    &__buttons {
        @include xs {
            display: flex;
            .btn {
                width: 50%;
                @include r(340) {
                    font-size: 16px; }
                &:not(:last-child) {
                    margin-right: 15px; }

                &_transparent {
                    padding: 0 15px; } } } }

    &__filters {
        @include xs {
            display: block !important;
            position: absolute;
            left: 0;
            transition: 0s;
            max-height: 1px;
            width: 100%;
            background-color: white;
            padding: 0 15px;
            //overflow: hidden
            opacity: 0;
            visibility: hidden;
            z-index: 99;

            &.opened {
                max-height: 650px;
                padding: 15px;
                border-bottom: 1px solid #0e76bc;
                opacity: 1;
                visibility: visible;
                transition: .4s;

                .select {
                    margin-top: 10px; }

                .select,
                .input,
                .custom-select,
                .custom-select__text {
                    width: 100% !important; } }

            .field_secondary input {
                height: 38px; } } }

    &_map-half {
        #{$this}__inner {
            padding-left: 30px;
            padding-right: 30px;
            max-width: none;
            @include md {
                padding-left: 15px;
                padding-right: 15px; } }

        &_lg {
            .custom-select__text {
                width: 224px; } }
        &_md {
            .custom-select__text, .field {
                width: 135px; } } }
    .select-row {
        font-size: 0;
        display: flex;
        flex-wrap: wrap;
        .custom-select, .select, .input {
            display: inline-block;
            vertical-align: middle;
            margin-bottom: 5px;
            &:not(:last-child) {
                margin-right: 15px;

                @include md {
                    margin-right: 12px; } } }

        &__filters {
            display: inline-block;
            max-width: 700px;

            @include xs {
                width: 100%; } }

        &__button {
            display: inline-flex;
            align-items: center;

            @include r(599) {
                width: 100%;
                justify-content: space-between; } }

        .select {
            &_lg {
                .custom-select__text {
                    width: 270px; } }
            &_lg-narrow {
                .custom-select__text {
                    width: 250px; } }
            &_md {
                .custom-select__text {
                    width: 150px; } }
            &_sm {
                .custom-select__text {
                    width: 120px; } }
            &_xs {
                .custom-select__text {
                    width: 100px; } } } }

    &__toogle-map {
        padding-top: 9px;

        @include md {
            padding-top: 20px; }
        @include r(761) {
            padding-bottom: 20px; } }

    .custom-select__text, .custom-select__input {
        padding-left: 5px;
        padding-right: 30px; }

    .input {
        &_lg {
            .field__input {
                width: 270px; } }

        &_lg-narrow {
            .field__input {
                width: 250px; } }
        &_md {
            .field__input {
                width: 150px; } }
        &_sm {
            .field__input {
                width: 120px; } }
        &_xs {
            .field__input {
                width: 100px; } }

        @include xs {
            .field__input {
                width: 100% !important; } } }

    .field_secondary input.active {
        border-color: $aqua;
        box-shadow: 0 0 25px -10px $aqua;
        animation-name: flash;
        animation-duration: 2s;
        animation-fill-mode: both; } }

@keyframes flash {
    from, 50%, to {
        opacity: 1; }
    25%,75% {
        opacity: 0; } }

.filters {
    &__additional + &__additional {
        margin-left: 10px; }

    &__additional {
        display: inline-block;
        font-size: 16px;
        line-height: 23px;
        top: 6px;
        padding-left: 10px;
        padding-right: 10px; } }


