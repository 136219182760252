.non-profits {
  padding: 59px 30px 77px;
  @include md {
    padding-left: 20px;
    padding-right: 20px; }
  @include xs {
    background-color: $light-aqua;
    padding: 40px 20px; }
  &__inner {
    @include xs {
      padding: 0 20px; } }
  &__title {
    text-align: center;
    margin-bottom: 35px;
    @include md {
      margin-bottom: 20px; } }
  &__btn {
    text-align: center;
    margin-top: 40px; }
  &__text {
    max-width: 915px;
    margin: 0 auto;
    @include xs {
      .text {
        text-align: center; } } }
  &__consideration {
    margin-top: 74px;
    margin-bottom: 40px;
    @include xs {
      display: none; } } }

.non-profit {
  &__application {
    margin: 45px 0;

    @include xs {
      margin: 25px 0; } }

  &__text {
    @include osR;
    color: $dark-gray;
    font-size: 18px;
    letter-spacing: 1.21px;
    line-height: 1.39em;
    margin-bottom: 22px;
    padding-top: 20px;

    @include xs {
      font-size: 14px;
      letter-spacing: 0.94px;
      line-height: 2.14em; }

    ul {
      margin: 10px 0; } }

  &__success {
    font-size: 24px;
    line-height: 1.8;
    padding-top: 140px;

    @include xs {
      font-size: 18px;
      padding-top: 50px; } } }
