.chat-min {
    height: 81px;
    overflow: hidden;
    background-color: $white;
    padding: 15px 25px;
    display: flex;
    align-items: center;
    border-bottom: 1px solid #979797;
    position: relative;
    text-decoration: none;
    color: $dark-gray;
    font-size: 16px;
    letter-spacing: 1.08px;
    @include osR;
    @include xs {
        height: 71px;
        padding: 15px; }
    &:first-child {
        border-top: 1px solid #979797; }
    &__body {
        flex-grow: 1;
        width: 50%; }
    &__avatar {
        width: 50px;
        height: 50px;
        overflow: hidden;
        border-radius: 50%;
        margin-right: 17px;
        min-width: 50px;
        max-width: 50px;
        flex-grow: 0;

        @include xs {
            width: 44px;
            height: 44px;
            flex: 0 0 44px;
            margin-right: 10px; }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover; } }
    &__top-row {
        display: flex;
        justify-content: space-between;
        align-items: flex-start;
        flex-grow: 1;
        @include sm {
            flex-direction: column-reverse; } }
    &__title {
        font-size: 16px;
        color: $dark-gray;
        letter-spacing: 1.1px;
        @include osSB;
        flex-grow: 1;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        margin-bottom: 5px;
        @include sm {
            margin-bottom: 0; }
        @include xs {
            font-size: 12px;
            letter-spacing: 0.81px;
            width: 100%; } }
    &__meta {
        margin-left: 15px;
        flex-grow: 0;
        font-size: 14px;
        color: $dark-gray;
        letter-spacing: 0.9px;
        @include osR;
        @include sm {
            flex: 1;
            width: auto;
            margin-left: 0; }
        @include xs {
            font-size: 12px;
            letter-spacing: 0.81px; } }
    &__text {
        font-size: 16px;
        color: $dark-gray;
        letter-spacing: 1.1px;
        @include osR;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        @include xs {
            font-size: 12px;
            letter-spacing: 0.81px;
            width: 100%; } }
    &.is-active {
        @include rmin(1024) {
            background-color: #99cccc; } }
    &.is-unread {
        &:before {
            content: '';
            position: absolute;
            left: 60px;
            width: 12px;
            height: 12px;
            background-color: #009999;
            border-radius: 50%;
            top: 15px;
            border: solid 1px white;

            @include xs {
                width: 10px;
                height: 10px;
                left: 55px; } } }

    &.has-attachment {
        &:before {
            content: '\E93B';
            position: absolute;
            font-family: icomoon;
            font-size: 26px;
            right: 0;
            top: 50%;
            transform: translateY(-50%); } } }
