.sidebar {
    position: fixed;
    height: 100vh;
    left: 0;
    top: 0;
    background-color: #33669a;
    width: 208px;
    z-index: 120;
    transition: transform .25s ease;
    @include sm {
        transform: translateX(-100%) translateZ(0);
        &.is-open {
            transform: translateX(0) translateZ(0); } }
    @include xs {
        width: 196px; }
    &__inner {
        padding-top: 100px;
        height: 100%;
        @include sm {
            padding-top: 79px; }
        @include xs {
            padding-top: 60px; } }
    &__content {
        max-height: 100%;
        padding-bottom: 100px;
        overflow: auto; }

    &__nav {
        padding-bottom: 40px; }
    &__title {
        width: 100%;
        text-align: center;
        color: $white;
        letter-spacing: 2.46px;
        font-size: 23px;
        @include osR;
        border-bottom: 2px solid #979797;
        padding: 20px;
        position: relative;
        overflow: hidden;
        text-overflow: ellipsis;
        transition: .3s;
        @include xs {
            font-size: 18px;
            padding: 10px; }
        span {
            display: inline-block;
            position: relative;
            white-space: nowrap;
            max-width: 90%; }

        &:hover,
        &:active,
        &:focus {
            color: $light-aqua; }

        &.has-messages {
            span {
                &:before {
                    content: '';
                    position: absolute;
                    width: 18px;
                    height: 18px;
                    border-radius: 50%;
                    background-color: #099;
                    top: -6px;
                    right: -9px;
                    @include xs {
                        width: 12px;
                        height: 12px;
                        top: -4px; } } } } } }

.sidebar__content {
    &::-webkit-scrollbar {
        width: 7px; }
    &::-webkit-scrollbar-thumb {
        border-radius: 7px;
        background-color: #ccc; } }
