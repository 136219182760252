.is-draft {
  &:after {
    content: '';
    position: fixed;
    top: -500%;
    left: -500%;
    width: 1000%;
    height: 1000%;
    background: url("../img/draft.png") repeat;
    background-size: 256px auto;
    transform: rotate(-45deg);
    opacity: .3; } }
