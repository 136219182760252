.field_secondary {
  &.is-error {
    textarea {
      background-color: #fff8f9;
      border: 2px solid #d0021b;
      box-shadow: none; } } }

.steps-form .field {
  vertical-align: top; }

.header {
  z-index: 1150; }

.booking-notification__actions form.button_to {
  display: inline; }
