.checkbox-tabs {
    display: flex; }

.checkbox-tab {
    flex: 1;
    width: 66px;
    height: 55px;
    position: relative;
    @include xs {
        height: 44px; }
    @include r(340) {
        height: 40px; }
    input {
        opacity: 0;
        visibility: hidden; }
    label {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        text-align: center;
        border: 1px solid #979797;
        @include bdrad;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        font-size: 16px;
        letter-spacing: 1.08px;
        color: $dark-gray;
        @include osR;
        font-weight: 400;
        @include md {
            font-size: 14px;
            letter-spacing: 0.94px; }
        @include r(340) {
            font-size: 12px; } }
    input:checked + label {
        background-color: #99cccc;
        border: 1px solid transparent; }
    &.is-error {
        label {
            border: 2px solid $red; } } }
