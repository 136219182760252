.no-results {
    text-align: center;
    &__btn {
        margin-top: 50px;
        @include md {
            margin-top: 40px;
            text-align: center; } }
    &__info {
        text-align: center;
        margin-top: 20px;
        @include xs {
            margin-top: 0; }
        p {
            color: $dark-gray;
            @include osR;
            font-size: 23px;
            font-weight: 400;
            letter-spacing: 1.71px;
            &:not(:last-of-type) {
                margin-bottom: 25px; }
            @include xs {
                font-size: 14px; } } }
    &__back-link {
        text-align: center;
        margin-top: 40px;
        @include xs {
            margin-top: 25px; } }
    a {
        @include osR;
        font-weight: 400;
        letter-spacing: 1.71px;
        text-decoration: none;
        color: #984a9d;
        font-size: 20px;
        @include xs {
            font-size: 14px; }
        &:hover {
            text-decoration: underline; } } }
