.pagination {
    $this: &;
    &__numb-wrap {
        display: flex;
        justify-content: center;
        align-items: center; }
    &__amount {
        @include osR;
        font-weight: 400;
        font-size: 20px;
        letter-spacing: 0.88px;
        color: $dark-gray;
        margin-top: 20px;
        text-align: center;
        @include md {
            font-size: 18px; } }
    &__numb {
        width: 50px;
        height: 50px;
        background-color: $white;
        border-radius: 50%;
        margin: 0 12px;
        color: $dark-gray;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 1;
        border: 1px solid transparent;
        cursor: pointer;
        @include r(1200) {
            margin: 0 5px; }
        @include md {
            width: 40px;
            height: 40px; }

        @include r(820) {
            width: 30px;
            height: 30px;
            margin: 0 5px; }
        @include r(500) {
            margin: 0 2px; }
        a {
            color: $dark-gray;
            text-decoration: none;
            @include osSB;
            font-size: 24px;
            font-weight: 600;
            letter-spacing: 0.88px;
            @include md {
                font-size: 20px; }
            @include r(820) {
                font-size: 15px; } }

        &.is-active {
            border: 1px solid #009999;
            background-color: #009999;
            color: $white;
            font-size: 24px;
            display: flex;
            align-items: center;
            line-height: 1;
            justify-content: center;
            @include md {
                font-size: 20px; }
            @include r(820) {
                font-size: 15px; }
            a {
                color: $white; } } }
    .gap {
        font-size: 24px;
        margin: 0 12px;
        @include md {
            font-size: 20px;
            margin: 0 5px; }
        @include r(500) {
            margin: 0 2px; }
        @include r(820) {
            font-size: 15px; } }


    &__left, &__right {
        position: relative;
        width: 50px;
        height: 50px;
        border: 1px solid #009999;
        background-color: $white;
        border-radius: 50%;
        cursor: pointer;
        @include md {
            width: 40px;
            height: 40px; }
        @include r(820) {
            width: 30px;
            height: 30px; }
        @include r(400) {
            display: none; }
        &:before, &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            width: 13px;
            height: 2px;
            background-color: #009999;
            border-radius: 3px;
            @include md {
                width: 10px; } }
        &:before {
            top: 46%;
            left: 47%;
            transform: translate(-50%, -50%) rotate(-25deg);
            @include md {
                top: 45%; }
            @include r(820) {
                top: 43%; } }
        &:after {
            top: 57%;
            left: 47%;
            transform: translate(-50%, -50%) rotate(25deg);
            @include md {
                top: 55%; }
            @include r(820) {
                top: 57%; } }
        a {
            display: block;
            width: 100%;
            height: 100%; } }
    &__left {
        margin-right: 33px;
        @include r(1200) {
            margin-right: 25px; }
        @include r(820) {
            margin-right: 15px; }
        @include r(500) {
            margin-right: 10px; } }
    &__right {
        margin-left: 33px;
        @include r(1200) {
            margin-left: 15px; }
        @include md {
            margin-left: 10px; }
        @include r(500) {
            margin-left: 5px; }
        &:before {
            left: 52%;
            transform: translate(-50%, -50%) rotate(-155deg); }
        &:after {
            left: 52%;
            transform: translate(-50%, -50%) rotate(155deg); } }
    &_half {
        #{$this}__numb {
            width: 30px;
            height: 30px;
            margin: 0 6px;
            @include r(1200) {
                margin: 0 5px; }
            @include md {
                width: 28px;
                height: 28px;
                font-size: 16px;
                margin: 0 2px; }
            @include r(820) {
                font-size: 14px; }
            a {
                font-size: 18px;
                @include md {
                    font-size: 16px; }
                @include r(820) {
                    font-size: 14px; } }
            &.is-active {
                font-size: 18px;
                @include md {
                    font-size: 16px; }
                @include r(820) {
                    font-size: 14px; } } }
        .gap {
            font-size: 18px;
            margin: 0 6px;
            @include md {
                font-size: 16px; }
            @include r(820) {
                font-size: 14px; } }
        #{$this}__left, #{$this}__right {
            width: 30px;
            height: 30px;
            @include r(820) {
                display: none; }
            &:before {
                top: 44%;
                left: 45%; }
            &:after {
                top: 59%;
                left: 45%;
                @include md {
                    top: 58%; } } }
        #{$this}__left {
            margin-right: 12px; }
        #{$this}__right {
            margin-left: 12px;
            @include md {
                margin-left: 10px; }
            &:before {
                left: 52%;
                transform: translate(-50%, -50%) rotate(-155deg); }
            &:after {
                left: 52%;
                transform: translate(-50%, -50%) rotate(155deg); } } } }



