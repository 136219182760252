.section-info {
	padding: 86px 20px;
	@include md {
		padding: 76px 20px 78px; }
	@include sm {
		padding: 36px 24px 40px; }
	&__title {
		color: #4a4a4a;
		font-size: 45px;
		letter-spacing: 2.46px;
		@include railR;
		margin-bottom: 49px;
		text-align: center;
		@include md {
			font-size: 42px;
			letter-spacing: 2.3px;
			margin-bottom: 38px; }
		@include sm {
			font-size: 32px;
			letter-spacing: 1.75px;
			text-align: left;
			margin-bottom: 14px; } }
	&__description {
		color: #4a4a4a;
		font-size: 23px;
		letter-spacing: 1.08px;
		@include osR;
		max-width: 915px;
		margin: 0 auto 34px auto;
		text-align: center;
		@include md {
			font-size: 18px;
			letter-spacing: 0.85px;
			margin-bottom: 38px; }
		@include sm {
			font-size: 16px;
			letter-spacing: 0.75px;
			text-align: left;
			margin-bottom: 20px; } }
	&__icons {
		font-size: 50px;
		display: flex;
		justify-content: space-between;
		align-items: center;
		padding: 0 15vw;
		@include md {
			font-size: 39px;
			padding: 0 29px; }
		@include sm {
			font-size: 16px;
			padding: 0; } }
	&__icon {} }
