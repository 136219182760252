.select {
    &_xs {
        .custom-select__text {
            padding-right: 20px; } } }

.custom-select__list {
  li {
    &.disabled {
      display: none;
      //background-color: #efefef
      //color: grey
 } } }      //cursor: not-allowed
