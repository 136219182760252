.switch {
    font-size: 0;
    position: relative;
    $this: &;
    &__label {
        display: inline-block;
        vertical-align: middle;
        font-size: 18px;
        @include osR;
        color: $dark-gray;
        letter-spacing: 0.75px;
        margin-right: 7px;
        cursor: pointer;
        @include md {
            font-size: 16px; }
        &_text-common {
            font-size: 16px;
            letter-spacing: 1.08px;
            @include xs {
                font-size: 14px;
                letter-spacing: 0.94px; } } }
    &__checkbox {
        display: inline-block;
        vertical-align: middle;
        border-radius: 20px;
        background-color: #d8d8d8;
        width: 68px;
        height: 34px;
        position: relative;
        z-index: 2; }

    &__circle {
        width: 35px;
        height: 34px;
        position: absolute;
        left: 0;
        top: 0;
        border: 1px solid #d8d8d8;
        background-color: $white;
        border-radius: 20px;
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
        transform: translateX(0) translateZ(0);

        &:before, &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 13px;
            height: 1px;
            background-color: #9b9b9b; }
        &:before {
            transform: translate(-50%, -50%) translateZ(0) rotate(55deg); }
        &:after {
            transform: translate(-50%, -50%) translateZ(0) rotate(-55deg); } }
    input {
        opacity: 0;
        position: absolute;
        z-index: 5;
        width: 70px;
        height: 100%;
        right: 0;
        top: 0;
        cursor: pointer; }
    input:checked {
        & + .switch__checkbox {
            background-color: #009999;
            .switch__circle {
                transform: translateX(100%) translateZ(0);
                border: 1px solid #33cccc;
                &:before, &:after {
                    background-color: #009999; }
                &:before {
                    width: 5px;
                    transform: translate(-8px, 3px) translateZ(0) rotate(40deg); }
                &:after {
                    width: 16px;
                    transform: translate(-5px, 0) translateZ(0) rotate(-40deg); } } } }
    &_link {
        display: block;
        position: relative;
        z-index: 12;
        text-decoration: none;
        color: $dark-gray; }
    &_dib {
        display: inline-block; }
    &_animate {
        #{$this}__circle {
            transition: all .25s ease; }
        #{$this}__checkbox {
            transition: all .25s ease; } }
    &_label-right {
        input {
            left: 0;
            right: auto; }
        #{$this}__label {
            margin-right: 0;
            margin-left: 15px;
            @include xs {
                margin-left: 8px; } } }
    &_sm {
        input {
            width: 60px;
            height: 30px; }
        #{$this}__circle {
            width: 30px;
            height: 30px; }
        #{$this}__checkbox {
            width: 60px;
            height: 30px; } } }




