.upload {
  &__list {
    display: flex;
    flex-wrap: wrap;
    margin: 15px 0;

    li {
      flex: 0 0 46%;
      max-width: 46%;
      margin: 2%;
      position: relative;
      display: inline-block;

      &.hidden {
        display: none; }

      img {
        max-width: 100%;
        max-height: 100%;
        padding: 3px;
        border: 1px solid #ccc;
        border-radius: 3px; } }

    &_number {
      position: absolute;
      top: 1px;
      left: 1px;
      background-color: white;
      height: 24px;
      padding: 0 7px;
      color: #888;
      font-size: 16px;
      font-weight: 700;
      border-radius: 0 0 2px 0;
      text-align: center;
      line-height: 24px; }

    li:first-child {
      .upload__list_number {
        color: $aqua; }
      img {
        border-color: $aqua; } } }

  &__error {
    color: #d0021b; }

  &-avatar {
    .upload-drag {
      & > div {
        border: none !important;
        border-radius: 0 !important; }

      .uppy-DragDrop-arrow {
        display: none !important; }

      .uppy-DragDrop-label {
        text-transform: uppercase !important;
        color: $dark-gray !important;
        line-height: 2;
        font-size: 14px !important;
        letter-spacing: 1.28px;

        &:hover, &:active, &:focus {
          text-decoration: none;
          span {
            text-decoration: underline; } }

        span {
          color: #7B90F8 !important; } } } } }

.js-next-button {
  &.disabled {
    cursor: not-allowed; } }

.uppy-Dashboard-inner {
  max-width: 100%;
  min-height: 200px !important; }

.uppy-Dashboard-dropFilesTitle {
  padding-top: 45px!important; }

@media screen and (min-width: 992px) {
  .upload__list {
    li {
      flex: 0 0 29%;
      max-width: 29%; } } }

@media only screen and (max-width: 1023px) {
  .upload-avatar__browse {
    display: block; } }
