.footer {
    width: 100%;
    background-color: #1c3664;
    position: relative;
    &__inner {
        padding: 55px 100px;
        max-width: 1440px;
        margin: 0 auto;
        @include md {
            padding: 40px 50px; }
        @include xs {
            padding: 40px;
            padding-bottom: 160px; } }
    .row {
        @include clr;
        @include xs {
            & > * {
                position: static; } } }
    &__col {
        display: inline-block;
        vertical-align: top;
        width: 49%;
        max-width: 300px;
        @include r(1200) {
            width: 100%;
            max-width: 100%; }
        &.is-mob-margin {
            @include xs {
                margin-top: 40px; } } }
    &__link {
        @include osL;
        @include fz-common;
        letter-spacing: 1.08px;
        color: $white;
        text-decoration: none;
        display: inline-block;
        margin-bottom: 8px;
        position: relative;
        @include r(1200) {
            margin-bottom: 18px; }
        @include xs {
            font-size: 14px;
            margin-bottom: 11px; }
        &:before {
            content: '';
            position: absolute;
            bottom: -2px;
            left: 0;
            width: 0;
            height: 2px;
            background-color: $white;
            transition: width .2s ease; }
        &:hover {
            &:before {
                width: 100%; } } }
    &__social {
        margin-top: 30px;
        @include md {
            margin-top: 35px; }
        @include xs {
            position: absolute;
            bottom: 40px;
            left: 0;
            right: 0;
            text-align: center; } }
    &__text {
        @include osL;
        @include fz-common;
        letter-spacing: 1.08px;
        color: $white;
        margin-bottom: 10px;
        @include xs {
            font-size: 14px; } }
    &__form {
        @include r(1200) {
            margin-top: 35px; }
        @include xs {
            margin-top: 50px; }

        .error-text {
            margin-top: 10px; } }
    &__conf-msg {
        color: #99cccc;
        font-size: 14px;
        @include osSB;
        letter-spacing: 1.02px;
        margin-top: 15px; }
    .col-xl-auto {
        padding: 0; } }
