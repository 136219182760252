.user-description {
    display: flex;
    align-items: flex-start;
    &_width-sm {
        width: 470px;
        @include sm {
            width: auto; } }
    &_full {
        width: 100%; }
    &__head {
        display: flex;
        align-items: center;
        &_mb {
            margin-bottom: 20px; } }
    &__image {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 15px;
        flex: 0 0 80px;
        &_mr-lg {
            margin-right: 28px; }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover; } }
    &__title {
 }        //margin-bottom: 20px
    &__list {
        margin: 20px 0; }
    &__btn {
        margin-top: 30px;
        text-align: right;
        @include md {
            text-align: center;
            .btn {
                width: 85%; } } }
    &__textual {
        letter-spacing: 1.08px;
        font-size: 16px;
        color: $dark-gray;
        //=osR
        p {
            margin-bottom: 15px;
            &:first-child {
                @include osSB; }
            &:last-child {
                margin-bottom: 0; } } } }
