.icon {
  &__delete {
    display: block;
    position: absolute;
    width: 30px;
    height: 30px;
    top: -10px;
    right: -10px;
    background-color: white;
    border-radius: 50%;
    text-align: center;
    line-height: 28px;
    font-size: 26px;
    font-weight: bold;
    color: #ccc;
    border: 1px solid #ccc;
    transform: rotate(45deg);
    cursor: pointer;
    transition: .3s;

    &:hover,
    &:focus,
    &:active {
      color: #4a4a4a;
      border: 1px solid #4a4a4a; } } }
