.title {
    &_primary,
    &_link {
        @include fz-lg;
        @include railR;
        color: $dark-gray;
        letter-spacing: 2.46px;
        text-align: center;
        @include md {
            font-size: 42px;
            letter-spacing: 2.3px; }
        @include xs {
            @include railSB;
            font-size: 32px;
            letter-spacing: 3.42px; }
        &-sm {
            @include md {
                font-size: 38px; }
            @include xs {
                font-size: 32px;
                font-weight: 400;
                line-height: 1.1; } } }

    &_heading {
        margin-bottom: 15px;
        font-size: 32px;

        @include md {
            font-size: 20px;
            margin-bottom: 10px; } }

    &_link {
        color: $purple;
        text-decoration: none;

        &:hover,
        &:focus,
        &:active {
            text-decoration: underline; } }

    &_common {
        @include railR;
        color: $dark-gray;
        font-size: 32px;
        font-weight: 400;
        letter-spacing: 3.42px;
        @include md {
            font-size: 23px;
            letter-spacing: 2.46px; }
        @include xs {
            font-size: 18px;
            letter-spacing: 1.9px; } }
    &_lspac-lg {
        letter-spacing: 4.81px; }
    &_mb-sm {
        margin-bottom: 13px;
        @include md {
            margin-bottom: 17px; }
        @include sm {
            margin-bottom: 44px; } }
    &_mb-md {
        margin-bottom: 42px; }
    &_secondary {
        color: $dark-gray;
        font-size: 23px;
        @include md {
            font-size: 20px; }
        @include sm {
            font-size: 14px; } }
    &_os-bold {
        @include osB; } }

.subtitle {
    @include osR;
    color: $dark-gray;
    font-size: 23px;
    letter-spacing: 2.46px;
    @include xs {
        font-size: 16px; }
    &_primary {
        color: $dark-gray;
        font-size: 32px;
        font-weight: 400;
        letter-spacing: 1.75px; }
    &_common {
        @include osR;
        color: $dark-gray;
        font-size: 23px;
        font-weight: 400;
        letter-spacing: 1.08px;
        @include md {
            font-size: 16px;
            letter-spacing: 0.75px; } }
    &_mb-md {
        margin-bottom: 32px;
        @include md {
            margin-bottom: 40px; } }
    &_mb-lg {
        margin-bottom: 44px; }
    &_mb-xl {
        margin-bottom: 60px; }
    &_mob-big {
        @include xs {
            font-size: 23px; } }
    &_bold {
        font-weight: bold; }
    &_rail {
        @include railR; }

    &_small {
        font-size: 16px; } }

.list {
    @include osR;
    color: $dark-gray;
    font-size: 18px;
    letter-spacing: 1.21px;
    line-height: 1.39em;
    margin-bottom: 22px;
    @include xs {
        font-size: 14px;
        letter-spacing: 0.94px;
        line-height: 2.142857142857143em; }
    li {
        &:not(:last-child) {
            margin-bottom: 6px; } }
    &_fz-lg {
        font-size: 23px;
        line-height: 1.9;
        @include xs {
            font-size: 14px;
            letter-spacing: 0.6px; } }
    &_fz-sm {
        font-size: 16px;
        line-height: 1.6875em;
        letter-spacing: 1.08px; }
    &_lh-lg {
        li {
            &:not(:last-child) {
                margin-bottom: 14px; } } }
    &__title {
        letter-spacing: 1.06px;
        @include xs {
            margin-bottom: 3px; } }
    &_disc {
        li {
            position: relative;
            padding-left: 15px;
            &:before {
                content: '';
                position: absolute;
                width: 4px;
                height: 4px;
                border-radius: 50%;
                background-color: #000;
                left: 0;
                top: 10px; } } }
    &_disc-lg {
        @include xs {
            margin-bottom: 39px; }
        li {
            padding-left: 35px;

            &:not(:last-child) {
                margin-bottom: 2px; }
            &:before {
                width: 6px;
                height: 6px;
                top: 20px;
                left: 2px; }
            @include xs {
                padding-left: 16px;
                &:not(:last-child) {
                    margin-bottom: 6px; }
                &:before {
                    width: 3px;
                    height: 3px;
                    top: 11px; } } } }
    &_disc-grey {
        li {
            &:before {
                background-color: #4a4a4a; } } }

    &_disc-scalable {
        li {
            &:before {
              top: .65rem; } } }
    &_dashed {
        li {
            list-style: none;
            padding-left: 15px;
            position: relative;
            &:before {
                content: '';
                position: absolute;
                left: 0;
                top: 12px;
                width: 5px;
                height: 1px;
                background-color: $dark-gray;
                letter-spacing: 0.61px;
                line-height: 1.75em;
                @include osR;
                font-weight: 400; } } }
    &_star {
        li {
            list-style: none;
            padding-left: 15px;
            position: relative;
            &:before {
                content: '*';
                position: absolute;
                left: 0;
                top: 0;
                color: inherit; } } }
    &_mb-l {
        margin-bottom: 55px; } }

.text {
    color: $dark-gray;
    &_common {
        @include osR;
        font-size: 18px;
        line-height: 25px;
        letter-spacing: 1.21px;
        margin-bottom: 14px; }
    &_sm {
        color: $red;
        font-size: 14px;
        letter-spacing: 1.08px;
        @include osSB;
        @include xs {
            font-size: 12px;
            margin-top: 5px; } }
    &_mt-sm {
        margin-top: 10px;
        @include xs {
            margin-top: 4px; } }
    &_default {
        @include osR;
        font-size: 16px;
        letter-spacing: 1.08px;
        color: $dark-gray; }
    &_md {
        font-size: 16px;
        letter-spacing: 1.08px;
        line-height: 1.4;
        @include xs {
            font-size: 14px;
            letter-spacing: 0.94px; } }
    .is-error {
        color: $red; } }

.link {
    color: $purple;
    text-decoration: none;
    &:hover {
        text-decoration: underline; } }

.text-flag {
    display: inline-block;
    vertical-align: middle;
    font-size: 11px;
    color: $dark-gray;
    letter-spacing: 0.4px;
    @include osR;
    &_lg {
        letter-spacing: 1.08px;
        font-size: 16px; } }


.text-center {
    text-align: center; }
.text-right {
    text-align: right; }
.text-left {
    text-align: left; }
.list-icons {
    &__item {
        position: relative;
        @include clr;
        // display: flex
        // align-items: center
        padding: 10px 0 5px 57px;
        margin-bottom: 15px;
        color: #ffffff;
        font-size: 23px;
        letter-spacing: 2.46px;
        @include osR;
        @include sm {
            font-size: 16px;
            letter-spacing: 1.71px;
            padding: 6px 0 8px 57px; }
        @include xs {
            // padding-top: 11px
 } }            // padding-bottom: 11px
    &__icon {
        position: absolute;
        left: 0;
        top: 50%;
        transform: translateY(-50%);
        height: 45px;
        width: 45px;
        font-size: 40px;
        @include sm {
            font-size: 32px;
            margin-right: 15px; }
        @include xs {
 }            // top: 0
        &_min {
            font-size: 26px;
            top: 65%;
            @include sm {
                font-size: 22px; }
            @include xs {
 } }                // top: 60%
        .icon-mail {
            margin-top: -4px; }
        span {
            // position: absolute
            // top: 50%
            // left: 0
            // width: 100%
            // height: 100%
            // display: block
 } }            // transform: translateY(-50%)
    &__text {
        // overflow: hidden
        @include md {
            text-shadow: 2px 3px 4px rgba(0,0,0,0.75); } } }
.error-text {
    font-size: 14px;
    @include osSB;
    font-weight: 600;
    letter-spacing: 1.08px;
    color: $red;

    &.is-hidden {
        display: none; }
    &_mt-sm {
        margin-top: 5px; }

    &__padding {
        padding-top: 15px; } }
.read-more {
    position: relative;
    font-size: 16px;
    @include osR;
    font-weight: 400;
    letter-spacing: 0.61px;
    color: #984a9d;
    // margin: 10px 0
    display: inline-block;
    padding-right: 35px;
    text-decoration: none;
    &:before, &:after {
        content: '';
        position: absolute;
        right: 10px;
        top: 50%;
        background-color: #984a9d;
        width: 10px;
        height: 1px; }
    &:before {
        transform: translateX(-4px) rotate(40deg); }
    &:after {
        transform: translateX(4px) rotate(-40deg); } }
