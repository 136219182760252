.loading {
  &__wrapper {
    position: fixed;
    z-index: 9999;
    width: 100%;
    height: 100%;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(black, .5); }

  &__img {
    width: 250px;
    height: 250px;
    background: white;
    border: 12px solid $aqua;
    border-radius: 30px;
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      max-width: 80%;
      height: 100%;
      display: block;
      position: relative; } }

  &__subscription {
    margin: 20px auto 0;
    width: 70px;
    text-align: center;
    display: none;

    span {
      width: 18px;
      height: 18px;
      background-color: white;
      border-radius: 100%;
      display: inline-block;
      animation: sk-bouncedelay 1.4s infinite ease-in-out both;

      &:nth-child(1) {
        animation-delay: -0.32s; }

      &:nth-child(2) {
        animation-delay: -0.16s; } } } }

@keyframes sk-bouncedelay {
  0%, 80%, 100% {
    transform: scale(0); }
  40% {
    transform: scale(1.0); } }
