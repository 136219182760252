.popup {
    $this: &;
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    min-height: 100vh;
    z-index: 159;
    overflow-y: auto;
    background-color: rgba(74, 74, 74, 0.45);
    // background-color: rgba(74, 74, 74, 1)
    &__head {
        margin-bottom: 16px;
        max-width: calc(100% - 30px); //30px - spase for btn-close
        display: flex;
        align-items: center; }
    &__icon {
        margin-right: 6px; }
    &__title {
        font-size:  18px;
        letter-spacing: 0.53px;
        @include osB;
        @include sm {
            font-size: 16px; }
        @include xs {
            font-size: 14px; }
        &_mob-xl {
            @include xs {
                font-size: 16px;
                letter-spacing: 1.71px;
                margin-bottom: 10px; } }
        &_font-normal {
            font-weight: 400; } }
    &__textual {
        font-size:  16px;
        letter-spacing: 0.53px;
        @include osR;
        @include sm {
            font-size: 14px; }
        p {
            margin-bottom:  16px;
            &:last-child {
                margin-bottom: 0; } }
        &_lsp-md {
            letter-spacing: 1.08px; }

        &_font-lg {
            i {
                font-size: 40px; }
            p, .error-text, a {
                font-size: 17px;
                margin: 7px 0; } } }
    &__sharing {
        margin-top: 25px;
        margin-bottom: 20px; }
    &__overlay {
        height: 100vh;
        position: fixed;
        width: 100%; }
    &__content {
        // display: flex
        left: 50%;
        top: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
        width: percentage(1200px/1440px);
        background-color: $white;
        margin: 0;
        @include bdrad;
        padding: 30px;
        @include md {
            display: block;
            width: 100%; }
        @include xs {
            padding: 30px 25px;
            padding-bottom: 50px; }
        &_main {
            max-width: 820px;
            width: percentage(820px/1360px); }
        &_md {
            max-width: 600px;
            padding-bottom: 25px;
            @include md {
                width: calc(100% - 50px);
                padding-bottom: 10px; }
            @include xs {
                width: calc(100% - 30px);
                padding: 25px 10px; } }
        &_sm {
            max-width: 425px;
            width: percentage(820px/1360px);
            @include xs {
                width: calc(100% - 50px); } }
        &_pt-md {
            padding-top: 55px; } }
    &__close {
        position: absolute;
        cursor: pointer;
        right: 30px;
        top: 30px;
        font-size: 14px;
        z-index: 1200;
        width: 20px;
        height: 20px;
        @include md {
            top: 16px;
            right: 24px;
            font-size: 20px; }
        &:before, &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            z-index: 10;
            width: 100%;
            height: 2px;
            background-color: #000; }
        &:before {
            transform: rotate(45deg); }
        &:after {
            transform: rotate(-45deg); }
        &_light {
            &:before, &:after {
                background-color: #9b9b9b; } }
        &_circle {
            background-color: #777777;
            width: 30px;
            height: 30px;
            border-radius: 50%;
            top: -15px;
            right: -15px;
            &:before, &:after {
                background-color: $white;
                width: 10px;
                left: 50%;
                top: 50%; }
            &:before {
                transform: translate(-50%, -50%) rotate(45deg); }
            &:after {
                transform: translate(-50%, -50%) rotate(-45deg); } }
        &_big {
            width: 60px;
            height: 60px; }
        &.is-hidden {
            display: none; } }
    &__btns {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 25px;
        @include xs {
            .btn {
                width: 110px;
                padding: 0 5px; } }
        @include r(400) {
            .btn {
                width: 100px;
                padding: 0; } } }
    &__cancel-btns {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-top: 50px;

        @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
            margin-top: 80px;
            margin-left: -20px; }
        @include xs {
            display: block;
            margin-top: 30px; }
        .cancellation-btn {
            &:not(:last-child) {
                margin-right: 8px;
                @include xs {
                    margin-right: 0;
                    margin-bottom: 10px; } }

            &:last-child {
                @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
                    line-height: 50px; } } } }
    &__aprove {
        margin-top: 25px;
        .btn {
            &:not(:last-child) {
                margin-bottom: 10px; } } }
    &_video {
        .popup__content {
            width: 700px;
            height: 394px;
            border-radius: 0;
            top: 40%;
            padding: 0;
            margin-top: 100px;
            @include sm {
                width: 500px;
                height: 280px; }
            @include xs {
                width: 400px;
                height: 223px;
                top: 20%;
                margin-top: 60px;
                left: 50%;
                transform: translate(-50%); }
            @include r(400) {
                width: 320px;
                height: 180px; } }
        .popup__close {
            top: -2px;
            right: -25px; } }
    &_full {
        #{$this}__content {
            min-height: 100%;
            width: 100%;
            padding: 43px;
            padding-top: 50px;
 } }            // padding-bottom: 60px
    &_with-header {
        @include xs {
            top: 62px; } }
    &_with-header-md {
        @include md {
            top: 85px; }
        @include sm {
            top: 78px; }
        @include xs {
            top: 62px; } }
    &_tooltip {
        z-index: 151;
        #{$this}__content {
            max-height: 100vh;
            overflow: auto;
            border-radius: 7px;
            padding: 15px;
            margin: 0;
            width: calc(100% - 40px);
            top: 50%;
            transform: translate(-50%, -50%); }
        #{$this}__close {
            top: 12px;
            right: 12px;
            width: 17px;
            height: 17px; } }
    &_conf {
        background-color: transparent;
        overflow: visible;
        max-width: 450px;
        min-height: 275px;
        top: 35%;
        left: 50%;
        transform: translate(-50%, -35%);
        @include xs {
            max-width: 270px;
            top: 50%;
            transform: translate(-50%, -50%); }
        #{$this}__content {
            background-color: #99cccc;
            max-width: 375px;
            padding: 25px;
            text-align: center;
            display: flex;
            justify-content: center;
            align-items: center;
            min-height: 235px;
            p {
                color: $dark-gray;
                font-size: 16px;
                @include osR;
                letter-spacing: 1.08px;
                @include xs {
                    font-size: 14px;
                    letter-spacing: 0.94px; } }
            a {
                text-decoration: none;
                color: $purple;
                font-weight: 600;
                letter-spacing: 1.08px;
                font-size: 16px;
                @include xs {
                    font-size: 14px;
                    letter-spacing: 0.94px; }
                &:hover {
                    text-decoration: underline; } } } }
    &_conf-md {
        max-width: 480px;
        #{$this}__content {
            max-width: 400px;
            padding: 40px 20px 30px 20px;
            @include xs {
                max-width: 320px; }
            @include r(355) {
                max-width: 270px; } } }
    &_steps {
        background-color: transparent;
        overflow: visible;
        max-width: 725px;
        min-height: 305px;
        top: 35%;
        left: 50%;
        transform: translate(-50%, -35%);
        @include sm {
            max-width: 600px; }
        @include xs {
            top: 50%;
            transform: translate(-50%, -50%); }

        #{$this}__content {
            max-width: 600px;
            min-height: 305px;
            background-color: #99cccc;
            @include sm {
                max-width: 475px; }
            @include xs {
                max-width: 360px;
                padding: 25px;
                padding-bottom: 10px; }
            @include r(400) {
                max-width: 270px;
                min-height: auto; } } }
    &_disabled {
        width: 100%;
        min-width: 100%;
        max-width: 100%;
        min-height: 100%;
        height: 100vh; }
    &_sm {
        z-index: 151;
        #{$this}__content {
            max-height: 100vh;
            overflow: auto;
            border-radius: 7px;
            padding: 15px;
            margin: 0;
            // width: calc(25% - 40px)
            top: 50%;
            transform: translate(-50%, -50%); }
        #{$this}__close {
            top: 12px;
            right: 12px;
            width: 17px;
            height: 17px; } }
    &_gallery {
        #{$this}__content {
            padding: 0;
            margin-top: 70px;
            background-color: transparent;
            top: 0;
            transform: translate(-50%, 0);
            @include xs {
                margin-top: 30px; } }
        #{$this}__overlay {
            background-color: #000000;
            opacity: 0.82; }
        #{$this}__close {
            right: -30px;
            top: -30px;
            @include md {
                right: 20px;
                top: -50px;
                background: transparent;
                &:before,
                &:after {
                    width: 24px; } }
            @include xs {
                right: 4px; } } }
    &_mob-full {

        #{$this}__content {
            border-radius: 0;
            @include xs {
                padding: 50px 0 25px;
                top: 0;
                transform: translate(-50%, 0);
                width: 100%;
                max-width: 100%;
                padding: 50px 25px 0; } }
        .popup__close_circle {
            @include xs {
                background-color: transparent;
                width: 20px;
                height: 20px;
                top: 16px;
                right: 24px;
                font-size: 20px;
                &:before, &:after {
                    background-color: #9b9b9b;
                    width: 100%; } } } }

    &.is-visible {
        display: block; }
    &_request {
        #{$this}__content {
            padding: 50px 0 0; }
        .flatpickr-calendar {
            @include r(360) {
                width: calc(100% - 50px); } }
        .booking-request {
            width: 100%;
            margin: 0;
            &__head {
                width: calc(100% + 50px);
                margin-left: -25px;
                padding-left: 25px;
                padding-right: 25px; }
            &__container {
                background: #fff; }
            &__content {
                background: #fff;
                display: block;
                border-top: none;
                padding-bottom: 140px; }
            &__rows {
                border-top: none; }
            &__row {
                margin: 0;
                display: block; }
            &__time-link {
                color: $purple; }
            &__btn {
                .btn {
                    font-size: 18px;
                    min-height: 45px; } }
            .select {
                margin: 0 0 20px;
                &__title {
                    color: $dark-gray;
                    margin-bottom: 5px; } }
            .cost-summ {
                h3, p {
                    @include md {
                        color: $dark-gray; } }
                &__title {
                    margin-bottom: 15px; }
                &__row {
                    margin-bottom: 15px; } } } }
    &_photo-load {
        #{$this}__content {
            background-color: transparent;
            max-width: 324px;
            padding: 0;
            top: auto;
            bottom: 74px;
            transform: translate(-50%, 0);
            @include r(330) {
                max-width: 290px; } } }
    &_send-message {
        .user-description {
            .list {
                @include xs {
                    font-size: 14px; } }
            &__image {
                @include xs {
                    margin-right: 16px;
                    flex: 0 0 60px;
                    width: 60px;
                    height: 60px; } }
            &__title {
                margin-bottom: 0; }
            .textarea__input {
                textarea {
                    @include xs {
                        font-size: 12px; } } } } }

    &_dark-bg {
        background-color: rgba(black, 0.6);
        overflow: hidden;
        #{$this}__content {
            background-color: #99cccc;
            padding: 25px;
            max-width: 450px;

            @include xs {
                max-width: 260px; }
            p {
                color: $dark-gray;
                font-size: 16px;
                @include osR;
                letter-spacing: 1.08px;
                @include xs {
                    font-size: 14px;
                    letter-spacing: 0.94px; } } } }

    &__form {
        margin-top: 15px;
        margin-bottom: 15px;

        &-button {
            position: relative;
            left: 50%;
            transform: translateX(-50%); } }

    &__field {
        &_full {
            width: 100%; }

        &_half + &_half {
            margin-left: 2%;

            @include xs {
             margin-left: 0; } }

        &_half {
            width: 49%;
            float: left;

            @include xs {
                width: 100%; } } }

    &__scrolable {
        max-height: 50vh;
        overflow: auto;

        &::-webkit-scrollbar {
            width: 10px; }

        &::-webkit-scrollbar-thumb {
            width: 10px;
            border-radius: 10px;
            background-color: #33669a; }

        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(51, 102, 154, .5);
            border-radius: 10px; } } }

.popup-step {
    &__title {
        padding-bottom: 20px;
        border-bottom: 1px solid #979797;
        font-size: 16px;
        @include osSB;
        color: $dark-gray;
        letter-spacing: 1.1px;
        padding-bottom: 20px;
        @include xs {
            font-size: 14px;
            letter-spacing: 0.94px;
            padding-bottom: 12px; } }
    &__text {
        padding-top: 40px;
        text-align: center;
        font-size: 16px;
        letter-spacing: 1.1px;
        @include osR;
        color: $dark-gray;
        @include xs {
            font-size: 14px;
            letter-spacing: 0.94px;
            padding-top: 12px; } }
    &__pricing {
        max-width: 350px;
        margin: 0 auto;
        margin-top: 20px;
        @include xs {
            margin-top: 0; } }
    &__row {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 5px;

        &:last-child {
            p {
                font-weight: 700; } }

        p:first-child {
            margin-right: 10px; }

        &_border {
            border-bottom: 1px solid $dark-gray;
            margin-bottom: 15px;
            padding-bottom: 15px; } }

    &__btns {
        margin-top: 70px;
        width: 100%;
        @include clr;
        @include xs {
            margin-top: 13px; } } }

