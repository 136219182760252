.listing {
  &__edit {
    display: inline-block;
    position: relative;
    text-decoration: none;
    z-index: 100;
    background-color: $aqua;
    border: 1px solid $aqua;
    border-radius: 6px;
    padding: 3px 10px;
    color: white;
    margin: 5px 0 10px;
    text-transform: capitalize;
    transition: .3s;

    &:hover,
    &:active,
    &:focus {
      background-color: white;
      color: $aqua; } } }
