.user {
    position: relative;
    display: block;
    &:hover {
        .menu-dropdown {
            pointer-events: all;
            opacity: 1;
            visibility: visible; } }
    &__image {
        display: block;
        width: 60px;
        height: 60px;
        border-radius: 50%;
        cursor: pointer;
        overflow: hidden;
        text-decoration: none;
        @include sm {
            width: 45px;
            height: 45px;
            margin-right: 50px; }
        @include xs {
            width: 35px;
            height: 35px;
            margin-right: 45px; }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover; } }
    &__no-image {
        width: 100%;
        height: 100%;
        font-size: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: $dark-gray;
        text-align: center;
        @include sm {
            font-size: 40px; }
        @include xs {
            font-size: 30px; } }
    .menu-dropdown {
        top: 100%;
        @include sm {
            display: none; } }

    &.has-messages {
        &:before {
            content: '';
            position: absolute;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: #099;
            top: -1px;
            right: 2px;
            @include sm {
                display: none; } } } }


