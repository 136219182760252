.payment {
	&:not(.is-active) {
		display: none; }
	&__title {
		margin-bottom: 15px;
		@include xs {
			margin-bottom: 5px; } }
	&__subtitle {
		margin-bottom: 35px;
		span {
			@include xs {
				display: none; } }
		@include xs {
			margin-bottom: 16px; } }
	&__error-message {
		@include osSB;
		font-weight: 600;
		font-size: 14px;
		letter-spacing: 1.08px;
		color: $red; }
	.icon-stripe,
	.icon-credit-card,
	.icon-paypal {
		color: $aqua;
		font-size: 24px; }
	&__form {
		font-size: 0;
		.select {
			margin-right: 30px;
			@include xs {
				margin-right: 15px; }
			&:last-child {
				margin-right: 0; } }
		.field {
			margin-bottom: 24px;
			max-width: 380px;
			.icon-stripe,
			.icon-credit-card {
				position: relative;
				top: 6px; }
			&__input {
				&_width-xs {
					@include xs {
						width: 50px; } }
				// &_mr-md
 } }				// 	margin-right: 50px
		.custom-select {
			&__text {
				&:before {
					width: 8px;
					height: 8px;
					top: 33%; } } }
		.custom-select.is-open {
			.custom-select__text {
				&:before {
					transform: rotate(45deg) scale(1,-1) translate(3px, -3px); } } } } }

.payment-method {
	display: flex;
	align-items: center;
	margin-bottom: 25px;
	&:last-child {
		margin-bottom: 0; }
	&__label {
		padding: 0 14px 0 24px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		width: 100%;
		width: 320px;
		height: 50px;
		border-radius: 7px;
		border: 2px solid $light-aqua;
		cursor: pointer;
		margin-right: 25px;
		@include xs {
			padding: 0 12px 0;
			height: 40px;
			margin-right: 15px;
			width: 230px; }
		&_sm {
			width: 220px; }
		&_reverse {
			flex-direction: row-reverse; } }
	&__name {
		font-size: 16px;
		letter-spacing: 1.08px;
		@include osR;
		@include xs {
			font-size: 12px; } }
	&__desc {
		font-size: 11px; }
	&__icons {
		.icon-credit-card {
			margin-left: 6px; }
		.icon-stripe {
			@include xs {
				display: none; } } }
	&__radio {
		width: 28px;
		height: 28px;
		border: 1px solid $aqua;
		border-radius: 50%;
		position: relative;
		cursor: pointer;

		input:checked {
			& ~ .radio__circle {
				&:before {
					content: '';
					position: absolute;
					left: 50%;
					top: 50%;
					transform: translate(-50%, -50%);
					width: calc(100% - 9px);
					height: calc(100% - 9px);
					border-radius: 50%;
					background-color: #33669a;
					// +xs
					//     width: calc(100% - 4px)
 } } } } }					//     height: calc(100% - 4px)


.payment-info {
	display: table;
	p,span {
		display: inline-block;
		vertical-align: middle; }
	.icon-credit-card {
		color: $aqua;
		font-size: 24px;
		@include xs {
			font-size: 18px; } }
	&__row {
		display: table-row;
		&:last-child {
			.payment-info__cell {
				padding-top: 12px; } } }
	&__cell {
		&:first-child {
			padding-right: 20px;
			@include r(360) {
				padding-right: 6px; } }
		display: table-cell;

		p {
			font-weight: 900 !important; } } }
