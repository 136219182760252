.tabs {
    &_main {
        display: flex;
        justify-content: center;
        align-items: center;
        .tab {
            background-color: #cacaca;
            // opacity: 0.31
            @include railM;
            @include fz-md;
            flex: 50%;
            text-align: center;
            color: #b0b0b0;
            letter-spacing: 1.7px;
            cursor: pointer;
            text-decoration: none;
            padding: 30px 20px 36px;
            position: relative;
            transition: color .2s ease;
            &:first-child {
                border-radius: 5px 0 0 0; }
            &:last-child {
                border-radius: 0 5px 0 0; }
            &:hover {
                color: #000; }
            span {
                position: relative;
                padding: 20px; }
            &.is-active {
                background-color: $white;
                span {
                    &:after {
                        content: '';
                        position: absolute;
                        bottom: 0;
                        left: -15px;
                        right: -15px;
                        background-color: #33669a;
                        height: 3px;
                        width: calc(100% + 30px);
                        margin: 0 auto; } } } } } }


.tab-content {
    display: none;
    &.is-active {
        display: block; } }

