.range-slider {
    height: 1px;
    background: $dark-gray;
    position: relative;
    margin-top: 60px;
    margin-bottom: 50px;
    @include xs {
        margin-top: 44px;
        margin-bottom: 38px; }
    &__title {
        position: absolute;
        bottom: 100%;
        text-align: center;
        margin-bottom: 5px;
        color: $dark-gray;
        font-size: 16px;
        font-weight: 400;
        letter-spacing: 1.08px;
        opacity: 1;
        transition: opacity .35s ease;
        @include md {
            font-size: 14px;
            letter-spacing: 0.94px; }
        @include xs {
            font-size: 10px;
            letter-spacing: 0.67px;
            margin-bottom: 0; }
        &_left {
            left: -35px;
            @include xs {
                left: -10px; } }
        &_right {
            right: -55px;
            @include xs {
                right: -30px; } }
        &.is-close {
            opacity: 0.2; } }
    &__add-btn {
        text-align: center;
        margin-bottom: 14px;
        @include xs {
            .btn {
                min-height: 30px;
                font-size: 12px;
                letter-spacing: 0.81px;
                margin-bottom: 0; } } } }

.ui-slider-horizontal {
    width: calc(100% - 16px);
    clear: both;
    height: 30px;
    top: -15px; }

.ui-slider {
    position: relative;
    text-align: left; }

.ui-slider-horizontal .ui-slider-range {
    top: -1px;
    height: 100%; }
.ui-slider-handle {
    display: inline-block;
    position: absolute;
    width: 16px;
    height: 16px;
    border-radius: 50%;
    background-color: #984a9d;
    top: 8px;
    z-index: 30;
    cursor: pointer;
    @include xs {
        width: 16px;
        height: 16px;
        top: 7px; } }

.ui-slider-handle {
    &:after {
        content: attr(data-value);
        position: absolute;
        bottom: -30px;
        left: 50%;
        transform: translateX(-50%);
        min-width: 100px;
        min-height: 20px;
        letter-spacing: 1.17px;
        text-align: center;
        @include osR;
        font-weight: 400;
        font-size: 12px;
        color: $dark-gray;
        transition: all .2s ease;
        @include xs {
            font-size: 10px;
            letter-spacing: 0.98px;
            width: auto;
            min-width: 80px;
            bottom: -27px; } }
    &:before {
        position: absolute;
        top: -43px;
        left: 50%;
        transform: translateX(-50%);
        min-width: 80px;
        min-height: 20px;
        letter-spacing: 0.81px;
        text-align: center;
        @include osR;
        font-weight: 400;
        font-size: 12px;
        color: $dark-gray;
        transition: all .2s ease;
        @include md {
            min-width: auto;
            width: 50px;
            top: -42px; }
        @include xs {
            font-size: 10px;
            letter-spacing: 0.98px;
            top: -35px; } }
    &:first-of-type {
        &:before {
            content: 'Opening Hour'; } }
    &:last-of-type {
        &:before {
            content: 'Closing Hour'; } } }

.ui-slider-handle {
    &.is-close {
        &:after, &:before {
            opacity: .8; }
        &:after {
            bottom: -45px;
            @include xs {
                bottom: -37px; } }
        &:before {
            @include xs {
                left: calc(100% + 13px); } } }
    &.is-title-close {
        &:before {
            top: -72px;
            @include xs {
                top: -35px;
                left: -13px; } } }
    &.is-left-border {
        &:after, &:before {
            padding-left: 20px;
            @include xs {
                padding-left: 10px; } } }
    &.is-right-border {
        &:after, &:before {
            padding-right: 20px;
            @include xs {
                padding-right: 10px; } } } }


