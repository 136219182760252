.map-wrap {
    width: 100%;
    height: 100%;
    &__inner {
        width: 100%;
        height: 100%; } }


.map {
    width: 100%;
    height: 100%;
    position: relative;
    &__controls {
        position: absolute;
        top: 80px;
        left: 5px;
        height: 80px;
        width: 40px;
        background-color: #fff;
        z-index: 75;
        overflow: hidden;
        @include bdrad;
        border: 1px solid #979797;
        &:before {
            content: '';
            position: absolute;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            width: 80%;
            height: 1px;
            margin: 0 auto;
            background-color: #dddddd; }
        &_top {
            top: 5px; } }
    &__zoom-in, &__zoom-out {
        width: 100%;
        height: 50%;
        position: absolute;
        left: 0;
        right: 0;
        cursor: pointer;
        &:before {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            background-color: #4a4a4a;
            width: 18px;
            height: 4px;
            margin: 0 auto; } }
    &__zoom-in {
        top: 0;
        &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            right: 0;
            background-color: #4a4a4a;
            width: 18px;
            height: 4px;
            margin: 0 auto;
            transform: rotate(90deg); } }
    &__zoom-out {
        bottom: 0; }
    &__fullscreen {
        position: absolute;
        width: 50px;
        height: 50px;
        background-color: #fff;
        @include bdrad;
        top: 2px;
        right: 2px;
        z-index: 75;
        cursor: pointer;
        display: block;
        @include md {
            width: 40px;
            height: 40px; }
        &:before {
            content: '';
            position: absolute;
            width: 100%;
            height: 100%;
            margin: 0 auto;
            left: 0;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            background-image: url(../img/map-expand.svg);
            background-repeat: no-repeat;
            background-size: cover; }
        &.is-open {
            display: none; } }
    &__fullscreen-close {
        position: absolute;
        width: 50px;
        height: 50px;
        background-color: #777;
        border-radius: 50%;
        top: 2px;
        right: 2px;
        z-index: 75;
        cursor: pointer;
        display: none;
        @include md {
            width: 40px;
            height: 40px; }
        &:before, &:after {
            position: absolute;
            content: '';
            top: 50%;
            left: 50%;
            background-color: #fff;
            width: 10px;
            height: 2px; }
        &:before {
            transform: translate(-50%, -50%) rotate(45deg); }
        &:after {
            transform: translate(-50%, -50%) rotate(-45deg); }
        &.is-open {
            display: block; } }
    &__checkbox {
        position: absolute;
        top: 5px;
        left: 5px;
        z-index: 75;
        background-color: #fff;
        @include bdrad;
        border: 1px solid #4a4a4a;
        .checkbox {
            .checkbox__wrapper {
                padding: 8px;
                padding-left: 18px;
                padding-right: 15px;
                @include md {
                    padding: 6px 10px; }
                input {
                    width: 15px;
                    height: 15px;
                    left: 18px; }
                input:checked+.checkbox__ico:before {
                    width: 3px; }
                input:checked+.checkbox__ico:after {
                    width: 8px; } }
            .checkbox__label {
                font-size: 16px;
                letter-spacing: 1.08px;
                @include osR;
                @include md {
                    font-size: 13px;
                    letter-spacing: 0.5px; } }
            .checkbox__ico {
                margin-right: 15px;
                width: 15px;
                height: 15px;
                min-width: 15px;
                border-radius: 0;
                border-color: #009999;
                @include md {
                    margin-right: 10px; }
                &:before {
                    bottom: 4px;
                    left: 2px;
                    background: #009999; }
                &:after {
                    transform: translate(4px,8px) rotate(-45deg);
                    background: #009999; } } } }
    .is-hidden {
        display: none; } }

