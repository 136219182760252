.menu {
  &__link {
    color: $white;
    text-decoration: none;
    display: flex;
    align-items: center;
    min-height: 60px;
    padding: 5px 0 5px 40px;
    border-bottom: 1px solid #9b9b9b;
    transition: .3s;
    @include sm {
      padding-left: 25px; }
    @include xs {
      min-height: 50px; }

    &:hover,
    &:focus,
    &:active {
      .menu {
        &__icon span:before,
        &__name {
          color: $light-aqua; } } } }

  &__name {
    font-size: 18px;
    @include osR;
    letter-spacing: 1.21px;
    color: $white;
    display: inline-block;
    vertical-align: middle;
    transition: .3s;
    @include md {
      font-size: 16px; }
    @include xs {
      font-size: 14px;
      letter-spacing: 0.94px; } }
  &__icon {
    color: $white;
    font-size: 0;
    display: flex;
    width: 35px;
    margin-right: 7px;
    position: relative;
    justify-content: center;
    @include xs {
      width: 25px; }
    [class^="icon-"], [class*=" icon-"] {
      font-size: 30px;
      @include xs {
        font-size: 24px; }
      &:before {
        color: $white;
        transition: .3s; } }

    .icon-hand_heart {
      font-size: 24px;

      @include xs {
        font-size: 18px; } }

    .icon-letter {
      font-size: 24px;
      @include xs {
        font-size: 16px; } } }
  .menu__item {
    font-size: 0;
    width: 100%;
    &.is-active {
      .menu__link {
        background-color: #fff;
        border-bottom: none;
        color: $dark-gray; }
      .menu__name {
        @include osSB;
        color: $dark-gray; }
      .menu__icon {
        [class^="icon-"], [class*=" icon-"] {
          &:before {
            color: $dark-gray; } } }
      .menu__subnav {
        display: block; } }
    &.has-messages {
      .menu__icon {
        &:before {
          content: '';
          position: absolute;
          width: 12px;
          height: 12px;
          border-radius: 50%;
          background-color: #099;
          top: -9px;
          right: -1px;
          @include xs {
            width: 10px;
            height: 10px;
            top: -7px;
            right: -2px; } } }
      &-main {
        .menu__link_main {
          position: relative;
          &:before {
            content: '';
            position: absolute;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: #099;
            top: -3px;
            right: -9px;
            box-shadow: 0 1px 1px rgba(black, .5); } } } } }

  &__subnav {
    display: none;
    padding: 15px 0;
    overflow: auto;
    max-height: 200px;
    border-bottom: 1px solid #9b9b9b;
    @include xs {
      max-height: 130px; }
    .menu__subitem {
      width: 100%;
      .menu__sublink {

        &:hover,
        &:focus,
        &:active {
          .menu {
            &__subicon span:before,
            &__subname {
              color: $light-aqua; } } } }

      &:not(:last-child) {
        .menu__sublink {
          margin-bottom: 15px;
          @include xs {
            margin-bottom: 10px; } } }


      &.has-messages {
        .menu__subicon {
          position: relative;
          &:before {
            content: '';
            position: absolute;
            width: 12px;
            height: 12px;
            border-radius: 50%;
            background-color: #099;
            top: -3px;
            right: -1px;
            box-shadow: 0 0 20px -5px black;
            @include xs {
              width: 10px;
              height: 10px;
              top: -7px;
              right: -2px; } } } } }

    .menu__subname {
      font-size: 18px;
      transition: .3s;
      @include osR;
      letter-spacing: 1.21px;
      @include md {
        font-size: 16px; }
      @include xs {
        font-size: 14px;
        letter-spacing: 0.94px; } }
    .menu__sublink {
      padding-left: 40px;
      min-height: auto;
      display: flex;
      align-items: center;
      color: $white;
      text-decoration: none;
      background-color: transparent;
      transition: .3s;
      @include sm {
        padding-left: 40px; } }
    .menu__subicon {
      width: 30px;
      height: 30px;
      border-radius: 50%;
      margin-right: 7px;
      display: flex;
      align-items: center;
      justify-content: center;
      @include xs {
        width: 25px;
        height: 25px; }
      [class^="icon-"], [class*=" icon-"] {
        font-size: 16px;

        @include xs {
          font-size: 12px; }
        &:before {
          transition: .3s;
          color: $white; } }
      .icon-profile {
        font-size: 12px;
        @include xs {
          font-size: 10px; } } }
    .menu__subitem {
      &.is-active {
        .menu__subicon {
          background-color: #99cccc; } } } }
  .menu__item_primary {
    text-align: center;
    position: relative;
    .menu__link {
      color: $white;
      @include osB;
      font-size: 18px;
      padding-left: 0;
      justify-content: center;
      letter-spacing: 1.93px;
      transition: .3s;
      @include md {
        font-size: 16px; }
      @include sm {
        justify-content: flex-start;
        padding-left: 25px; }
      @include xs {
        font-size: 14px;
        letter-spacing: 1.5px; }
      &:hover,
      &:focus,
      &:active {
        color: $light-aqua; } } }

  .menu__item_adapt {
    @include rmin(768) {
      display: none; } } }

.menu__subnav {
  &::-webkit-scrollbar {
    width: 5px; }
  &::-webkit-scrollbar-thumb {
    border-radius: 7px;
    background-color: #9c9c9c; } }
