.notification {
  &__modal {
    @include osR;
    color: $dark-gray;
    font-size: 16px;
    letter-spacing: 1.08px;
    min-height: 60px;
    background-color: #99cccc;
    display: flex;
    align-items: center;
    justify-content: center;
    position: fixed;
    top: 100px;
    left: 0;
    width: 100%;
    z-index: 100;

    @include sm {
      padding-left: 0;
      top: 79px; }
    @include xs {
      top: 61px;
      font-size: 14px;
      min-height: 50px; }

    &.error,
    &.error .info-modal {
      background-color: #f2dede;
      color: #a94442; }

    &.info,
    &.info .info-modal {
      background-color: #fcf8e3; } }

  &__dashboard {
    padding-left: 208px;
    @include sm {
      padding-left: 0; } } }
