.counter {
    display: inline-flex;
    align-items: center;

    &__wrapper {
        position: relative; }

    &__amount {
        font-size: 18px;
        @include osSB;
        font-weight: 600;
        letter-spacing: 1.2px;
        color: $dark-gray;
        margin: 0 25px;
        @include xs {
            font-size: 14px;
            letter-spacing: 0.94px;
            margin: 0 20px; } }
    &__increment, &__decrement {
        width: 35px;
        height: 35px;
        background-color: #33669a;
        position: relative;
        border-radius: 50%;
        cursor: pointer;
        @include xs {
            width: 30px;
            height: 30px; }
        &:before, &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            height: 2px;
            background-color: $white;
            width: 13px; } }
    &__increment {
        &:before {
            transform: translate(-50%, -50%) rotate(90deg); } }

    &__error {
        max-width: 250px;
        color: $red;
        padding: 15px 0;
        position: absolute;
        top: 100%;

        @include md {
            position: relative;
            top: auto; } } }

.has-error {
    position: inherit;
    padding: 13px 13px; }
