.legal-page {
    @include md {
        padding-left: 50px;
        padding-right: 35px; }
    @include xs {
        padding-left: 15px;
        padding-right: 5px; }
    h1, h2, h3, h4, h5, h6 {
        text-align: left; }
    h1 {
        @include railR;
        color: $dark-gray;
        font-size: 45px;
        font-weight: 400;
        letter-spacing: 2.46px;
        margin-bottom: 10px;
        @include md {
            font-size: 36px;
            letter-spacing: 1.97px; }
        @include xs {
            font-size: 32px;
            letter-spacing: 1.75px; } }
    h2 {
        @include railR;
        font-size: 23px;
        color: $dark-gray;
        font-weight: 400;
        text-transform: uppercase;
        letter-spacing: 2.46px;
        margin-bottom: 30px;
        margin-top: 65px;
        @include md {
            margin-top: 40px;
            font-size: 20px;
            letter-spacing: 2.14px; }
        @include xs {
            margin-top: 35px;
            margin-bottom: 20px; } }

    h4 {
        @include osB;
        color: $dark-gray;
        font-size: 16px;
        font-weight: 700;
        letter-spacing: 1.08px;
        margin-bottom: 30px; }
    p {
        color: $dark-gray;
        @include osR;
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 1.08px;
        margin-bottom: 25px;
        @include md {
            font-size: 14px;
            letter-spacing: 0.94px;
            margin-bottom: 20px; }
        @include xs {
 } }            // margin-bottom: 15px
    a {
        text-decoration: none;
        color: #984a9d;
        letter-spacing: 1.08px;
        @include osR;
        font-weight: 400;
        font-size: 16px;
        @include xs {
            font-size: 14px; } }

    ul {
        p {
            margin-bottom: 0; } }
    ul, ol {
        @include osR;
        font-size: 16px;
        color: $dark-gray;
        letter-spacing: 1.08px;
        margin-bottom: 22px;
        @include xs {
            font-size: 14px;
            letter-spacing: 0.94px;
            margin-bottom: 10px; }
        li {
            margin-bottom: 4px; } }
    ol {
        counter-reset: list; }
    ol > li {
        list-style: none;
        position: relative;
        padding-left: 1.4em; }

    ol > li:before {
        counter-increment: list;
        content: counter(list) ") ";
        position: absolute;
        left: 0; }

    &__address {
        margin-bottom: 25px;
        p {
            margin-bottom: 0; } }
    .no-mt {
        margin-top: 0; }
    .container {
        @include r(992) {
            max-width: none; } } }

