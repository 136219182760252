.js-toggle-target {
  display: none;
  position: relative;
  overflow: hidden;
  max-height: 1px;
  opacity: 0;
  visibility: hidden;
  transition: max-height .5s;

  &.is-open {
    max-height: 2500px;
    opacity: 1;
    visibility: visible;
    transition: 1.3s; } }

.js-toggle {
  &:before,
  &:after {
    transition: .5s; }
  &.is-open {
    &:before {
      transform: translateX(-4px) rotate(-40deg);
      transition: .5s; }
    &:after {
      transform: translateX(4px) rotate(40deg);
      transition: .5s; } } }

.js-toggle-expand {
  display: block;
  max-height: 90px;
  opacity: 1;
  visibility: visible;

  &:after {
    position: absolute;
    content: '';
    width: 100%;
    height: 30px;
    left: 0;
    bottom: 0;
    background: linear-gradient(to bottom, rgba(white, 0) 0%, rgba(white, 1) 90%, rgba(white, 1) 100%); }

  &.is-open {
    &:after {
      height: 1px; } } }
