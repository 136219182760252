.select_xs {
  .select__title {
    padding-left: 8px;
    font-size: 16px;
    width: 128px; } }

.select_width-md {
  .select__title {
    width: 173px; } }

.js-toggle-time-selects {
  display: none; }

.booking-segment__list {
  display: block; }

.booking-segment__list ul:not(:last-child),
.booking-segment__list ol:not(:last-child) {
  margin: 0; }

.booking__submit {
  margin-top: 25px; }

.booking-request {
    &__content {
        min-height: 5px; } }
