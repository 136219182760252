.search-map {
    $this: &;
    &__inner {
        max-width: 1135px;
        padding-top: 17px;
        margin: 0 auto;
        @include m {
            padding-left: 6vw;
            padding-right: 6vw;
            padding-top: 20px; }
        @include md {
            padding-left: 25px;
            padding-right: 25px; }
        @include xs {
            padding-top: 20px; } }

    &__cards {
        margin: 0 auto;
        padding-bottom: 100px;
        @include md {
            padding-bottom: 75px; }
        @include xs {
            padding-bottom: 50px; } }
    &__pagination {
        margin-top: 30px;
        margin-bottom: 25px;
        @include md {
            margin-top: 20px; } }
    &_half-map {
        #{$this}__inner {
            max-width: none;
            padding-left: 0;
            padding-right: 0;
            padding-top: 25px;
            padding-bottom: 0;
            @include xs {
                padding-top: 0; } }
        #{$this}__container {
            display: flex;
            position: relative; }
        #{$this}__cards {
            width: 50%;
            padding-left: 30px;
            padding-right: 10px;
            margin-right: auto;
            max-height: 100vh;
            overflow: auto;
            transition: all .25s ease;
            @include md {
                padding-left: 15px;
                padding-right: 15px; }
            @include xs {
                display: none; }
            &.is-open {
                width: 0;
                padding: 0; } }

        #{$this}__map-wrapper {
            width: 50%;
            padding-left: 10px;
            padding-right: 30px;
            max-height: 100vh;
            transition: all .25s ease;
            @include md {
                padding-left: 15px;
                padding-right: 15px; }
            @include xs {
                width: 100%;
                height: 100%;
                height: 100vh;
                padding-left: 0;
                padding-right: 0; }
            &.is-open {
                width: 100%;
                padding-left: 30px;
                @include md {
                    padding-left: 15px; }
                @include xs {
                    padding-left: 0; } } }
        .residence-card {
            width: calc(50% - 14px);
            max-width: none;
            @include md {
                width: 100%;
                margin: 0 auto;
                margin-bottom: 20px; } }
        .residence-cards {
            margin-top: -7px;
            @include md {
                margin: 0 auto; } }
        .residence-card__image {
            height: 275px;
            @include r(1400) {
                height: 225px; }
            @include m {
                height: 185px; }
            @include md {
                height: 225px; } }
        .next, .prev {
            top: 140px;
            @include r(1400) {
                top: 115px; }
            @include m {
                top: 95px; }
            @include md {
                top: 115px; } } } }


.search-map__cards::-webkit-scrollbar {
    width: 10px; }
.search-map__cards::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.2);
    border-radius: 10px; }
.search-map__cards::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #9c9c9c; }
