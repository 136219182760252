.dashboard {
    margin-right: auto;
    position: relative;
    padding-top: 100px;
    width: 100%;
    @include md {
        padding-right: 0; }
    @include sm {
        padding-top: 79px; }
    @include xs {
        padding-top: 62px; }
    &_full {
        .dashboard__content {
            max-width: none; } }
    &__inner {
        padding-left: 60px;
        padding-top: 30px;
        padding-right: 60px;
        padding-bottom: 40px;
        @include xs {
            padding-left: 25px;
            padding-right: 25px;
            padding-top: 40px; }
        &.has-no-pb {
            padding-bottom: 0; }

        &_wide {
            @include r(900) {
                padding-left: 30px;
                padding-right: 30px; } } }
    &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        position: relative;
        @include xs {
            align-items: flex-start; }
        &_mob-wrap {
            @include xs {
                display: block; } } }
    &__content {
        padding: 0 0 0 208px;
        max-width: 1550px;
        width: 100%;
        transition: padding .25s ease;
        @include sm {
            padding-left: 0; }
        @include xs {
            padding-left: 0; }
        &_center {
            padding-right: 208px;
            @include r(1600) {
                padding-right: 0; } } }
    &__columns {
        display: flex;
        height: 100%;
        padding: 0 60px;
        @include md {
            display: block; }
        @include xs {
            padding: 0 25px; } }
    &__col-left {
        padding: 35px 0 90px 0;
        padding-right: 60px;
        width: 50%;
        flex-shrink: 0;
        border-right: 1px solid #979797;
        @include md {
            border-right: 0;
            width: 100%;
            padding-top: 30px;
            padding-right: 0; }
        @include xs {
            padding: 30px 0; }
        &.has-no-bor-r {
            border-right: none; } }
    &__col-right {
        padding-left: 54px;
        flex: 1;
        padding-bottom: 90px;
        @include md {
            padding-left: 0; }
        @include xs {
            padding-bottom: 40px; }
        &_pt-lg {
            padding-top: 100px;
            @include md {
                padding-top: 75px; }
            @include xs {
                padding-top: 55px; } } }
    &__title {
        margin-bottom: 20px;
        @include xs {
            text-align: center;
            margin-bottom: 24px; }
        &_mob-left {
            @include xs {
                text-align: left; } } }
    &__subtitle-desc {
        margin-top: 10px; }
    &__subtitle {
        font-size: 16px;
        @include osSB;
        font-weight: 600;
        letter-spacing: 0.66px;
        color: $dark-gray;
        margin-bottom: 25px;
        &_mob-xs {
            @include xs {
                font-size: 14px;
                letter-spacing: 0.57px;
                margin-bottom: 15px; } } }
    &__contact-form {
        .switch_sm {
            .switch__checkbox {
                width: 39px;
                height: 20px; }
            .switch__circle {
                width: 19px;
                height: 19px; }
            .switch__label {
                margin-top: -29px;
                margin-left: 46px;
                margin-bottom: 15px;
                font-size: 13px; } }

        .field {
            @include md {
                max-width: 380px; }
            &:not(:last-child) {
                margin-bottom: 30px;
                @include md {
                    margin-bottom: 25px; }
                @include xs {
                    margin-bottom: 20px; } }
            input {
                @include xs {
                    height: 40px; } } } }
    &__upload-avatar {
        @include md {
            padding-top: 30px; }
        @include sm {
            padding-bottom: 70px; }
        @include xs {
            padding-top: 20px; } }
    &__notify-settings {
        p {
            margin-bottom: 18px;
            @include xs {
                margin-bottom: 8px; } }
        .settings-switch {
            text-align: center;
            margin-top: 30px;
            margin-bottom: 70px;
            @include md {
                margin-top: 40px;
                margin-bottom: 40px;
                text-align: left; }
            @include xs {
                margin-top: 35px;
                margin-bottom: 35px; } } }
    &__modal {
        @include osR;
        color: $dark-gray;
        font-size: 16px;
        letter-spacing: 1.08px;
        min-height: 60px;
        height: 1px;
        background-color: #99cccc;
        display: flex;
        align-items: center;
        justify-content: center;
        position: fixed;
        top: 100px;
        left: 0;
        padding-left: 208px;
        width: 100%;
        z-index: 100;
        display: none;
        @include sm {
            padding-left: 0;
            top: 79px; }
        @include xs {
            top: 61px;
            font-size: 14px;
            min-height: 50px; } }
    &__get-started {
        padding-top: 70px;
        @include md {
            padding-top: 30px; }
        @include xs {
            padding-top: 0; } }
    &__main-menu {
        margin-top: 120px;
        text-align: center;
        @include md {
            margin-top: 70px; }
        @include xs {
            margin-top: 32px; } }
    &__bookings-list {
        margin-top: 30px;
        margin-bottom: 30px;
        @include r(1600) {
            padding-right: 60px; }
        @include md {
            padding-right: 33px; }
        @include xs {
            padding-right: 0;
            margin-top: 15px; } }
    &__top-actions {
        display: flex;
        margin-bottom: 20px;
        @include xs {
            margin-bottom: 5px;
            display: block; }
        .btn {
            padding-left: 23px;
            padding-right: 23px;
            align-self: flex-end;
            @include xs {
                min-width: 130px;
                height: 30px;
                min-height: 30px;
                font-size: 14px;
                padding: 5px; }
            &:not(:last-child) {
                margin-right: 30px;
                @include xs {
                    margin-right: 0;
                    margin-bottom: 15px; } } } }
    &__btn {
        margin-top: 16%;
        display: block;
        width: 100%;
        text-align: right;
        @include md {
            margin-top: 60px; }
        @include sm {
            text-align: center; }
        .btn {
            @include xs {
                font-size: 14px;
                letter-spacing: 1.21px; } } }
    &__new-btn {
        display: none;
        @include xs {
            display: block;
            width: 30px;
            height: 30px;
            top: -18px;
            right: 0;
            position: absolute;
            border-radius: 50%;
            background-color: #009999;
            &:before, &:after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                width: 16px;
                height: 3px;
                border-radius: 4px;
                background-color: $white;
                transform: translate(-50%, -50%); }
            &:after {
                transform: translate(-50%, -50%) rotate(90deg); } } }
    &__booking-select {
        .select {
            margin-bottom: 0;
            @include xs {
                margin-bottom: 15px; }
            .custom-select__field {
                @include xs {
                    height: 30px; } }
            .custom-select__text {
                font-size: 16px;
                letter-spacing: 1.1px;
                text-align: left;
                color: $dark-gray;
                width: 155px;
                padding: 0 28px 0 20px;
                @include xs {
                    text-align: center;
                    width: 130px;
                    padding-left: 20px;
                    padding-right: 35px;
                    font-size: 14px;
                    letter-spacing: 0.94px;
                    height: 100%;
                    line-height: 30px; }
                &:before {
                    top: 26%;
                    right: 12px;
                    height: 14px;
                    width: 14px;
                    border-left: 4px solid #777777;
                    border-bottom: 4px solid #777777;
                    @include xs {
                        height: 10px;
                        width: 10px;
                        border-left: 3px solid #777;
                        border-bottom: 3px solid #777; } }
                // &.is-placeholder
 }                //     color: #9b9b9b
            .custom-select__input {
                @include xs {
                    width: 100%; } }
            .custom-select__list {
                text-align: left;
                color: $dark-gray;
                @include xs {
                    text-align: center; }
                li {
                    padding-left: 20px;
                    color: $dark-gray;
                    &:not(:last-child) {
                        border-bottom: 1px solid #009999; }
                    &:hover, &.is-active {
                        background-color: #99cccc; }
                    @include xs {
                        padding: 7px;
                        font-size: 14px; } } } }
        &_mob-lg {
            @include xs {
                .select {
                    max-width: 400px;
                    margin: 0 auto;
                    .custom-select__text {
                        width: 100%;
                        line-height: 40px;
                        padding-left: 30px;
                        padding-right: 30px; }
                    .custom-select__field {
                        height: 40px; }
                    .custom-select__list {
                        li {
                            padding: 7px 5px; } } } } } }
    &__reviews {
        padding-bottom: 40px; }
    &__listings {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
        @include md {
            margin-left: -25px;
            margin-right: -25px; }
        @include xs {
            margin-left: 0;
            margin-right: 0; }
        .residence-preview {
            margin-bottom: 40px;
            @include md {
                width: calc(50% - 50px);
                max-width: 100%;
                margin-right: 25px;
                margin-left: 25px; }
            @include xs {
                width: 100%;
                max-width: 400px;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 20px;
                padding-bottom: 25px; }
            &:not(:last-child) {
                margin-right: 55px;
                @include md {
                    margin-right: 25px;
                    margin-left: 25px; }
                @include xs {
                    margin-left: auto;
                    margin-right: auto;
                    border-bottom: 1px solid #979797; } } } }
    &__favorites {
        display: flex;
        flex-wrap: wrap;
        margin-top: 20px;
        margin-left: -30px;
        margin-right: -30px;
        @include xs {
            margin-left: 0;
            margin-right: 0;
            margin-top: 0; }
        .residence-card {
            margin: 0;
            margin-bottom: 20px;
            max-width: 287px;
            width: 287px;
            margin-right: 30px;
            margin-left: 30px;
            @include md {
                width: calc(50% - 20px);
                margin-right: 10px;
                margin-left: 10px;
                max-width: 100%; }
            @include sm {
                width: calc(50% - 40px);
                margin-left: 20px;
                margin-right: 20px; }
            @include xs {
                width: 100%;
                max-width: 300px;
                margin-left: auto;
                margin-right: auto; }
            &__image {
                width: 100%;
                height: 177px;
                img {
                    width: 100%;
                    height: 100%;
                    object-fit: cover; } } }
        .like-btn {
            font-size: 20px; }
        .next, .prev {
            top: 90px; } }
    &__save {
        text-align: center;
        margin-top: 25px; }
    &__empty {
        text-align: center;
        width: 100%; } }

.main-menu {
    display: inline-flex;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
    @include xs {
        flex-direction: column; }
    a {
        text-decoration: none; }
    &__row {
        display: flex;
        align-items: center;
        @include xs {
            margin: 0 -25px 70px; } }
    &__item {
        width: 225px;
        height: 225px;
        border-radius: 50%;
        background-color: #99cccc;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        margin: 25px;
        position: relative;
        @include md {
            width: 180px;
            height: 180px; }
        @include xs {
            width: 100px;
            height: 100px;
            margin: 0 25px; } }
    &__icon {
        font-size: 50px;
        color: $dark-gray;
        .icon-heart {
            @include xs {
                position: relative;
                top: 6px; } } }
    &__name {
        color: $dark-gray;
        @include osR;
        font-size: 23px;
        font-weight: 400;
        letter-spacing: 2.46px;
        @include md {
            font-size: 20px; }
        @include xs {
            position: absolute;
            top: calc(100% + 10px);
            left: 50%;
            transform: translate(-50%,0); } } }


.nonprofit-desc {
    margin-top: 50px;
    @include xs {
        margin-top: 25px; }
    &__text {
        margin-bottom: 40px;
        @include xs {
            margin-bottom: 25px; } }

    .error-text {
        margin-bottom: 15px;
        margin-top: 5px; }

    ul, ol, li, p {
        font-size: 16px;
        margin-bottom: 20px;
        color: $dark-gray;
        @include osR;
        letter-spacing: 0.7px;
        @include xs {
            font-size: 14px;
            margin-bottom: 10px;
            line-height: 1.75em; }
        li {
            padding-left: 7px;
            margin-bottom: 0;
            &:not(:last-child) {
                margin-bottom: 0; } } }
    &__big {
        ul, ol, li, p {
            font-size: 18px;
            margin-bottom: 10px; } }
    .list__title {
        margin-bottom: 0; }
    .dashboard__subtitle {
        margin-bottom: 15px; }
    .radio {
        margin-bottom: 12px;
        label {
            @include xs {
                font-size: 14px; } } } }
