.header {
    &__inner {
        @include sm {
            display: flex;
            flex-flow: column;
            position: relative;
            max-height: 100vh; } } }

.nav {
    &__list {
        font-size: 0;
        @include sm {
            padding-bottom: 20px; } }
    &__item {
        display: inline-block;
        @include osR;
        @include fz-md;
        letter-spacing: 1.46px;
        position: relative;
        @include md {
            font-size: 18px; }
        @include sm {
            text-align: center;
            display: block; }
        @include xs {
            font-size: 12px;
            letter-spacing: 1.11px; }
        &:not(:last-child) {
            margin-right: 23px;
            @include md {
                margin-right: 10px; }
            @include sm {
                margin-right: 0;
                margin-bottom: 5px; }
            @include xs {
                margin-bottom: 0; } }
        &:hover {
            .menu-dropdown {
                opacity: 1;
                visibility: visible;
                pointer-events: all; } }
        & > a {
            border: 3px solid transparent;
            position: relative;
            border-radius: 7px;
            text-decoration: none;
            @include bdrad;
            display: block;
            padding: 9px 10px;
            @include md {
                padding: 5px 10px; }
            @include sm {
                border-radius: 0;
                border: none;
                padding: 5px; }
            &::after {
                width: 0;
                height: 3px;
                transition: width .3s ease;
                content: '';
                position: absolute;
                top: 100%;
                left: 0;
                right: 0;
                margin: auto;
                background-color: $white;
                border-radius: 3px; }
            &:hover {
                &::after {
                    width: 50%; } } }
        .menu-dropdown {
            top: 100%;
            @include md {
                top: 49px; }
            @include sm {
                display: none; } }
        &.is-active {
            &:not(:last-child) {
                margin-right: 19px;
                @include sm {
                    margin-right: 0; } }
            a {
                transition: all .2s ease;
                padding: 6px 9px;
                margin-left: 10px;
                letter-spacing: 1.46px;
                @include osR;
                @include md {
                    padding: 5px 20px;
                    top: 2px; }
                @include sm {
                    @include osR;
                    padding: 0;
                    margin-left: 0;
                    top: 0;
                    padding: 5px; }
                &::before, &::after {
                    display: none; } } }
        &.has-messages {
            span {
                position: relative;
                &:before {
                    content: '';
                    position: absolute;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background-color: #099;
                    top: 0;
                    right: -8px;
                    @include xs {
                        width: 7px;
                        height: 7px; } } } } } }

.header_transparent {
    .nav__item > a {
        color: $white;
        @include sm {
            color: $dark-gray;
            &:hover, &:focus, &:active {
                color: #2C75BB; } } }
    .nav__item.is-active a {
        border: 3px solid $white;
        @include sm {
            border: none; }
        &:hover, &:focus, &:active {
            background-color: $white;
            color: #000;
            @include sm {
                color: #2C75BB; } } }
    &.is-fixed {
        .nav__item.is-active a {
            &:hover, &:focus, &:active {
                background-color: $purple;
                color: #fff;
                @include sm {
                    color: #2C75BB;
                    background-color: transparent; } } } } }

.header_color {
    .nav__item a {
        color: $dark-gray;
        @include sm {
            &:hover, &:focus, &:active {
                color: #2C75BB; } } }
    .nav__item.is-active a {
        border: 3px solid $purple;
        @include sm {
            border: none; }
        &:hover, &:focus, &:active {
            background-color: $purple;
            color: $white;
            @include sm {
                color: $white; } } } }
