.message {
    $this: &;
    padding: 5px;
    margin-bottom: 30px;
    @include md {
        margin-bottom: 25px; }
    @include xs {
        margin-bottom: 10px;
        padding: 0; }
    &__person {
        text-align: center;
        text-decoration: none; }
    &__avatar {
        width: 60px;
        height: 60px;
        margin: 0 auto;
        overflow: hidden;
        border-radius: 50%;
        flex: 0 0 60px;
        margin-bottom: 5px;
        @include md {
            width: 50px;
            height: 50px;
            flex: 0 0 50px; }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover; } }
    &__name {
        font-size: 16px;
        color: $dark-gray;
        @include osR;
        letter-spacing: 1.1px;
        @include xs {
            font-size: 14px;
            letter-spacing: 0.94px; } }
    &__body {
        display: flex; }
    &__meta {
        @include osR;
        font-size: 11px;
        letter-spacing: 0.77px;
        color: $dark-gray;
        margin-bottom: 8px; }
    &__text {
        position: relative;
        width: 100%;
        @include bdrad;
        font-size: 16px;
        @include osR;
        letter-spacing: 1.1px;
        color: $dark-gray;
        @include xs {
            font-size: 14px;
            letter-spacing: 0.94px; }

        &.is-read,
        &.not-read {
            &:after {
                content: '\e939';
                font-family: 'icomoon';
                position: absolute;
                font-size: 26px;
                top: 0;
                left: -36px; } }

        &.is-read, {
            &:after {
                content: '\e93a';
                color: $aqua; } }

        a {
            color: $purple;
            &.filetype {
                position: relative;
                display: flex;
                align-items: center;
                min-height: 45px;
                padding-left: 40px;
                text-decoration: none;

                &:before {
                    content: '';
                    position: absolute;
                    width: 25px;
                    height: 25px;
                    display: inline-block;
                    left: 0;
                    top: 50%;
                    transform: translateY(-50%); }

                &.pdf {
                    &:before {
                        content: url('../img/filetypes/pdf.svg'); } }
                &.doc,
                &.docx {
                    &:before {
                        content: url('../img/filetypes/doc.svg'); } }
                &.xls,
                &.xlsx {
                    &:before {
                        content: url('../img/filetypes/xls.svg'); } }
                &.jpg,
                &.jpeg,
                &.png {
                    &:before {
                        content: url('../img/filetypes/img.svg'); } } } } }

    &.is-recieved {
        #{$this}__meta {
            text-align: right; }
        #{$this}__person {
            margin-right: 50px;
            @include md {
                margin-right: 30px; }
            @include xs {
                margin-right: 20px; } }
        #{$this}__text {
            background-color: rgba(233, 233, 233, 0.6);
            padding: 20px 10px;
            padding-left: 30px;
            border-top-left-radius: 0;
            @include xs {
                padding: 10px;
                padding-bottom: 15px; }
            &:before {
                content: '';
                position: absolute;
                top: 0;
                left: -29px;
                width: 30px;
                height: 34px;
                background-color: inherit;
                border-top-left-radius: 7px;
                border-bottom-left-radius: 7px;
                z-index: -1;
                @include md {
                    width: 20px;
                    left: -19px; }
                @include xs {
                    height: 25px;
                    width: 16px;
                    left: -15px; } } }

        .is-read,
        .not-read {
            margin-right: 35px;
            &:after {
                right: -36px;
                left: auto; } } }

    &.is-sent {
        #{$this}__meta {
            text-align: left; }
        #{$this}__person {
            margin-left: 50px;
            @include md {
                margin-left: 30px; }
            @include xs {
                margin-left: 20px; } }
        #{$this}__body {
            flex-direction: row-reverse; }
        #{$this}__text {
            background-color: #cacaca;
            padding: 20px;
            padding-right: 30px;
            border-top-right-radius: 0;
            @include xs {
                padding: 10px;
                padding-bottom: 15px; }
            &:before {
                content: '';
                position: absolute;
                top: 0;
                right: -29px;
                width: 30px;
                height: 34px;
                background-color: inherit;
                border-top-right-radius: 7px;
                border-bottom-right-radius: 7px;
                z-index: -1;
                @include md {
                    width: 20px;
                    right: -19px; }
                @include xs {
                    height: 25px;
                    width: 16px;
                    right: -15px; } } }
        .is-read,
        .not-read {
            margin-left: 35px; } } }
