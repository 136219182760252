.field {
	$this: &;
	@include clr;
	position: relative;
	input {
		background-color: $white;
		@include bdrad;
		@include fz-common;
		letter-spacing: 1.08px;
		width: 100%;
		height: 100%;
		line-height: normal;
		@include osR;
		color: $dark-gray;
		border: 1px solid transparent;
		-webkit-appearance: none;
		appearance: none;
		@include xs {
			font-size: 14px;
			letter-spacing: 0.94px; } }
	input::placeholder {
		color: #9b9b9b; }
	&__top {
		@include clr; }
	&__name {
		margin-bottom: 17px;
		float: left;
		color: $dark-gray;
		font-size: 18px;
		@include railM;
		letter-spacing: 2.57px;
		@include xs {
			font-size: 14px;
			letter-spacing: 2px;
			margin-bottom: 10px; } }
	&__link {
		float: right;
		text-decoration: none;
		@include fz-sm;
		color: #984a9d;
		@include railM;
		letter-spacing: 1.67px;
		padding-top: 4px;
		&:hover {
			text-decoration: underline; }
		@include xs {
			font-size: 12px;
			letter-spacing: 1.43px;
			padding-top: 0; } }
	&__input {
		position: relative;
		&_text-c {
			input {
				text-align: center; } }
		&_text-l {
			input {
				text-align: left; } }
		&_mr {
			margin-right: 52px; }
		&_mr-md {
			margin-right: 30px; }
		&_ml {
			margin-left: 25px; }
		&_italic {
			input::placeholder {
				font-style: italic; } }
		&_border-gray {
			input {
				border-color: #979797; } }
		&_height-common {
			height: 40px;
			input {
				padding: 5px 15px; } } }
	&__dropdown {
		position: absolute;
		top: 100%;
		margin-top: 5px;
		left: 0;
		width: 100%; }

	&__btn {
		position: absolute;
		right: 0;
		top: 50%;
		transform: translateY(-50%);
		margin-right: 18px;
		@include bdrad;
		background-color: $white;
		border: 1px solid #009999;
		font-size: 11px;
		@include osR;
		letter-spacing: 1.08px;
		color: $dark-gray;
		padding: 7px 19px 9px;
		transition: all .2s ease;
		line-height: 1;
		cursor: pointer;
		&:hover {
			background-color: #009999;
			color: $white; }
		@include xs {
			font-size: 8px;
			background-color: $white;
			color: #009999;
			padding: 7px 12px; }
		&_toggle-pass {
			display: none; } }
	&_checkbox {
		&:not(:last-of-type) {
			margin-bottom: 5px; } }

	&_password {
		position: relative;
		input[type="password"] {
			-webkit-text-security: disc;
			font-family: Verdana, sans-serif;
			color: #797979;
			letter-spacing: 2px;
			padding: 18px 35px;
			padding-right: 100px;
			@include xs {
				padding: 8px 14px;
				padding-right: 80px; }
			&::placeholder {
				@include railM;
				letter-spacing: 2px; } } }
	&_sm {
		input {
			min-height: 40px;
			padding: 8px 15px;
			@include xs {
				padding: 8px 15px; } } }
	&_height-md {
		input {
			min-height: 50px; } }

	&__info {
		font-size: 14px;
		color: $dark-gray;
		max-width: 150px;
		margin-top: 10px; }

	&_common {
		min-width: 250px;
		min-height: 50px;
		@include md {

			min-width: 240px;
			min-height: 50px; }
		input {
			padding: 14px 10px; }

		&_min {
			@include rmin(768) {
				min-width: 150px;
				max-width: 150px; } } }

	&_primary {
		&:not(:last-of-type) {
			margin-bottom: 40px;
			@include xs {
				margin-bottom: 30px; } }
		input {
			padding: 17px 35px;
			font-size: 18px;
			@include xs {
				padding: 7px 14px; } } }
	&_light {
		input {
			font-size: 14px;
			letter-spacing: 1.71px;
			background-color: transparent;
			border: 1px solid $white;
			border-top-right-radius: 0;
			border-bottom-right-radius: 0;
			color: $white; } }
	&_grey {
		input {
			background-color: #f2f2f2; } }
	&_with-btn {
		display: flex;
		input {
			min-height: 40px;
			border-right: none; }
		@include r(1200) {
			width: 100%;
			max-width: 323px; }
		.btn {
			margin-left: -5px; } }
	&_secondary {
		display: inline-block;
		vertical-align: middle;
		input {
			height: 40px;
			@include bdrad;
			border: solid 1px #979797;
			padding: 0 10px;
			font-size: 16px;
			@include xs {
				font-size: 14px;
				height: 30px; } }
		input[type="password"] {
			padding: 0 10px; }
		#{$this}__desc {
			margin-bottom: 16px;
			@include osR;
			font-weight: 400;
			font-size: 16px;
			color: $dark-gray;
			letter-spacing: 0.9px;
			&_flex {
				display: flex;
				justify-content: space-between; }
			@include xs {
				font-size: 14px;
				margin-bottom: 5px; }
			&_sm {
				font-size: 11px;
				letter-spacing: 0.66px;
				margin-bottom: 6px; }
			&_mb-sm {
				margin-bottom: 6px; }
			sup {
				font-size: 11px;
				display: block;
				letter-spacing: 0.61px; } }

		#{$this}__input {
			position: relative;
			&_width-xl {
				width: 250px;
				@include xs {
					width: 100%; } }
			&_width-lg {
				width: 155px; }
			&_width-md {
				width: 90px; }
			&_width-sm {
				width: 74px;
				@include xs {
					width: 59px; } }
			&_width-xs {
				width: 60px; } }
		#{$this}__row {
			display: flex;
			align-items: center;
			&_tag {
				padding-bottom: 20px; } }
		#{$this}__tag {
			font-size: 11px;
			color: $dark-gray;
			letter-spacing: 0.4px;
			@include osR;
			&_bottom {
				text-align: center;
				margin-top: 4px;
				position: absolute;
				left: 0;
				right: 0; } }

		#{$this}__note {
			font-size: 16px;
			color: $dark-gray;
			@include osR;
			font-weight: 400;
			letter-spacing: 0.9px;
			@include xs {
				font-size: 14px; }
			&_right {
				margin-left: 18px;
				@include xs {
					margin-left: 6px; } }
			&_left {
				margin-right: 5px;
				margin-left: 10px; }
			&_primary {
				font-weight: 600;
				letter-spacing: 1.21px;
				font-size: 18px;
				margin-left: 14px;
				@include xs {
					font-size: 14px;
					letter-spacing: 0.94px; } } } }
	&_full {
		display: block;
		&_half + &_half {
			margin-left: 4%; }
		&_half {
			width: 48%;
			float: left;
			@include md {
				width: 100%;
				float: none;
				margin-left: 0 !important; } } }

	&_mob-full {
		@include xs;
		@include xs {
			display: block;
			width: 100%;
			max-width: 100%; } }

	&_font-sm {
		input {
			font-size: 16px;
			letter-spacing: 0.2px;
			@include md {
				font-size: 14px; }
			@include xs {
				font-size: 12px;
				letter-spacing: 0; } } }
	&_p-xs {
		input {
			@include md {
				padding: 0 4px; } } }
	&_bdr-wider {
		input {
			border-width: 2px; } }


	&__error-message {
		color: red;
		font-size: 16px;
		margin-top: 8px;
		margin-bottom: 10px;
		@include railR;
		letter-spacing: 1.15px;

		&_narrow {
			max-width: 250px;
			margin-top: 25px !important; } }

	&.is-error {
		input, textarea {
			background-color: #fff8f9;
			border: 2px solid $red;
			box-shadow: none; }

		.field__error-message {
			display: block !important; } } }

.field_secondary {
	&.is-error {
		input, textarea {
			background-color: #fff8f9;
			border: 2px solid $red;
			box-shadow: none; }

		.field__error-message {
			display: block !important; } }

	.field__error-message {
		@include osSB;
		font-weight: 600;
		font-size: 14px;
		letter-spacing: 1.08px;
		color: $red;
		margin-top: 12px; } }

.published__rate {
	font-size: 14px;
	width: 130px;
	text-align: center;
	position: absolute;
	top: 38px;
	left: 115px;

	@include xs {
		position: relative;
		top: auto;
		left: auto;
		margin-top: 10px; } }
