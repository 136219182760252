.payout {
    text-align: center;
    max-width: 570px;
    margin: 0 auto;
    &__inner {
        max-width: 380px;
        margin: 0 auto; }
    &__icon {
        font-size: 125px;
        margin-bottom: 40px;
        @include md {
            font-size: 85px;
            margin-bottom: 20px; }
        @include xs {
            font-size: 60px;
            margin-bottom: 10px; }
        &_mb-xs {
            margin-bottom: 10px; } }
    &__row {
        border-bottom: 1px solid #E0E0E0;
        padding: 20px 0;
        text-align: left;
        padding-left: 60px;
        @include xs {
            text-align: center;
            padding: 15px 20px; }
        &_center {
            padding-left: 0;
            text-align: center;
            @include xs {
                padding: 20px; } } }
    &__title {
        font-size: 23px;
        color: $dark-gray;
        @include osR;
        letter-spacing: 2.5px;
        @include xs {
            font-size: 14px;
            letter-spacing: 1.5px; } }
    &__main-title {
        font-size: 23px;
        @include osB;
        letter-spacing: 2.5px;
        color: $dark-gray;
        @include xs {
            font-size: 18px; } }
    &__name, &__desc {
        color: $dark-gray;
        letter-spacing: 1.1px;
        font-size: 16px;
        @include xs {
            font-size: 14px;
            letter-spacing: 0.94px; } }
    &__name {
        @include osSB;
        margin-bottom: 7px; }
    &__desc {
        @include osR; }
    &__delete {
        margin-top: 50px;
        @include xs {
            margin-top: 20px; } }
    &__warning {
        color: $red;
        margin: 10px 0;
        font-size: 14px;
        @include osSB;
        letter-spacing: 0.9px;
        @include xs {
            font-size: 11px;
            letter-spacing: 0.7px;
            max-width: 380px;
            margin: 15px auto 20px auto; } }
    &__btn {
        .btn {
            @include xs {
                font-size: 14px; } } } }

.payout-form {
    text-align: center;
    max-width: 720px;
    margin: 0 auto;

    &__center {
        padding-top: 70px;

        @include md {
            padding-top: 30px; } }

    &_wide {
        max-width: 750px; }
    &__title {
        @include osSB;
        font-size: 16px;
        color: $dark-gray;
        letter-spacing: 1.1px;
        margin-bottom: 15px; }
    &__text {
        p {
            @include osR;
            font-size: 16px;
            color: $dark-gray;
            letter-spacing: 1.1px;
            @include md {
                font-size: 14px;
                letter-spacing: 0.94px; }
            &:not(:last-of-type) {
                padding-bottom: 10px; } } }
    &__row {
        margin-bottom: 50px;
        @include xs {
            margin-bottom: 25px; }
        &_mt-bg {
            margin-top: 80px;
            @include md {
                margin-top: 50px; }
            @include xs {
                margin-top: 25px; } } }
    &__form {
        text-align: left;
        .field {
            margin: 0 auto;
            margin-bottom: 20px;
            max-width: 330px;
            &:not(.field_full):not(:last-child) {
                margin-right: 50px; }
            &.field_mob-full {
                @include xs {
                    max-width: 100%; } }
            input {
                @include xs {
                    height: 40px; } } }
        .form__col {
            .field {
                &:last-child {
                    @include md {
                        margin-bottom: 0; } } } }

        &_width-md {
            max-width: 330px;
            margin: 0 auto; } }
    &__note {
        display: flex;
        margin-top: 30px;
        padding: 15px 0;
        border-top: 1px solid #979797;
        border-bottom: 1px solid #979797;

        @include md {
            flex-direction: column; }

        img {
            max-width: 100%;
            max-height: 100%;
            display: block;
            @include md {
                margin-bottom: 15px; } }

        p {
            flex: 1;
            padding-left: 15px; } }

    &__btn {
        .btn {
            @include xs {
                min-width: 100%;
                font-size: 14px;
                letter-spacing: 0.94px; } } }

    &__contact {
        font-weight: 700;
        margin-top: 30px;

        a {
            color: $aqua; } } }

.radio-row {
    @include xs {
        display: flex;
        justify-content: space-around;
        flex-direction: column;

        .radio + .radio {
            margin-top: 15px; } }
    .radio {
        display: inline-flex;
        align-items: center;
        &:not(:last-child) {
            margin-right: 85px;
            @include xs {
                margin-right: 0; } } } }

