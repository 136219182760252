.photo-load-actions {
    &__inner {
        @include bdrad;
        overflow: hidden; }
    &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 3px 10px;
        background-color: #fff;
        position: relative;
        &:not(:last-child) {
            border-bottom: 2px solid #cdcdcd; } }
    &__icon {
        font-size: 25px;
        color: #009999; }
    &__text {
        font-size: 18px;
        @include osR;
        color: $dark-gray;
        letter-spacing: 1.21px; }
    &__btn {
        width: 100%;
        text-align: center;
        background-color: $white;
        margin-top: 15px;
        @include bdrad;
        .btn {
            width: 100%;
            font-size: 18px;
            letter-spacing: 1.21px;
            height: 40px;
            color: $dark-gray;
            @include osR;
            background-color: transparent;
            border: 1px solid #3c6996; } }
    input {
        opacity: 0;
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        z-index: 10; } }
