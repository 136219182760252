.about-video {
    padding: 55px 30px;
    background-color: #33669a;
    @include md {
        padding: 40px 30px; }
    @include xs {
        padding: 30px 15px;
        padding-bottom: 50px; }
    &__video {
        width: 100%;
        max-width: 788px;
        height: 443px;
        margin: 0 auto;
        position: relative;
        margin-bottom: 30px;
        @include md {
            margin-bottom: 40px;
            max-width: 701px;
            height: 393px; }
        @include xs {
            max-width: 430px;
            height: 240px;
            margin-bottom: 20px; }
        // +r(480)
        //     height: 280px
        @include r(460) {
            max-width: 287px;
            height: 160px;
 }            // height: 230px
 }        // +r(340)
    &__text {
        text-align: center;
        max-width: 990px;
        margin: 0 auto;
        color: $white;
        @include railR;
        @include fz-lg-m;
        letter-spacing: 3.42px;
        line-height: 1.2;
        @include md {
            @include fz-md;
            letter-spacing: 2px; }
        @include xs {
            letter-spacing: 1.16px; } } }
