.custom-table {
  max-width: 800px;
  margin: 0 auto;
  text-align: left;
  margin-bottom: 45px;
  @include osR;
  color: $dark-gray;
  font-size: 18px;
  letter-spacing: 1.21px;
  line-height: 1.39em;
  display: flex;
  flex-direction: column;

  @include xs {
    font-size: 14px;
    max-width: 320px; }

  &__row {
    display: flex;
    flex-direction: row;
    padding: 10px 0;

    @include xs {
      flex-direction: column; } }

  &__row + &__row {
    @include xs {
      margin-top: 25px; } }

  &__head {
    font-weight: 700;

    @include xs {
      display: none; } }

  &__item {
    flex-grow: 1;

    @include xs {
      display: flex;
      justify-content: space-between; }

    &_fixed {
      flex: 0 0 25%;
      max-width: 25%;

      @include xs {
        flex: 0 0 100%;
        max-width: 100%; } } }

  &__title {
    display: none;

    @include xs {
      display: block;
      font-weight: 600; } } }
