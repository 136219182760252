.profile {
  &__submit {
    display: block;
    position: fixed;
    width: calc(100% - 208px);
    right: 0;
    bottom: 0;
    padding: 15px;
    background-color: white;
    border-top: 1px solid $blue;
    text-align: center;
    z-index: 99;
    @include sm {
      width: 100%; } }

  &__checkbox {
    padding: 0;
    margin-bottom: 15px;

    .checkbox__label {
      font-size: 13px; } }

  &__popup {
    .popup__content {
      background-color: #99cccc;
      max-width: 375px;
      padding: 25px;
      text-align: center;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 235px; } } }

.phone__status {
  position: absolute;
  right: 7px;
  top: 50%;
  border-radius: 15px;
  background-color: rgba($red, .7);
  color: white;
  padding: 5px 10px;
  transform: translateY(-50%);
  font-size: 12px;
  font-weight: 600;

  &.verified {
    background-color: $aqua; } }
