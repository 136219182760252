.messages {
    display: flex;
    align-items: flex-start;
    height: 100vh;
    left: 0;
    top: 0;
    position: fixed;
    padding-left: 208px;
    max-width: 1440px;
    width: 100%;
    @include md {
        display: block;
        position: relative;
        left: auto;
        top: auto;
        height: auto; }

    @include sm {
        padding-left: 0; }
    &__left-col {
        padding-top: 100px;
        width: percentage(440px/1220px);
        max-width: 400px;
        border-right: 1px solid #979797;
        border-top: 1px solid #979797;
        height: 100%;
        max-height: 100vh;
        flex: auto;
        @include r(1325) {
            width: 50%;
            max-width: 50%; }
        @include md {
            max-width: none;
            width: 100%;
            padding-top: 0; }
        &.is-hidden {
            @include md {
                display: none; } } }
    &__right-col {
        height: 100vh;
        padding-top: 100px;
        border-right: 1px solid #979797;
        width: percentage(780px/1220px);
        flex: auto;
        @include r(1325) {
            width: 50%;
            max-width: 50%; }
        @include md {
            max-width: none;
            width: 100%;
            padding-top: 0 !important;
            display: block;
            height: auto; }

        @include sm {
            padding-top: 79px; }
        @include xs {
            padding-top: 62px; }
        &.is-hidden {
            @include md {
                display: none; } } }
    &__chats {
        &.has-no-results {
            .chat-min {
                text-align: center;
                justify-content: center;
                @include md {
                    display: none; } } }
        .messages__empty {
            margin-top: 50px;
            @include rmin(1024) {
                display: none; } } }
    &__chat-wrapper {
        height: 100%;
        display: flex;
        flex-direction: column;
        @include md {
            height: auto;
            display: block; } }
    &__wrapper {
        max-height: 100%;
        padding-bottom: 40px;
        overflow: auto; }
    &__search {
        margin-bottom: 30px;
        width: 100%;
        padding-left: 40px;
        padding-right: 20px;
        @include md {
            padding-left: 30px;
            padding-right: 30px; }
        @include xs {
            margin-bottom: 27px;
            padding-left: 25px;
            padding-right: 25px; } }
    &__top {
        padding: 10px 20px 20px;
        border-bottom: 1px solid #979797;
        display: flex;
        justify-content: space-between;
        flex-wrap: wrap;
        align-items: flex-start;

        @include md {
            padding-top: 12px; }
        @include xs {
            padding: 15px;
            padding-bottom: 5px; }
        &.is-empty {
            min-height: 130px; }

        &__title {
            display: flex;
            justify-content: space-between; }

        &_tablet {
            @include rmin(1024) {
                display: none; } } }
    &__chat {
        display: flex;
        flex-direction: column;
        overflow: auto;
        flex: auto; }
    &__send {
        width: 100%;
        background-color: #f4f4f4; }
    &__file-icon {
        font-size: 34px;
        margin-right: 15px;
        color: $aqua;
        cursor: pointer;
        &>* {
            display: none !important; } }
    &__back {
        @include md {
            margin-bottom: 15px; }
        @include xs {
            margin-bottom: 10px; } }
    &__empty {
        height: 100%;
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
        flex: 1; }
    &__mob-title {
        font-size: 12px;
        @include railSB;
        letter-spacing: 0.81px;
        color: $dark-gray;
        margin-top: 8px;
        @include rmin(600) {
            display: none; } }

    &__file {
        display: none !important; }

    &__discount {
        display: flex;
        flex-direction: row;
        align-items: flex-end;
        justify-content: space-between;
        flex-wrap: wrap;
        padding-top: 10px; }

    &__attachment {
        position: absolute;
        bottom: 100%;
        max-width: 90%;
        left: 0;
        padding: 7px 20px 7px 15px;
        background-color: $aqua;
        color: white;

        &_delete {
            width: 28px;
            height: 28px;
            line-height: 26px;
            right: -14px;
            top: -14px;
            border-color: #979797;
            color: #979797; } } }

.discount {
    &__input {
        font-size: 16px;
        letter-spacing: 0.2px;
        height: 40px;
        border-radius: 7px;
        border: solid 1px #979797;
        padding: 0 10px;
        width: 90px;
        text-align: center;

        &.has-error {
            background-color: #fff8f9;
            border: 2px solid #d0021b; } }

    &__btn {
        padding-right: 15px;
        padding-left: 15px;
        @include md {
            padding-right: 7px;
            padding-left: 7px; } }
    &__type {
        font-size: 16px;
        margin-right: 7px;
        display: inline-block;
        width: 15px; }

    &__message {
        text-align: center; } }

.messenger {
    position: relative;
    overflow: auto;
    padding: 20px;
    padding-bottom: 20px; }

.chat-meta {
    margin-bottom: 30px;
    @include osL;
    color: $dark-gray;
    letter-spacing: 0.8px;
    font-size: 12px;
    text-align: center;
    padding: 0 8px;
    background-color: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    @include md {
        margin-bottom: 20px; }
    @include xs {
        margin-bottom: 10px;
        padding: 0; }
    &:before, &:after {
        content: '';
        flex-grow: 1;
        height: 1px;
        background-color: #979797;
        padding: 0 10px; }
    &:before {
        margin-right: 10px; }
    &:after {
        margin-left: 10px; } }


.residence-msg-preview {
    display: flex;
    text-decoration: none;
    margin-right: 15px;
    padding-top: 10px;

    &__image {
        width: 148px;
        height: 90px;
        margin-right: 15px;
        flex: 0 0 148px;
        @include md {
            width: 132px;
            height: 80px;
            flex: 0 0 132px;
            margin-right: 20px; }
        @include xs {
            display: none; }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover; } }
    &__meta {
        @include osL;
        font-size: 16px;
        color: $dark-gray;
        letter-spacing: 1.1px;
        margin-bottom: 10px;
        @include md {
            margin-bottom: 5px; }
        @include xs {
            font-size: 12px;
            letter-spacing: 0.81px;
            margin-bottom: 0; } }
    &__title {
        font-size: 16px;
        @include osSB;
        letter-spacing: 1.1px;
        color: $dark-gray;
        margin-bottom: 10px;
        @include md {
            margin-bottom: 8px; }
        @include xs {
            font-size: 14px;
            letter-spacing: 0.94px;
            margin-bottom: 0; } }
    &__rating {
        @include xs {
            display: none; } } }

.send-bar {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    padding-right: 25px;
    min-height: 74px;
    border-top: 1px solid #979797;
    position: relative;
    @include xs {
        min-height: 46px;
        padding-right: 13px; }
    &__input, input, textarea {
        width: 100%;
        height: 100%;
        min-height: 100%;
        border: none;
        resize: none;
        background-color: transparent;
        font-size: 16px;
        color: $dark-gray;
        @include osR;
        letter-spacing: 1.1px;
        padding: 10px 25px;
        display: flex;
        align-items: center;
        min-height: 74px;
        @include xs {
            min-height: 46px;
            font-size: 14px;
            letter-spacing: 0.94px;
            padding-top: 0; } }
    input::placeholder, textarea::placeholder {
        font-style: italic;
        @include osL; }
    textarea {
        opacity: 1;
        max-height: 170px;
        overflow: auto;
        &.is-center {
            padding-top: 26px;
            @include xs {
                padding-top: 14px; } } }
    &__btn {
        padding-bottom: 17px;
        display: flex;
        justify-content: center;
        align-items: center;
        width: 215px;
        @include xs {
            padding-bottom: 6px; }
        .btn {
            @include md {
                min-width: 82px;
                padding-left: 10px;
                padding-right: 10px; }
            @include xs {
                min-width: 53px;
                min-height: 36px;
                font-size: 14px;
                letter-spacing: 1.21px; } } } }

.messages__wrapper, .messenger, .messages__chat, textarea {
    &::-webkit-scrollbar {
        width: 5px; } }
.messages__wrapper, .messenger, .messages__chat, textarea {
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #9c9c9c; } }
