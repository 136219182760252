.img-caption {
    max-width: 420px;
    margin: 0 auto;
    display: block;
    text-decoration: none;
    a {
        text-decoration: none;
        color: $dark-gray; }
    &__image {
        margin: 0 20px;
        @include md {
            width: 96%;
            margin: 0 2%;
            height: auto; }
        @include xs {
            width: calc(100% - 14px);
            margin: 0 7px; }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover; } }
    &__title {
        text-align: center;
        margin-top: 20px;
        color: $dark-gray;
        @include osB;
        @include fz-md;
        letter-spacing: 2.46px;
        @include md {
            font-size: 14px; }
        @include xs {
            font-size: 10px;
            margin-top: 4px;
            letter-spacing: 1.15px; } } }

