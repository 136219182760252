.reasons-video {
    padding: 65px 30px;
    padding-bottom: 75px;
    background-color: #99cccc;
    @include md {
        padding: 40px 0px; }
    @include xs {
        padding: 40px 15px;
        background-color: $white; }
    &__videos {
        @include xs {
            padding: 0 20px; } }
    &__title {
        margin-bottom: 62px;
        @include xs {
            margin-bottom: 20px; } } }
