.search-input {
    border-bottom: 1px solid #979797;
    position: relative;
    height: 65px;
    @include xs {
        height: 50px; }
    &__icon {
        font-size: 29px;
        color: #757575;
        position: absolute;
        left: 0;
        bottom: 5px;
        @include xs {
            font-size: 24px; } }
    &__input {
        width: 100%;
        height: 100%;
        padding-left: 45px;
        padding-right: 75px; }
    input::placeholder {
        color: #c7c7c7;
        letter-spacing: 0.36px;
        @include osL;
        font-style: italic; }
    input {
        width: 100%;
        height: 100%;
        font-size: 16px;
        color: $dark-gray;
        padding-top: 27px;
        @include xs {
            font-size: 14px;
            letter-spacing: 0.32px;
            padding-top: 13px; } }
    &__btn {
        position: absolute;
        bottom: 13px;
        right: 6px;
        @include xs {
            bottom: 10px; } } }
