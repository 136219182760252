.flatpickr-calendar {
    box-shadow: none;
    border: 1px solid #9b9b9b;
    @include bdrad;
    padding-top: 22px;
    width: 322px;
    @include md {
        padding-top: 10px; }
    @include xs {
        padding-top: 0;
        width: 270px; } }

.flatpickr-months {
    position: relative;
    margin-bottom: 30px;
    @include xs {
        margin-bottom: 12px; } }

.flatpickr-current-month, .flatpickr-current-month input.cur-year {
    @include osB;
    font-weight: 700;
    font-size: 14px;
    color: #2c3135; }

.flatpickr-day {
    border: none;
    font-size: 12px;
    @include osR;
    font-weight: 400;
    color: $dark-gray; }

span.flatpickr-weekday {
    @include osR;
    font-weight: 400;
    font-size: 12px;
    color: #7f8fa4; }

.flatpickr-day, .flatpickr-day.nextMonthDay, .flatpickr-day.prevMonthDay {
    max-width: 46px;
    height: 46px;
    line-height: 46px;
    border-radius: 0;
    border-right: 1px solid #979797;
    border-bottom: 1px solid #979797;
    @include xs {
        max-width: 40px;
        height: 40px;
        line-height: 40px; }
    &:nth-child(7n-6) {
        border-left: none; }
    &:nth-last-child(-n+7) {
        border-bottom: none;
        &:hover {
            border-bottom: none; } }
    &:hover {
        border-right: 1px solid #979797;
        border-bottom: 1px solid #979797; } }

.flatpickr-day.today {
    border-radius: 0;
    background-color: #99cccc;

    &.booked {
        background-color: white; } }

.flatpickr-day.selected, .flatpickr-day.prevMonthDay.selected, .flatpickr-day.nextMonthDay.selected, .flatpickr-day.inRange {
    background-color: #dcdcdc;
    background: #dcdcdc;
    border-color: #979797;
    color: $dark-gray;
    position: relative;
    font-size: 0;
    &:before {
        content: 'X';
        font-size: 12px; }
    &:hover, &:focus {
        border-color: #979797;
        background-color: #979797; } }

.dayContainer {
    border-top: 1px solid #979797;
    border-radius: 7px;
    overflow: hidden; }

.flatpickr-months .flatpickr-next-month, .flatpickr-months .flatpickr-prev-month {
    color: #bac2c7;
    fill: #bac2c7; }

.flatpickr-rContainer, .flatpickr-days, .dayContainer {
    width: 100%; }

.dayContainer {
    min-width: 322px;
    max-width: 322px;
    @include xs {
        min-width: 270px;
        max-width: 270px; } }

.flatpickr-current-month span.cur-month {
    margin-left: 20px; }

.rangeMode .flatpickr-day {
    margin-top: 0; }
.flatpickr-day.selected.startRange, .flatpickr-day.selected.endRange, .flatpickr-day.endRange, .flatpickr-day.endRange.nextMonthDay, .flatpickr-day.endRange.prevMonthDay {
    border: 0;
    background: #c1c1c1;
    box-shadow: none!important;
    font-size: 0;
    &:before {
        content: 'X';
        font-size: 12px; }
    &:hover, &:focus {
        box-shadow: none!important;
        background: #c1c1c1;
        color: $dark-gray; } }

.flatpickr-calendar.arrowTop,
.flatpickr-calendar.arrowBottom {
    &:before, &:after {
        display: none; } }
.flatpickr-day.inRange {
    box-shadow: none; }
.flatpickr-day.today.inRange {
    border-bottom: 1px solid #979797;
    border-right: 1px solid #979797;
    background-color: #99cccc; }
.flatpickr-day.disabled, .flatpickr-day.disabled:hover {
    border-bottom: 1px solid #979797;
    border-right: 1px solid #979797;
    color: rgba(57,57,57,0.2);

    &.booked {
        color: #4a4a4a;
        text-align: right;
        line-height: 50px;
        padding: 8px;
        overflow: hidden;

        &:before {
            content: '';
            display: block;
            position: absolute;
            background-color: #dcdcdc;
            width: 50px;
            height: 150px;
            transform: rotate(45deg);
            left: -25px;
            top: -65px; }

        &:after {
            content: 'x';
            color: white;
            font-weight: 700;
            position: absolute;
            left: 10px;
            top: 6px;
            line-height: 1; } } }

.datepicker-select {
    width: 173px;
    height: 40px;
    position: relative;
    input::placeholder {
        color: $dark-gray; }
    &_sm {
        width: 128px; }
    &__input:not(.flatpickr-input) {
        display: none; }
    .flatpickr-input {
        height: 100%;
        width: 100%;
        font-size: 16px;
        @include bdrad;
        border: 1px solid #979797;
        padding-left: 8px;
        padding-right: 20px;
        letter-spacing: 0.88px;
        white-space: nowrap;
        color: $dark-gray;
        @include osR;
        font-weight: 400;
        position: relative; }
    &:before {
        content: '';
        position: absolute;
        top: 33%;
        right: 12px;
        height: 10px;
        width: 10px;
        border-left: 3px solid $dark-gray;
        border-bottom: 3px solid $dark-gray;
        transform: rotate(-45deg) scale(1,1) translate(0, 0);
        transform-origin: center;
        transition: .3s ease;
        z-index: 15; } }
