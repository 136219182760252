.choose-card {
    text-align: center;
    background-color: $white;
    @include bdrad;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.5);
    padding: 20px;
    padding-top: 33px;
    min-height: 300px;
    text-decoration: none;
    transition: all .2s ease;
    border: none;
    @include md {
        padding: 10px;
        padding-top: 25px;
        padding-bottom: 15px;
        min-height: 227px; }
    @include xs {
        border: 1px solid #979797; }
    &:hover, &:focus {
        background-color: #99cccc;
        box-shadow: none;
        border: none;
        [class^="icon-"], [class*=" icon-"] {
            &:before {
                color: #fff; } } }
    &__icon {
        font-size: 27px;
        height: 50px;
        margin-bottom: 20px;
        @include md {
            height: auto;
            margin-bottom: 7px; }
        [class^="icon-"], [class*=" icon-"] {
            &:before {
                color: #009999;
                transition: color .2s ease; } } }
    .icon-profile {
        font-size: 27px; }
    .icon-calendar-check {
        font-size: 47px;
        @include md {
            font-size: 36px; } }
    .icon-empty_page {
        font-size: 38px;
        @include md {
            font-size: 30px; } }
    &__title, &__desc {
        color: $dark-gray;
        font-size: 16px;
        @include osR;
        letter-spacing: 1.08px; }
    &__title {
        margin-bottom: 20px;
        @include md {
            @include osSB; } } }

.animated {
    animation-duration: 3s;

    &.card-highlight {
        background-color: #99cccc;
        animation-name: cardHighlight; } }

@keyframes cardHighlight {
    0% {
        background-color: white; }
    66% {
        background-color: white; }
    100% {
        background-color: #99cccc; } }

