.residence-preview {
    max-width: 250px;
    a {
        text-decoration: none; }
    &__inner {
        display: block; }
    &__image {
        width: 250px;
        height: 165px;
        margin-bottom: 10px;
        @include md {
            width: 100%; }
        @include xs {
            height: auto;
            margin-bottom: 5px; }
        &.has-no-image {
            border: 1px solid #979797;
            position: relative;
            overflow: hidden;
            @include xs {
                min-height: 230px; }
            @include r(360) {
                min-height: 180px; }
            &:before {
                content: '';
                position: absolute;
                width: 100%;
                height: 100%;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-image: url(../img/img_placeholder.svg);
                background-position: 50% 50%;
                background-repeat: no-repeat;
                background-color: #fff; } }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover; } }
    &__title {
        @include osB;
        font-size: 16px;
        color: $dark-gray;
        margin-bottom: 5px;
        @include xs {
            margin-bottom: 0;
            font-size: 14px; } }
    &__subtitle {
        @include osR;
        font-size: 16px;
        color: $dark-gray;
        @include xs {
            font-size: 12px;
            @include osL; } }
    &__actions {
        margin-top: 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include xs {
            display: block; }
        .btn {
            padding: 2px 10px;
            @include xs {
                display: block;
                width: 100%;
                max-width: 400px;
                margin-left: auto;
                margin-right: auto;
                min-height: 35px;
                &:not(:last-child) {
                    margin-bottom: 15px; } } } } }
