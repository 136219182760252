.venue-list {
    background-color: #d8d8d8;
    padding: 40px 15px;
    text-align: center;
    &__title {
        margin-bottom: 30px; }
    &__text {
        font-size: 14px;
        @include osR;
        letter-spacing: 1.5px;
        color: $dark-gray;
        margin-bottom: 40px;
        padding: 0 45px; }
    &__btn {
        .btn {
            min-width: 200px;
            padding: 9px 30px; } } }
