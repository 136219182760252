.b-step {
    padding-top: 125px;
    @include md {
        padding-top: 74px; }
    @include sm {
        padding-top: 55px; }
    &__title {
        margin-bottom: 30px;
        @include sm {
            margin-bottom: 20px; }
        &_mb-sm {
            margin-bottom: 16px;
            @include sm {
                margin-bottom: 16px; } }
        &_mb-xs {
            margin-bottom: 10px; } }
    &__desc {
        @include osR;
        font-weight: 400;
        font-size: 16px;
        color: $dark-gray;
        letter-spacing: 0.6px;
        margin-bottom: 16px;
        line-height: 1.75;
        @include xs {
            font-size: 14px; }
        &_sm {
            line-height: normal;
            letter-spacing: 1.08px;
            font-size: 16px;
            @include md {
                font-size: 14px;
                letter-spacing: 0.94px; }
            @include xs {
                font-size: 12px;
                letter-spacing: 0.81px; } }
        &_mb-md {
            margin-bottom: 27px;
            @include md {
                margin-bottom: 16px; }
            @include xs {
                margin-bottom: 10px; } }
        &_padding {
            padding-top: 25px; } }
    &__fields {
        &_mb-lg {
            margin-bottom: 70px; } }
    &__range-slider {
        margin-bottom: 20px;
        min-height: 180px;
        @include bdrad;
        border: 1px solid #979797;
        padding: 20px;
        @include xs {
            padding: 10px 18px;
            padding-bottom: 5px;
            margin-bottom: 30px;
            min-height: 140px; } }
    &__checkbox-tabs {
        margin-bottom: 20px;
        // +xs
 }        //  // margin-bottom: 80px
    &__add-time {
        margin-left: auto;
        text-align: right;
        margin-bottom: 30px; }
    &__row {
        display: flex;
        justify-content: space-between;
        margin-bottom: 37px;
        @include md {
            display: block; }
        @include sm {
            margin-bottom: 25px; }
        .b-step__counter {
            @include md {
                margin-top: 45px;
                text-align: left; }
            @include sm {
                margin-top: 25px; } } }
    &__radio {
        .radio {
            &:not(:last-child) {
                margin-bottom: 10px;
                @include xs {
                    margin-bottom: 15px; } } } }
    &__select {
        margin-bottom: 90px;
        @include md {
            margin-bottom: 110px; }
        @include xs {
            margin-bottom: 55px; } }
    &__error-msg {
        margin: 25px 0; } }
