.steps-amount {
    margin-top: 5px;
    margin-bottom: 30px;
    position: absolute;
    width: 100%;
    @include md {
        margin-top: 0; }
    @include xs {
        margin-top: 3px; }
    &__wrapper {
        display: flex;
        height: 11px;
        width: 100%;
        @include xs {
            height: 5px; } }
    &__title {
        color: #33669a;
        @include railSB;
        font-size: 11px;
        font-weight: 600;
        letter-spacing: 0.6px;
        margin-bottom: 3px;
        @include xs {
            font-size: 10px;
            margin-bottom: 1px; } }
    &__item {
        height: 100%;
        width: 95px;
        border: 1px solid #9b9b9b;
        @include md {
            flex: 1; }
        &.is-active {
            background-color: #33669a;
            border: 1px solid transparent;
            border-left: 1px solid #9b9b9b; } } }
