.img-slider {
    // text-align: center
    &__inner {
        padding-top: 57px;
        padding-bottom: 63px;
        @include md {
            padding-top: 43px;
            padding-bottom: 48px; }
        @include xs {
            padding-bottom: 35px; } }
    &__title {
        margin-bottom: 33px; }

    &__btn {
        text-align: center;
        margin-top: 25px;
        @include r(360) {
            margin-top: 15px; } } }

