.gallery-block {
    @include xs {
        margin-bottom: 20px;
        text-align: center;
        &__wrapper {
            margin-left: -7px;
            margin-right: -7px; }
        &__title {
            color: $dark-gray;
            font-size: 23px;
            letter-spacing: 2.46px;
            @include railB;
            text-align: left;
            margin-bottom: 10px; }
        .img-caption {
            width: calc(50% - 3px);
            margin: 10px 0;
            display: inline-block; } } }
