.header {
    @include clr;
    width: 100%;
    padding: 19px 0 20px;
    transition: all .25s ease;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 150;
    @include sm {
        padding: 0; }
    &_transparent {
        background-color: transparent;
        &:not(.is-fixed) {
            @include rmin(768) {
                .logo {
                    svg, path {
                        fill: #fff; }
                    .logo-subname {
                        stroke: #fff; }
                    &.beta {
                        &:before {
                            color: white; } } } } }
        .menu-dropdown {
            padding-top: 0; }
        .user {
            position: relative;
            &::after {
                content: '';
                transition: all .3s ease;
                height: 3px;
                transition: width .3s ease;
                position: absolute;
                top: 100%;
                left: 0;
                right: 0;
                margin: auto;
                background-color: $white;
                border-radius: 3px;
                width: 0; }
            &:hover {
                &::after {
                    width: 100%; } }
            .menu-dropdown {
                margin-top: 3px; } }
        .user__no-image {
            color: #fff;
            @include sm {
                color: $dark-gray; } }

        &.is-fixed {
            box-shadow: 0 0 4px #adadad;
            .nav__item > a {
                color: $dark-gray; }
            .nav__item.is-active a {
                border: 3px solid #984a9d;
                @include sm {
                    border: none; } }
            .user__no-image {
                color: $dark-gray; }
            .menu-dropdown {
                padding-top: 20px; }
            .user {
                &::after {
                    display: none; }
                .menu-dropdown {
                    margin-top: 0; } }
            @include rmin(768) {
                background-color: $white; } }
        @include sm {
            padding: 0; } }
    &_color {
        background-color: $white;
        border-bottom: 1px solid #0e76bc;
        @include sm {
            .header__nav {
                border-top: 1px solid #0e76bc; } } }
    &_authorized {
        @include sm {
            .header__logo {
                margin-left: auto;
                margin-right: auto; }
            .header__burger {
                right: auto;
                left: 6px; }
            .header__user {
                position: fixed;
                right: 16px;
                display: block;
                top: 18px;
                .user__image {
                    margin-right: 0; } } }
        @include xs {
            .header__user {
                top: 15px; } } }

    &__inner {
        max-width: 1440px;
        margin: 0 auto;
        padding: 0 45px;
        padding-right: 38px;
        @include clr;
        display: flex;
        align-items: center;
        @include sm {
            padding: 0;
            display: flex;
            flex-flow: column;
            position: relative;
            max-height: 100vh; } }
    &__main-wrap {
        @include sm {
            width: 100%;
            padding-left: 20px;
            padding-right: 15px;
            background-color: $white;
            padding: 20px 15px 12px 20px;
            margin-bottom: 1px;
            position: relative; } }


    &__logo {
        display: block;
        width: 187px;
        height: 55px;
        margin-top: 5px;
        @include md {
            width: 132px;
            height: 40px; }
        @include xs {
            width: 78px;
            height: 23px; } }
    &__nav {
        padding-top: 3px;
        margin-left: auto;
        @include sm {
            display: none;
            padding-top: 20px;
            float: none;
            align-self: center;
            width: 100%;
            flex: 1;
            overflow: auto;
            background-color: $white; }
        @include xs {
            padding-top: 16px; }
        @include rmin(768) {
            display: block!important; } }

    &__burger {
        display: none;
        @include sm {
            display: block;
            position: absolute;
            right: 6px;
            top: 50%;
            transform: translateY(-50%);
            z-index: 10; }
        @include xs {
            top: auto;
            bottom: 12px;
            transform: translateY(0); }
        &.has-messages {
            @include sm {
                &:before {
                    content: '';
                    position: absolute;
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                    background-color: #099;
                    top: -5px;
                    right: 1px;
                    z-index: 55; } } } }

    &__user {
        margin-left: 25px;
        @include sm {
            display: none; } } } // while no design on mob for this

