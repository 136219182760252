.slider {
    &-slide {
        text-decoration: none;
        color: $dark-gray; }
    &__social-btns {
        position: absolute;
        top: 33px;
        right: 33px;
        z-index: 25;
        @include xs {
            right: 18px; } }
    &_common {
        max-width: 1255px;
        margin: 0 auto;
        @include r(1200) {
            max-width: 860px; }
        @include md {
            max-width: percentage(646px/768px); }
        @include xs {
            max-width: 100%;
            padding: 0 15px; }
        .slider-slide {
            @include md {
                margin-bottom: 20px; }
            @include xs {
                margin-bottom: 15px;
                min-height: 125px; } }
        a.slider-slide {
            display: block; } }
    &_dots {
        .slick-dots {
            text-align: center;
            margin-left: auto;
            margin-right: auto;
            cursor: pointer;
            margin-top: 65px;
            @include md {
                margin-top: 20px; }
            @include xs {
                margin-top: 25px; }
            li {
                display: inline-block;
                width: 12px;
                height: 12px;
                margin: 0 4px;
                position: relative;
                background-color: #d9d9d9;
                border: 1px solid #979797;
                border-radius: 50%;
                &:before, &:after {
                    position: absolute;
                    content: ' ';
                    height: 12px;
                    width: 4px;
                    top: 0;
                    background-color: transparent; }
                &:before {
                    left: -4px; }
                &:after {
                    right: -4px; }

                @include xs {
                    &:before, &:after {
                        height: 6px; } } }
            button {
                font-size: 0; }
            .slick-active {
                background-color: #984a9d; } } }
    &_full {
        .slider__inner {
            width: 100%; }
        .slider-slide {
            width: 100%;
            height: 70vh;
            img {
                width: 100%;
                height: 100%;
                object-fit: cover; }
            &:not(.slick-initialized):not(:first-child) {
                display: none; } // before slider init
            @include md {
                height: 50vh; } } }

    &.is-hidden-before-init {
        .residence-card__image {
            &:not(:first-child) {
                display: none; } }
        &.slick-initialized {
            .residence-card__image {
                display: block; } } } }

.slider_common-arrows {
    .prev, .next {
        position: absolute;
        top: 50%;
        transform: translate3d(0,0,0) translateY(-50%);
        z-index: 15;
        background-color: transparent;
        width: 20px;
        height: 40px;
        font-size: 0;
        cursor: pointer;
        @include sm {
            width: 15px;
            height: 30px; }
        svg {
            display: block;
            width: 100%;
            height: 100%;
            fill: #fff;
            stroke: #009999;
            stroke-width: 20px; } }
    .prev {
        left: 15px; }
    .next {
        right: 15px;
        transform: translate3d(0,0,0) translateY(-50%) scale(-1, 1); } }


.slider-arrows {
    .prev, .next {
        position: absolute;
        top: 50%;
        transform: translate3d(0,0,0) translateY(-50%);
        z-index: 15;
        background-color: transparent;
        width: 75px;
        height: 90px;
        font-size: 0;
        cursor: pointer;
        display: flex;
        align-items: center;
        svg {
            display: block;
            width: 100%;
            max-width: 20px;
            max-height: 40px;
            height: 100%;
            fill: #fff;
            stroke: #009999;
            stroke-width: 20px;
            @include sm {
                max-width: 15px;
                max-height: 30px; } } }
    .prev {
        left: 0;
        padding-left: 15px; }
    .next {
        right: 0;
        padding-left: 15px;
        transform: translate3d(0,0,0) translateY(-50%) scale(-1, 1); }
    &_full {
        .prev {
            left: 0; }
        .next {
            right: 0; }
        .prev, .next {
            width: 210px;
            padding-left: 85px;
            padding-right: 85px;
            height: 100%;
            @include md {
                padding-left: 30px;
                padding-right: 30px;
                width: 100px; }
            svg {
                max-width: 33px;
                max-height: 45px; } } }
    &_popup {
        .prev, .next {
            width: 210px;
            padding-left: 85px;
            padding-right: 85px;
            height: 80%;
            svg {
                max-width: 45px;
                max-height: 70px; }
            @include md {
                padding-left: 30px;
                padding-right: 30px;
                width: 100px;
                svg {
                    max-width: 33px;
                    max-height: 45px; } } } } }





.slider-popup {
    &_main {
        margin-bottom: 35px;
        .slick-track {
            padding-top: 30px; }
        & + .btn {
            display: none;
            @include md {
                display: block; } } }
    &_thumbnails {
        max-width: 1220px;
        margin: 0 auto;
        @include md {
            display: none; }
        img {
            border: 2px solid transparent; }
        .slick-slide {
            margin: 0 5px; } } }

.slider-counter {
    text-align: center;
    color: #fff;
    font-size: 16px;
    display: none;
    line-height: 1.2;
    letter-spacing: 1.2px;
    position: absolute;
    width: 100%;
    @include osR;
    @include md {
        display: block; } }

.popup-slide {
    text-align: center;
    &__inner {
        max-width: 76vw;
        height: 614px;
        margin: 0 auto;
        position: relative;
        img {
            max-width: 100%;
            max-height: 100%;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            margin: auto; }
        @include md {
            max-width: 100vw;
            height: 70vh; } } }

.slide-thumb {
    text-align: center;
    cursor: pointer;
    &__inner {
        max-width: 268px;
        height: 168px;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover; }
        @include m {
            height: 130px; } } }
