.checkbox {
	$this: &;
	position: relative;
	@include clr;
	display: flex;
	align-items: center;
	position: relative;
	border: 1px solid transparent;
	padding: 10px;
	border-radius: 5px;
	&_mb-lg {
		margin-bottom: 15px;
		@include xs {
			margin-bottom: 0; } }
	&_input {
		flex-wrap: wrap;
		input[type="checkbox"] {
			transform: translate(0, 0);
			top: 11px;
			@include xs {
				top: 14px; } } }
	&__label {
		user-select: none;
		cursor: pointer;
		margin-bottom: 0;
		overflow: hidden;
		font-size: 14px;
		@include railM;
		letter-spacing: 2px;
		color: $dark-gray;
		position: relative;
		br {
			content: '';
			display: block;
			margin-top: 10px; }
		@include xs {
			font-size: 12px;
			padding-top: 2px; } }
	a {
		color: #984a9d;
		text-decoration: underline; }
	input {
		cursor: pointer;
		position: absolute;
		z-index: 100;
		left: 0;
		top: 2px;
		width: 20px;
		height: 20px;
		opacity: 0;
		top: 50%;
		left: 10px;
		transform: translateY(-50%);
		@include xs {
			width: 15px;
			height: 15px; } }

	&__input {
		width: 100%;
		margin-top: 10px;
		input {
			border: 1px solid #979797;
			@include bdrad;
			min-height: 30px;
			position: static;
			min-width: 200px;
			opacity: 1;
			margin-top: 10px;
			margin-left: 30px;
			padding: 0 10px;
			@include railM;
			font-size: 16px;
			color: $dark-gray;
			@include xs {
				margin-left: 25px; } } }
	&__ico {
		position: relative;
		display: block;
		margin-right: 24px; }


	&_common {
		input:checked {
			& + .checkbox__ico {
				&:before {
					width: 7px;
					transition-delay: 0ms;
					@include xs {
						width: 5px; } }
				&:after {
					width: 11px;
					transition-delay: 150ms;
					@include xs {
						width: 9px; } } } }
		.checkbox__ico {
			width: 20px;
			min-width: 20px;
			height: 20px;
			background-color: #fff;
			border-radius: 5px;
			border: 2px solid #33669a;
			@include xs {
				margin-right: 10px;
				min-width: 15px;
				width: 15px;
				height: 15px; }
			&:before {
				content: '';
				position: absolute;
				width: 0px;
				height: 2px;
				background: #33669a;
				bottom: 7px;
				left: 2px;
				transform: rotate(45deg);
				transform-origin: left;
				transition: all 150ms;
				transition-delay: 150ms;
				@include xs {
					bottom: 5px;
					left: 1px; } }
			&:after {
				content: '';
				position: absolute;
				width: 0px;
				height: 2px;
				background: #33669a;
				transform: translate(7px, 11px) rotate(-45deg);
				transform-origin: left;
				transition: all 150ms;
				transition-delay: 0ms;
				@include xs {
					transform: translate(4px, 8px) rotate(-45deg); } } } }
	&_primary {
		.checkbox__label {
			font-size: 16px; }
		.checkbox__ico {
			margin-right: 10px; }
		.checkbox__label {
			@include osR;
			letter-spacing: 1.08px; } }
	&_default {
		@include xs {
			padding: 7px 10px; }
		&.is-error {
			@include xs {
				padding: 7px 10px; } }

		.checkbox__label {
			font-size: 16px;
			@include osR;
			font-weight: 400;
			letter-spacing: 0.88px;
			color: $dark-gray;
			padding-left: 11px;
			@include sm {
				padding-left: 8px; }
			@include xs {
				font-size: 14px; } }
		input[type="checkbox"] {
			@include sm {
				width: 20px;
				min-width: 20px;
				height: 20px; } }
		.checkbox__ico {
			width: 16px;
			min-width: 16px;
			height: 16px;
			background-color: #fff;
			border-radius: 0;
			border: 1px solid #979797;
			margin-right: 0;
			@include sm {
				width: 20px;
				min-width: 20px;
				height: 20px; }
			&:before {
				content: '';
				position: absolute;
				width: 0px;
				height: 2px;
				background: #984a9d;
				bottom: 7px;
				left: 0;
				transform: rotate(45deg);
				transform-origin: left;
				transition: all 150ms;
				transition-delay: 150ms;
				@include sm {
					bottom: 9px;
					left: 3px; }
				@include xs {
					bottom: 8px;
					left: 4px; } }
			&:after {
				content: '';
				position: absolute;
				width: 0px;
				height: 2px;
				background: #984a9d;
				transform: translate(5px, 10px) rotate(-45deg);
				transform-origin: left;
				transition: all 150ms;
				transition-delay: 0ms;
				@include sm {
					transform: translate(8px,11px) rotate(-45deg); }
				@include xs {
					transform: translate(7px,12px) rotate(-45deg); } } }
		input:checked {
			& + .checkbox__ico {
				&:before {
					width: 7px;
					transition-delay: 0ms;
					@include xs {
						width: 5px; } }
				&:after {
					width: 19px;
					transition-delay: 150ms;
					@include xs {
						width: 10px; } } } } }
	&_no-pl {
		padding-left: 0;
		input {
			left: 0; } }
	&__error-message {
		color: red;
		font-size: 16px;
		margin-top: 13px;
		@include railR;
		letter-spacing: 1.15px; }
	&.is-error {
		// margin-bottom: 25px
		border: 1px solid red;
		padding: 10px;
		box-shadow: inset 0 0 1px red;
		#{$this}__ico {
			border-color: red; } } }

.checkboxes-block {
	&__row {
		display: flex;
		align-items: center;
		justify-content: space-between;
		@include md {
			flex-wrap: wrap; }
		.checkbox {
			width: 50%;
			@include sm {
				width: 100%; } } }
	&_main {
		max-width: 1180px;
		margin: 0 auto;
		padding-left: 5%;
		@include md {
			padding-left: 0; } } }




@keyframes checkbox-check {
	0% {
		width: 0;
		height: 0;
		border-color: #212121;
		transform: translate3d(0,0,0) rotate(45deg); }

	33% {
		width: .2em;
		height: 0;
		transform: translate3d(0,0,0) rotate(45deg); }

	100% {
		width: .2em;
		height: .5em;
		border-color: #212121;
		transform: translate3d(0,-.5em,0) rotate(45deg); } }
