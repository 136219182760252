.booking {
    &__container {
        padding: 0 165px;
        max-width: 1440px;
        margin: 0 auto;
        @include m {
            padding-left: 25px;
            padding-right: 25px; } }
    &__content {
        margin-bottom: 38px;
        padding-top: 25px;
        &_pt-xs {
            padding-top: 15px; }
        @include xs {
            margin-bottom: 0; } }
    &__title {
        &_main {
            margin-bottom: 10px; }
        &_mb-lg {
            margin-bottom: 55px; } }
    .title {
        &_primary {
            @include md {
                font-size: 38px; }
            @include xs {
                font-size: 20px;
                letter-spacing: 1px; } } }
    p {
        @include osR;
        font-size: 16px;
        letter-spacing: 1.08px;
        color: $dark-gray;
        @include md {
            font-size: 14px; }
        @include xs {
            font-size: 12px; } }
    .subtitle {
        @include md {
            font-size: 20px; }
        @include xs {
            font-size: 18px; } }
    .list {
        li {
            @include md {
                font-size: 14px;
                &:before {
                    top: 12px; } }
            @include xs {
                font-size: 12px; } } }
    .field {
        &_height-md {
            input {
                @include xs {
                    min-height: 30px; } } } }

    &__subtitle {
        &_xs {
            margin-bottom: 12px; }
        &_md {
            margin-bottom: 20px; }
        &_l {
            margin-bottom: 30px;
            @include md {
                margin-bottom: 20px; } } }
    &__divider {
        width: 100%;
        margin: 20px 0;
        background-color: #979797;
        height: 1px;
        border: none;
        &_mt-md {
            margin-top: 55px; }
        &_mt-lg {
            margin-top: 70px; }
        &_mb-md {
            margin-bottom: 50px; }
        &_mb-m {
            margin-bottom: 40px; }
        &_mb-xs {
            margin-bottom: 5px; }
        @include md {
            margin-top: 30px;
            margin-bottom: 30px; }
        @include xs {
            margin-top: 22px;
            margin-bottom: 22px; } }
    &__tag {
        color: $dark-gray;
        @include osL;
        font-weight: 300;
        letter-spacing: 1.08px;
        font-size: 16px;
        opacity: 0.7;
        @include md {
            font-size: 14px; }
        @include xs {
            font-size: 12px; }
        &_bolder {
            font-weight: 400; }
        &_mb {
            margin-bottom: 20px; } }
    &__timetable {
        margin-bottom: 30px; }
    &__text {
        color: $dark-gray;
        @include osR;
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 1.08px;
        &_mb-common {
            margin-bottom: 20px; } }
    &__row {
        display: flex;
        &_tab-reverse-col {
            @include md {
                flex-direction: column-reverse; } } }
    &__left-col {
        flex: 1; }
    &__right-col {
        margin-left: 35px;
        margin-top: 30px;
        @include md {
            margin: 0; } }
    &__selects-range {
        &:not(.is-active) {
            display: none; } }
    &__map {
        width: 100%;
        height: 435px;
        @include xs {
            height: 300px; }
        @include r(360) {
            height: 185px; } }
    &__information {
        .information {
            margin-top: 40px; } }
    &__payment {
        @include xs {
            margin-bottom: 40px; }
        .payments {
            padding-top: 20px; } }
    &__submit {
        @include xs {
            text-align: center;
            .btn {
                min-width: calc(100% - 30px);
                width: calc(100% - 30px); } } }
    &__user-description {
        .user-description {
            position: relative;
            &__info {
                padding-left: 95px;
                @include md {
                    padding-left: 0; } }
            &__image {
                position: absolute;
                top: 0;
                left: 0;
                @include md {
                    position: static; } } } }
    &__field {
        .field__note {
            @include xs {
                font-size: 12px; } } }
    &__payment-info {
        padding-top: 5px;
        padding-bottom: 25px; }

    &__return-link {
        margin-top: 34px;
        @include xs {
            margin-top: 24px; } }

    .residence-card {
        margin: 0;
        max-width: none;
        &:hover {
            box-shadow: none; }
        .slick-slide {
            margin: 0; } }
    .slick-dots {
        margin-top: 25px; }
    .read-more {
        @include md {
            font-size: 14px; }
        @include xs {
            font-size: 12px; } }

    &__select {
        width: 250px;
        margin-bottom: 35px;

        .custom-select__text,
        .custom-select__input {
            width: auto; } } }

.booking-similar-slider {
    margin: 0 -10px;
    .slick-slide {
        margin: 0 10px; }
    // .slick-list
 }    //     margin: 0 -10px


.booking-segment {
    border-bottom: 1px solid #979797;
    padding: 30px 0;
    &:first-child {
        padding-top: 0; }
    @include xs {
        padding: 15px 0; }
    &_p-md {
        padding: 44px 0;
        @include md {
            padding: 34px 0; }
        @include xs {
            padding: 24px 0; } }
    .subtitle_mb-md {
        @include md {
            margin-bottom: 30px; }
        @include xs {
            margin-bottom: 20px; } }
    &__row {
        display: flex;
        justify-content: space-between;
        align-items: center;
        @include xs {
            flex-direction: column-reverse; } }
    &__head {
        margin-bottom: 15px;
        @include md {
            margin-bottom: 30px; } }
    &__right {
        margin-left: 40px;
        margin-right: 20px;
        @include xs {
            margin: 0; } }
    &__left {
        .booking__subtitle {
            @include xs {
                display: none; } } }
    &__list {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        @include xs {
            flex-direction: column; }
        ul, ol {
            &:not(:last-child) {
                margin-right: 30px;
                @include xs {
                    margin-right: 0; } }
            @include xs {
                margin-bottom: 0;
                &:last-child {
                    margin-bottom: 5px; } } }
        li {
            @include osR;
            font-size: 16px;
            letter-spacing: 1.08px;
            color: $dark-gray;
            @include md {
                font-size: 14px; }

            @include xs {
                font-size: 12px;
                &:not(:last-child) {
                    margin-bottom: 0; } }
            &:before {
                @include md {
                    top: 12px; }
                @include sm {
                    top: 13px; } } } }
    p {
        &:not(:last-child) {
            margin-bottom: 20px; }
        @include xs {
            &:not(:last-child) {
                margin-bottom: 10px; } } }

    .review {
        p {
            margin-bottom: 0; } }
    &_bor-top {
        border-top: 1px solid #979797; }
    &_no-bor-bot {
        border-bottom: 0; }
    &_pt-lg {
        padding-top: 50px;
        @include md {
            padding-top: 30px; }
        @include xs {
            padding-top: 15px; } } }

.booking-hero {
    position: relative;
    .slick-slide {
        font-size: 0; }
    &__btn {
        position: absolute;
        bottom: 28px;
        left: 25px;
        z-index: 20;

        @include xs {
            left: 15px; } } }

.booking-request {
    width: 440px;
    border: 1px solid $dark-gray;
    border-radius: 7px;
    @include md {
        width: calc(100% + 50px);
        margin-left: -25px;
        border: none;
        border-radius: 0; }
    &_full {
        background: $blue;
        width: 100%;
        margin-left: 0;
        border: none;
        border-radius: 0;
        .cost-summ {
            padding: 12px 0 0;
            @include xs;
            padding: 5px 0 0;
            &__row {
                margin-bottom: 12px;
                @include xs {
                    margin-bottom: 5px; }
                p {
                    font-size: 23px;
                    @include md {
                        font-size: 16px; }
                    @include xs {
                        font-size: 13px; } } }
            &__title {
                font-size: 23px;
                margin-bottom: 12px;
                @include osSB;
                @include xs {
                    margin-bottom: 5px; }
                .subtitle {
                    font-weight: inherit;
                    @include md {
                        font-size: 18px; }
                    @include xs {
                        font-size: 13px; } } }
            &__total {
                &:before {
                    top: -5px;
                    width: 100%;
                    @include xs {
                        top: -2px; } }
                .subtitle {
                    @include xs {
                        font-size: 13px; } } }

            p, h3 {
                color: #fff; } }
        .booking-request {
            &__container {
                padding: 0 165px;
                max-width: 1440px;
                margin: 0 auto;
                @include l {
                    padding: 0 25px; } }
            &__head {
                border-radius: 0;
                display: flex;
                align-items: center;
                justify-content: space-between;
                padding: 35px 0 6px;
                @include md {
                    padding: 25px 0 12px; }
                @include xs {
                    padding: 14px 0 5px; } }
            &__col {
                &_top {
                    position: static;
                    top: auto;
                    transform: none; } }
            &__content {
                background: $blue;
                border-radius: 0;
                padding: 70px 0 48px;
                min-height: auto;
                border-top: 1px solid #979797;
                @include md {
                    padding: 70px 0 25px; }
                @include xs {
                    display: block;
                    padding: 50px 0 18px; } }
            &__summary {
                margin: 0; }
            &__desc {
                font-size: 23px;
                max-width: 160px;
                @include md {
                    font-size: 18px; }
                @include xs {
                    font-size: 13px;
                    max-width: 64px; } }
            &__tariff,
            &__subtariff {
                margin-bottom: 8px;
                @include md {
                    font-size: 20px; }
                @include xs {
                    font-size: 15px;
                    letter-spacing: 1px; } } } }

    .select {
        @include md {
            margin: 0 15px 20px;
            &__title {
                color: #fff; } } }
    .select,
    .datepicker-select_sm,
    .datepicker-select {
        @include md {
            width: 100%; } }
    .custom-select {
        &__field {
            background: #fff; }
        &__text {
            @include md {
                width: 100%; } } }

    .cost-summ {
        @include md {
            p, h3 {
                color: #fff; } }
        &__total {
            @include md {
                &:before {
                    width: 100%; } } } }

    &__head {
        color: $white;
        position: relative;
        background: $blue;
        padding: 150px 45px 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        border-radius: 7px;
        @include md {
            border-radius: 0;
            display: flex;
            align-items: center;
            justify-content: space-between;
            padding: 16px 0; } }
    &__container {
        @include md {
            padding: 0 25px 0;
            background: $blue; } }
    &__content {
        position: relative;
        background: $white;
        padding: 48px 14px 120px;
        border-radius: 7px;
        min-height: 415px;
        @include md {
            background: $blue;
            border-radius: 0;
            padding: 16px 0 120px;
            min-height: auto;
            @include md {
                border-top: 1px solid #979797; } } }
    &__discount {
        width: 80px;
        height: 80px;
        position: absolute;
        top: 10px;
        right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background-color: $white;
        font-size: 12px;
        @include osSB;
        color: $dark-gray;
        letter-spacing: 1px;
        border-radius: 50%;
        border: 4px solid #984a9d;
        @include md {
            position: absolute;
            right: 0;
            top: -100px; }
        @include xs {
            right: -10px; } }

    &__non-profit {
        width: 440px;
        text-align: center;
        font-size: 14px !important;
        margin: 15px 0 0 0;
        padding: 0 15px;

        @include md {
            width: 100%; } }

    &__col {
        &_top {
            position: absolute;
            top: 40px;
            left: 50%;
            transform: translate(-50%,0);
            width: 100%;
            @include md {
                position: static;
                top: auto;
                transform: none; } } }
    &__tariff,
    &__subtariff {
        font-size: 32px;
        text-align: center;
        @include osSB;
        letter-spacing: 2.46px;
        margin-bottom: 15px;
        line-height: 1;
        @include md {
            font-size: 18px;
            margin-bottom: 5px; }
        @include xs {
            font-size: 14px; } }

    &__subtariff {
        font-size: 18px;
        font-style: italic;
        letter-spacing: 0;
        @include md {
            font-size: 16px;
            margin-bottom: 5px; }
        @include xs {
            font-size: 14px; } }

    &__desc {
        //max-width: 130px
        text-align: center;
        font-size: 18px;
        letter-spacing: 2.45px;
        @include xs {
            font-size: 11px;
            max-width: 64px; }

        p {
            font-size: inherit;
            color: inherit; } }
    &__rating {
        .rating {
            text-align: center;
            @include osR; } }

    &__row {
        display: flex;
        justify-content: space-between;
        align-items: flex-end;
        @include md {
            margin: 0 -15px; }
        @include xs {
            margin: auto;
            flex-direction: column;
            .select {
                margin: 0 0 20px 0; } } }

    &__timing {
        cursor: pointer;
        font-size: 16px;
        letter-spacing: 0.6px;
        @include osR;
        display: flex; }
    &__summary {
        margin: 40px 0;
        display: none;
        @include md {
            margin: 20px 0; }
        &.is-shown {
            display: block; } }

    &__btn {
        position: absolute;
        bottom: 50px;
        left: 50%;
        transform: translateX(-50%);
        .btn {
            min-width: 260px;
            @include md {
                font-size: 18px;
                min-height: 45px; } } }
    &__time-link {
        margin-left: auto;
        margin-right: 0;
        @include md {
            color: #fff; }
        &_left {
            margin-left: 0;
            margin-right: auto; } }

    &__invalid-code {
        color: white;
        background-color: $purple;
        font-weight: 700;
        padding: 10px 15px;
        position: relative;
        top: -5px;
        text-align: center; } }

.working-time {
    display: flex;
    @include md {
        font-size: 14px; }
    @include xs {
        font-size: 12px; }
    li {
        margin: 10px 0; }
    &__days {
        margin-right: 50px; } }

.booking-notification {
    $this: &;
    position: fixed;
    top: auto;
    left: 0;
    width: 100%;
    padding: 50px 15px 40px;
    background: $blue;
    z-index: 151;
    transition: .3s;
    @include md {
        padding: 35px 15px 25px; }
    @include xs {
        padding: 20px 15px 25px; }

    p {
        color: white; }

    &__title {
        margin-bottom: 32px;
        max-height: 200px;
        overflow: hidden;
        transition: .3s;
        .title {
            color: #fff;
            @include md {
                letter-spacing: 2px; } } }
    &__actions {
        .btn {
            margin: 0 25px 10px;
            @include xs {
                margin: 0 10px 10px; }
            @include md {
                min-width: 130px;
                padding: 0 10px; }
            @include xs {
                font-size: 16px;
                padding: 0 5px;
                min-width: 110px; } } }

    &.sticky {
        padding: 20px 15px 10px;
        #{$this}__title {
            max-height: 1px;
            margin-bottom: 0;
            margin-top: -1px; } }

    + .booking {
        margin-top: 100px; } }
