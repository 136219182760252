.sharing {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    @include xs {
        flex-direction: column;
        align-items: flex-start; }
    &__icon {
        display: flex;
        align-items: center;
        justify-content: center;
        margin: 0 auto;
        margin-bottom: 7px;
        color: $white;
        text-decoration: none;
        width: 40px;
        height: 40px;
        background-color: #009999;
        border-radius: 50%;
        font-size: 24px;
        @include xs {
            margin-right: 15px;
            margin-bottom: 0; }
        .icon-letter2 {
            font-size: 14px; }
        .icon-link {
            font-size: 18px; } }
    &__link {
        text-decoration: none;
        text-align: center;
        @include xs {
            display: flex;
            align-items: center;
            margin-bottom: 30px; } }
    &__name {
        @include osR;
        font-weight: 400;
        font-size: 12px;
        text-decoration: none;
        color: $dark-gray;
        letter-spacing: 0.81px; } }
