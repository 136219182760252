.error-page {
    .content-wrapper {
        padding-bottom: 100px;
        @include xs {
            padding-bottom: 70px; } }
    .btn-back {
        margin: 25px 0 75px;
        @include xs {
            margin: 5px 0 25px; } }
    .title {
        max-width: 1105px;
        margin-left: auto;
        margin-right: auto;
        @include md {
            letter-spacing: 2px;
            font-size: 36px; }
        @include xs {
            font-size: 18px;
            margin-bottom: 20px; } }
    .subtitle_mb-md {
        display: inline-block;
        margin-bottom: 0;
        @include xs {
            margin-bottom: 16px;
            font-size: 14px;
            &:last-child {
                margin-bottom: 0; } } }
    &__img {
        margin-bottom: 34px;
        text-align: center;
        img {
            width: auto;
            height: auto;
            @include md {
                width: 320px; }
            @include xs {
                width: 165px; } } }
    &__text {
        max-width: 795px;
        margin: 0 auto;
        text-align: center; } }
