.about-page {
    padding-bottom: 43px;
    @include md {
        padding-left: 45px;
        padding-right: 35px;
        padding-bottom: 0; }
    @include xs {
        padding-left: 5px;
        padding-right: 5px; }
    h1 {
        @include railR;
        font-weight: 400;
        color: $dark-gray;
        font-size: 45px;
        letter-spacing: 2.46px;
        margin-bottom: 60px;
        @include md {
            font-size: 36px;
            letter-spacing: 1.97px; }
        @include xs {
            font-size: 32px;
            letter-spacing: 1.75px;
            margin-bottom: 40px; } }
    p {
        @include osR;
        font-weight: 400;
        color: $dark-gray;
        font-size: 23px;
        letter-spacing: 1.55px;
        margin-bottom: 35px;
        @include md {
            margin-bottom: 20px;
            font-size: 14px; }
        @include xs {
            letter-spacing: 0.94px; } }
    strong {
        letter-spacing: 2.23px;
        font-weight: 700; }
    &__icons {
        padding-top: 38px;
        padding-bottom: 17px;
        @include md {
            padding-top: 10px;
            padding-bottom: 40px; } }
    .container {
        @include r(992) {
            max-width: none; } } }
