.custom-select {
    position: relative;
    $this: &;
    select {
 }        // display: none

    &__text, &__input {
        height: 40px;
        position: relative;
        font-size: 18px;
        color: $dark-gray;
        @include osR;
        letter-spacing: 0.88px;
        line-height: 39px;
        vertical-align: middle;
        padding: 0 15px;
        padding-left: 25px;
        padding-right: 40px;
        width: 183px;
        white-space: nowrap;
        overflow: hidden;
        @include md {
            width: 130px;
            font-size: 16px;
            letter-spacing: 1.78px;
            padding-left: 15px;
            padding-right: 30px; }
        @include xs {
            font-size: 14px; }
        input {
            font-size: 18px;
            @include osR;
            color: $dark-gray;
            width: 100%; }
        input::placeholder {
            color: $dark-gray;
            @include xs {
                color: #9b9b9b; } } }

    &__field {
        position: relative;
        display: block;
        width: 100%;
        height: 40px;
        border: 1px solid #979797;
        cursor: pointer;
        @include bdrad; }
    &__text {
        position: relative;
        text-overflow: ellipsis;
        &:before {
            content: '';
            position: absolute;
            top: 33%;
            right: 12px;
            height: 10px;
            width: 10px;
            border-left: 3px solid $dark-gray;
            border-bottom: 3px solid $dark-gray;
            transform: rotate(-45deg) scale(1,1) translate(0, 0);
            transform-origin: center;
            transition: .3s ease;
            z-index: 15; } }
    &__icon {
        position: absolute; }
    &__dropdown {
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        visibility: hidden;
        opacity: 0;
        transition: .25s ease;
        background-color: #ffffff;
        border: 1px solid #979797;
        @include bdrad;
        z-index: 80;
        overflow: hidden;
        overflow-y: auto;
        max-height: 260px;
        &::-webkit-scrollbar {
            width: 10px; }
        &::-webkit-scrollbar-track {
            -webkit-box-shadow: inset 0 0 6px rgba(0,0,0,0.5);
            border-radius: 10px; }
        &::-webkit-scrollbar-thumb {
            border-radius: 10px;
            background-color: #9c9c9c; } }
    &__list {
        // padding: 5px
        text-align: center;
        li {
            font-size: 16px;
            @include osR;
            color: $dark-gray;
            letter-spacing: -0.12px;
            cursor: pointer;
            padding: 7px 5px;
            @include xs {
                font-size: 14px;
                &:not(:last-of-type) {
                    border-bottom: 1px solid #009999; } }
            &:hover, &:focus, &:active, &.is-active {
                background-color: #99cccc; } } }

    &_input {
        .custom-select__input {
            padding-right: 25px; } }

    &.is-open {
        #{$this}__dropdown {
            visibility: visible;
            opacity: 1; }
        #{$this}__text {
            &:before {
                transform: rotate(45deg) scale(1,-1) translate(3px, -3px); } } } }


.select {
    &.is-error {
        .custom-select {
            &__field {
                background-color: #fff8f9;
                border: 2px solid $red;
                box-shadow: none;
                overflow: hidden; } } }
    &__title {
        @include osSB;
        font-size: 12px;
        letter-spacing: 1.08px;
        color: $dark-gray;
        margin-bottom: 5px;
        @include xs {
            font-size: 11px;
            margin-bottom: 2px;
            letter-spacing: 0.99px; } }

    &_top {
        .custom-select__dropdown {
            bottom: 100%;
            top: auto; } }
    &_secondary {
        .custom-select__field {
            @include xs {
                height: 30px;
                line-height: 29px; } }
        .custom-select__text {
            padding-left: 25px;
            font-size: 16px;
            letter-spacing: 0.88px;
            @include xs {
                font-size: 14px;
                letter-spacing: 0.77px;
                color: #4a4a4a;
                padding-left: 10px;
                height: 30px;
                line-height: 29px; }
            &:before {
                top: 21%;
                height: 15px;
                width: 15px;
                border-left: 2px solid $dark-gray;
                border-bottom: 2px solid $dark-gray;
                @include xs {
                    height: 12px;
                    width: 12px; } } }
        .custom-select__dropdown {
            padding-top: 4px;
            @include xs {
                padding-top: 0; } }
        .custom-select__list {
            text-align: left;
            li {
                padding: 5px 20px;
                letter-spacing: 0.9px;
                @include xs {
                    padding-left: 10px;
                    letter-spacing: 0.77px;
                    color: #4a4a4a;
                    font-size: 14px; } } }

        .custom-select.is-open {
            .custom-select__text {
                &:before {
                    transform: rotate(45deg) scale(1,-1) translate(5px, -5px); } } } }

    &_xs {
        .custom-select__text {
            padding-left: 8px;
            font-size: 16px;
            width: 128px;
            &:before {
                right: 8px; } }
        .select__title {
            margin-bottom: 12px;
            font-size: 16px;
            @include osR; } }
    &_xxs {
        .custom-select__text {
            width: 85px;
            padding: 0 24px 0 8px;
            @include xs {
                width: 78px; }
 } }            //font-size: 14px
    &_width-md {
        .custom-select__text {
            width: 173px; } }

    &_full {
        .custom-select__text {
            width: 100%; }
        .custom-select__input {
            width: 100%; } }
    &_mb {
        margin-bottom: 25px; }
    &_center-holder {
        .custom-select__text {
            text-align: center; } }
    &_arrow-bg {
        .custom-select__text {
            &:before {
                top: 26%;
                right: 12px;
                height: 14px;
                width: 14px;
                border-left: 4px solid #4a4a4a;
                border-bottom: 4px solid #4a4a4a;
                @include xs {
                    height: 10px;
                    width: 10px;
                    border-left: 3px solid #4a4a4a;
                    border-bottom: 3px solid #4a4a4a; } } } } }

.select-column {
    &:not(:last-child) {
        margin-bottom: 25px; }
    .select {
        &:not(:last-child) {
            margin-bottom: 15px; } } }

.select-tabs {
    &__title {
        @include osR;
        color: $dark-gray;
        letter-spacing: 1.71px;
        @include xs {
            font-size: 16px;
            margin-bottom: 20px; } }
    .custom-select {
        $this: &;
        display: flex;
        // justify-content: center
        select {
 }            // display: none
        &__list {
            display: flex;
            align-items: center;
            align-items: flex-start;
            border: 1px solid #979797;
            @include bdrad;
            background-color: $white;
            overflow: hidden;
            width: 100%;
            li {
                background-color: #fff;
                font-size: 12px;
                color: #9b9b9b;
                @include osR;
                letter-spacing: 0.81px;
                text-align: center;
                padding: 10px;
                cursor: pointer;
                flex: 1;
                &:not(:last-of-type) {
                    border-right: 1px solid #979797;
                    border-bottom: none; }
                &.is-active {
                    background-color: #99cccc;
                    color: $dark-gray; } } } }

    // &_full-w
    //     .custom-select-tabs__list
 }    //         width: 100%
