.multi-action {
  &__wrapper {
    display: flex;
    align-items: center;

    .btn {
      border-radius: 7px 0 0 7px;
      border-right: none; } }

  &__dropdown {
    position: relative;
    width: 32px;
    height: 30px;
    background-color: $aqua;
    border-radius: 0 7px 7px 0;
    border-left: 1px solid rgba(white, .3);
    cursor: pointer;

    @include xs {
      margin-bottom: 15px;
      height: 36px; }

    &:hover,
    &:focus,
    &:active {
      ul {
        display: block; } }

    &:before {
      content: '';
      position: absolute;
      top: 33%;
      right: 12px;
      height: 8px;
      width: 8px;
      border-left: 2px solid white;
      border-bottom: 2px solid white;
      transform: rotate(-45deg) scale(1,1) translate(0, 0);
      transform-origin: center;
      transition: .3s ease;
      z-index: 15; }

    ul {
      display: none;
      min-width: 140px;
      position: absolute;
      right: 0;
      top: 100%;
      border: 1px solid #979797;
      border-radius: 7px;
      background-color: white;
      overflow: hidden;
      z-index: 2;

      li + li {
        border-top: 1px solid $aqua; }

      li {
        a {
          display: block;
          color: $dark-gray;
          text-align: center;
          font-size: 16px;
          letter-spacing: -0.12px;
          padding: 7px 15px;
          transition: .3s; }

        &:hover,
        &:focus,
        &:active {
          a {
            background-color: $light-aqua; } } } } } }
