.venue-registration {
    .textual {
        font-size: 16px;
        color: $dark-gray;
        letter-spacing: 0.6px;
        @include osR;
        @include sm {
            font-size: 12px; } }
    &__container {
        display: flex;
        justify-content: space-between;
        position: relative;
 }        // margin-bottom: 50px
    &__col {
        position: relative;
        &_r {
            width: 55%;
            padding-right: 38px;
            position: relative;
            @include m {
                padding-right: 0; }
            @include md {
                width: 44%; }
            @include sm {
                width: 0; }
            &:before {
                content: '';
                position: absolute;
                top: -140px;
                right: -160px;
                height: 800px;
                width: 800px;
                border-radius: 50%;
                background-color: #A2CCCD;
                z-index: -1;
                @include m {
                    width: 650px;
                    height: 650px; }
                @include md {
                    display: none; } } }
        &_r-xl {
            width: 59%;
            @include md {
                width: 44%; }
            @include sm {
                width: 0; } }
        &_l {
            width: 500px;
            margin-left: 45px;
            @include m {
                width: 400; }
            @include md {
                width: 53%;
                margin-left: 0;
                padding-right: 1%; }
            @include sm {
                width: 100%;
                padding-right: 0; } }
        &_l-md {
            width: 400px;
            @include md {
                width: 53%;
                padding-right: 1%; }
            @include sm {
                width: 100%;
                padding-right: 0; } } }

    &__wrapper {
        position: relative;
        min-height: 100vh;
        max-width: 1440px;
        margin: 0 auto;
        padding: 100px 0;
        @include md {
            padding: 100px 60px 121px; } //footer-height=121px
        @include sm {
            padding: 78px 60px 175px; } //footer-height=121px + tooltip btn
        @include xs {
            padding: 60px 20px 130px; } } //footer-height=95px  + tooltip btn
    &__actions {
        padding: 35px 38px 46px 45px;
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        @include md {
            padding: 35px 60px 46px; }
        @include xs {
            padding: 35px 20px 20px; }
        &:before {
            content: '';
            position: absolute;
            bottom: -270px;
            right: -140px;
            border-radius: 50%;
            background-color: #3C6699;
            width: 525px;
            height: 426px;
            @include r(1440) {
                right: -100px; }
            @include md {
                display: none; }
            @include rmin(1440) {
                background-size: cover; } } }

    &__advice-mob {
        display: none;
        position: absolute;
        right: 60px;
        bottom: 100px;
        @include sm {
            display: block; }
        @include xs {
            bottom: 75px;
            right: 20px; } } }

.venue-registration {
    overflow: hidden;
    position: relative; }

.registration-actions {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .btn_transparent {
        padding: 6px 23px 7px;
        border: 1px solid $blue;
        letter-spacing: 1.08px;
        transition: all .25s ease;
        @include md {
            padding: 5px 20px 6px; }
        @include xs {
            padding: 6px 14px 6px; }
        &:hover, &:active, &:focus {
            color: $white;
            background-color: $aqua;
            border: 1px solid $white; } }
    &_spec {
        justify-content: flex-end;
        @include sm {
            justify-content: center; }
        .btn_transparent {
            @include md {
                padding: 6px 9px 6px; } } }
    &.has-order {
        @include md {
            .btn:first-child {
                order: 1; }
            .btn:last-child {
                order: 2;
                padding: 6px 10px; }
            .btn:nth-child(2) {
                order: 3; } } }
    .btn {
        font-size: 16px;
        @include sm {
            font-size: 14px; }
        &_blue {
            @include md {
                padding: 4px 20px 6px; }
            @include xs {
                padding: 6px 14px 6px; } }
        &_height-xs {
            @include md {
                height: 40px; } }
        &:last-child {
            margin-right: 71px;
            @include r(1440) {
                margin-right: 0; } } } }
