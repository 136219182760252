.subscription-form {
  &__wrapper {
    margin: 0 auto 24px; }

  &__inner {
    display: flex;
    align-items: center;
    justify-content: center;

    @include md {
      flex-direction: column; } }

  &__conf-msg {
    color: $aqua;
    font-size: 14px;
    @include osSB;
    letter-spacing: 1.02px;
    text-align: center; }

  &__text {
    margin-right: 15px;
    margin-bottom: 0;
    @include md {
      margin-bottom: 15px; } }

  .form {
    .field {
      display: flex;

      &__input {
        margin-right: 15px;
        width: 290px;

        @include sm {
          width: auto; }

        input {
          font-size: 16px;
          height: 40px; } } }

    .error-text {
      text-align: center;
      margin: 10px 0; } } }
