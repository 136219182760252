.reasons {
    padding: 65px 30px;
    @include xs {
        padding: 40px 30px 30px;
        padding-left: 0;
        padding-right: 0; }
    &__inner {
        max-width: 985px;
        margin: 0 auto;
        background-color: #efeeee;
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.5);
        padding: 41px 79px 33px;
        padding-left: percentage(79px/1365px);
        padding-right: percentage(79px/1365px);
        @include md {
            padding-left: percentage(69px/1365px);
            padding-right: percentage(69px/1365px); }
        @include xs {
            background-color: $white;
            box-shadow: none;
            padding: 0; } }

    &__title {
        margin-bottom: 23px;
        @include md {
            margin-bottom: 20px; } }
    &__description {
        @include fz-common;
        @include osR;
        color: $dark-gray;
        letter-spacing: 0.61px;
        text-align: center;
        max-width: 89%;
        margin: 0 auto;
        margin-bottom: 35px;
        @include md {
            font-size: 14px; }
        @include xs {
            display: none; }
        a {
            @include osSB;
            color: #984a9d;
            text-decoration: none; } }
    &__items {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-between;
        @include xs {
            display: block; }
        .advantage {
            width: calc(50% - 40px);
            @include md {
                width: calc(50% - 25px); }
            @include xs {
                padding-left: 15px;
                padding-right: 15px; }
            &__title {
                margin-top: 20px;
                margin-bottom: 10px;
                @include md {
                    margin-top: 10px; }
                @include xs {
                    margin-top: 0; } } } } }

