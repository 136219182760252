.field {
  &_secondary {
    .input {
      height: 40px;
      @include bdrad;
      border: solid 1px #979797;
      padding: 10px;
      font-size: 16px !important;

      @include xs {
        font-size: 14px;
        height: 30px;
        padding: 5px 7px; }

      &.is-error {
        background-color: #fff8f9;
        border: 2px solid #d0021b;
        box-shadow: none; } }

    input[type="password"] {
      padding: 0 10px; } } }

.field_secondary {
  input[type=number] {
    padding: 0 0 0 10px; } }

.venue-registration {
  .field__error-message {
    @include osSB;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: 1.08px;
    color: $red;
    margin-top: 12px; } }
