.recaptcha {
  display: flex;
  justify-content: center;
  margin: 15px 0;
  overflow: hidden;

  @include xs {
    justify-content: flex-start; }

  &__absolute {
    position: absolute; } }
