.contact-us-page {
    .content-wrapper {
        padding-bottom: 220px;
        @include md {
            padding-bottom: 0;
            min-height: 540px; }
        @include xs {
            min-height: 270px; } }
    .title {
        max-width: 1130px;
        margin-left: auto;
        margin-right: auto;
        @include xs {
            font-size: 18px; } }
    .link {
        @include osR;
        font-size: 23px;
        letter-spacing: 2.46px;
        @include md {
            font-size: 18px; }
        @include xs {
            font-size: 14px; } }
    &__cols {
        margin: 53px auto 0;
        display: flex;
        justify-content: center;
        @include xs {
            flex-direction: column; } }
    &__col {
        margin: 0 75px;
        text-align: center;
        @include md {
            margin: 0 35px; }
        @include xs {
            margin-bottom: 42px; } }
    &__icon {
        margin: 0 auto;
        width: 130px;
        height: 130px;
        border-radius: 50%;
        background: $aqua;
        margin-bottom: 50px;
        position: relative;
        @include md {
            width: 100px;
            height: 100px; }
        @include xs {
            margin-bottom: 13px;
            width: 60px;
            height: 60px; }
        span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%); }
        .icon-letter {
            font-size: 45px;
            @include md {
                font-size: 35px; }
            @include xs {
                font-size: 20px; } }
        .icon-phone {
            font-size: 60px;
            @include md {
                font-size: 45px; }
            @include xs {
                font-size: 25px; } } } }
