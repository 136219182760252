.steps-info {
  padding-top: 120px;
  &_pt-sm {
    padding-top: 60px;
    @include md {
      padding-top: 85px; } }
  @include sm {
    padding-top: 34px; }
  &__head {
    margin-bottom: 30px;
    max-width: 400px; }
  &__title {
    margin-bottom: 22px;
    letter-spacing: 0.9px; } }


.step-info {
  padding-bottom: 20px;
  margin-bottom: 15px;
  border-bottom: 2px solid rgba(119, 119, 119, 0.6);
  &_woborder {
    border: none; }
  &:last-child {
    border-bottom: none; }
  .btn {
    font-size: 16px;
    @include sm {
      font-size: 14px; } }
  &__head {
    margin-bottom: 11px;
    width: calc(100% + 30px);
    @include sm {
      margin-bottom: 6px; } }
  &__title {
    display: inline-block;
    vertical-align: middle;
    .title_secondary {
      letter-spacing: 0.9px; } }

  .title {
    text-transform: uppercase;
    letter-spacing: 0.9px; }

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center; }
  &__list {
    display: flex;
    justify-content: flex-start;
    max-width: calc(100% - 90px);
    @include md {
      flex-direction: column; }
    ul {
      margin-right: 20px; }
    li {
      padding-left: 12px;
      position: relative;
      &:before {
        content: '-';
        position: absolute;
        left: 0;
        top: 0; } } }
  &__link {
    display: inline-block;
    vertical-align: middle;
    font-size: 16px;
    margin-left: 2px;
    @include osR;
    @include sm {
      font-size: 14px; } }
  &__status {
    width: 90px;
    display: flex;
    justify-content: center;
    @include sm {
      justify-content: flex-end; } } }
