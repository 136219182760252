.steps {
	border-bottom: 2px solid #4a4a4a;
	margin-bottom: 236px;
	position: relative;
	@include sm {
		border: none;
		margin-bottom: 74px; }
	&__content-wrapper {
		display: block;
		padding-bottom: 0;
		padding-top: 19px;
		min-height: unset;
		font-size: 0;
		@include md {
			padding-top: 83px; }
		@include sm {
			padding: 70px 0 0; } }
	.slick-dots {
		position: absolute;
		bottom: 45px;
		left: 50%;
		transform: translate(-50%, 0);
		display: flex;
		li {
			&:not(:last-child) {
				margin-right: 5px; }
			button {
				font-size: 0;
				width: 6px;
				height: 6px;
				border: 1px solid #979797;
				background-color: #d9d9d9;
				border-radius: 50%; }
			&.slick-active {
				button {
					background-color: #984a9d;
					border-color: #984a9d; } } } } }

.step {
	background-color: $light-aqua;
	padding: 28px 24px 10px;
	max-width: 22.4%;
	text-align: center;
	display: inline-block;
	vertical-align: bottom;
	&:not(:last-child) {
		margin-right: 3.45%; }
	@include r(1380) {
		padding-left: 9px;
		padding-right: 9px; }
	@include md {
		max-width: 23.85%;
		&:not(:last-child) {
			margin-right: 1.53%; } }

	&_xs {
		min-height: 325px; }
	&_sm {
		min-height: 400px; }
	&_md {
		min-height: 439px; }
	&_lg {
		min-height: 466px; }
	&__icon {
		font-size: 50px;
		margin-bottom: 13px;
		@include sm {
			margin-bottom: 27px; }
		span {
			&:before {
				color: #099; } } }
	&__title {
		color: #ffffff;
		@include railB;
		font-size: 32px;
		letter-spacing: 3.42px;
		margin-bottom: 16px;
		@include md {
			font-size: 30px; }
		@include sm {
			margin-bottom: 18px; } }
	&__description {
		color: #4a4a4a;
		font-size: 18px;
		letter-spacing: 1.21px;
		line-height: 1.4;
		@include md {
			font-size: 16px; }
		@include osR;
		&-bold {
			display: block;
			margin-top: 22px;
			@include osB; } }
	&_lg {
		.step__icon {
			font-size: 60px;
			@include sm {
				font-size: 50px; } } }
	&__top {
		@include sm {
			display: flex;
			flex-direction: column-reverse; } }
	@include sm {
		width: 100%;
		max-width: unset;
		padding: 39px 50px 50px;
		min-height: 400px; } }
