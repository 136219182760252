.header_transparent {
    &.is-fixed {
        .nav__item.is-active a {
            &:hover, &:focus, &:active {
                background-color: $aqua; } } } }

.header_color {
    .nav__item.is-active a {
        border: 3px solid $aqua;
        &:hover, &:focus, &:active {
            background-color: $aqua; } } }
