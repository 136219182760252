.form {
	//width: 795px
	//max-width: 100%
	//border-radius: 7px
	//background-color: $light-aqua
	//padding: 37px 57px 39px 46px
	&_secondary {
		width: 795px;
		max-width: 100%;
		border-radius: 7px;
		background-color: $light-aqua;
		padding: 37px 57px 39px 46px;
		@include md {
			padding: 31px 26px 29px 26px; }
		@include xs {
			padding: 0;
			background-color: transparent; } }
	&_mx-auto {
		margin: 0 auto; }
	&__inner {
		display: flex;
		justify-content: space-between;
		align-items: flex-start;
		@include xs {
			flex-direction: column;
			justify-content: center;
			align-items: center; } }
	&__error {
		margin-bottom: 30px;
		@include xs {
			margin-bottom: 24px; } }
	&__field {
		margin-right: 14px;
		@include xs {
			margin-right: 0; }
		input {
			// min-height: 40px
			text-align: center;
			padding: 0 10px;
			border: 1px solid #979797; }
		&.is-error {
			input {
				border: 2px solid $red; } } }
	&__content {
		width: 100%;
		@include xs {
			margin-bottom: 20px; } }
	&__content-bg {
		@include xs {
			background-color: $light-aqua;
			padding: 20px 22px;
			border-radius: 7px;
			width: 100%; } }
	&__note {
		color: $dark-gray;
		font-size: 14px;
		@include railM;
		letter-spacing: 2px;
		margin-top: 30px;
		@include xs {
			font-size: 10px;
			margin-top: 20px;
			letter-spacing: 1.43px; }
		a {
			text-decoration: underline;
			color: #984a9d;
			&:hover {
				text-decoration: none; } } }
	&__btn {
		margin-top: 50px;
		@include xs {
			margin-top: 25px; } }
	&__update-btn {
		text-align: center;
		margin-top: 54px;
		@include xs {
			margin-top: 45px; }
		.btn {
			@include xs {
				font-size: 14px;
				min-height: 30px;
				min-width: 111px; } } }
	&__columns {
		display: flex;
		align-items: flex-start;
		@include xs {
			display: block; } }
	&__col {
		width: 50%;
		padding: 10px 0;
		@include md {
			padding: 0; }
		@include xs {
			width: 100%;
			padding: 20px 0; }
		&:not(:first-child) {
			padding-left: 25px;
			@include md {
				padding-left: 30px; }
			@include xs {
				padding-left: 0; } }
		&:not(:last-child) {
			padding-right: 25px;
			border-right: 1px solid #979797;
			@include md {
				padding-right: 30px; }
			@include xs {
				padding-right: 0;

				border-right: none;
				border-bottom: 1px solid #979797; } } } }
