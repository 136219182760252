.radio {
    $this: &;
    input[type="radio"] {
        position: absolute;
        width: 100%;
        height: 100%;
        left: 0;
        top: 0;
        opacity: 0;
        position: absolute;
        z-index: 15;
        cursor: pointer; }

    &_main {
        display: flex;
        align-items: stretch;
        border: 1px solid #979797;
        @include bdrad;
        flex: 1;
        @include xs {
            flex-direction: column; }
        #{$this}__left {
            width: 100px;
            flex: 0 0 100px;
            padding: 20px 18px;
            display: flex;
            // flex-wrap: wrap
            flex-direction: column;
            align-items: center;
            justify-content: center;
            position: relative;
            hyphens: auto;
            @include xs {
                width: 100%;
                flex: 1;
                flex-direction: row;
                align-items: center;
                padding: 10px; } }
        #{$this}__title {
            font-size: 16px;
            @include osB;
            font-weight: 700;
            text-transform: uppercase;
            color: $dark-gray;
            letter-spacing: 0.61px;
            margin-bottom: 15px;
            text-align: center;
            @include xs {
                margin-bottom: 0;
                margin-right: 10px;
                font-size: 14px;
                letter-spacing: 0.53px; }
            &_sm {
                font-size: 15px;
                letter-spacing: 0.57px;
                @include xs {
                    font-size: 14px;
                    letter-spacing: 0.53px; } } }
        #{$this}__circle {
            width: 28px;
            height: 28px;
            border: 2px solid #777777;
            border-radius: 50%;
            position: relative;
            @include xs {
                width: 22px;
                height: 22px; } }
        label {
            padding: 19px 15px;
            border-left: 1px solid #777777;
            display: flex;
            flex-direction: column;
            justify-content: center;
            cursor: pointer;
            @include osR;
            font-weight: 400;
            font-size: 16px;
            letter-spacing: 1.08px;
            color: $dark-gray;
            @include xs {
                border-left: none;
                border-top: 1px solid #777777;
                padding: 15px 10px;
                font-size: 12px;
                letter-spacing: 0.81px; } }
        input:checked {
            & ~ .radio__circle {
                &:before {
                    content: '';
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    width: calc(100% - 9px);
                    height: calc(100% - 9px);
                    border-radius: 50%;
                    background-color: #33669a;
                    @include xs {
                        width: calc(100% - 4px);
                        height: calc(100% - 4px); } } } }
        &.is-error {
            border: 2px solid $red;
            background-color: #fff8f9; } }
    &_default {
        position: relative;
        .radio__circle {
            width: 20px;
            height: 20px;
            border: solid 1px #009999;
            border-radius: 50%;
            display: inline-block;
            vertical-align: middle;
            position: relative; }
        label {
            display: inline-block;
            vertical-align: middle;
            padding-left: 15px;
            color: $dark-gray;
            font-size: 16px;
            letter-spacing: 1.1px;
            @include osR;
            @include xs {
                font-size: 14px;
                letter-spacing: 0.94px; } }
        input:checked {
            & ~ .radio__circle {
                &:before {
                    content: '';
                    position: absolute;
                    left: 50%;
                    top: 50%;
                    transform: translate(-50%, -50%);
                    width: 12px;
                    height: 12px;
                    border-radius: 50%;
                    background-color: #009999; } } } }
    &_md {
        .radio__circle {
            width: 28px;
            height: 28px; }
        input:checked {
            & ~ .radio__circle {
                &:before {
                    width: 18px;
                    height: 18px; } } } } }


