.consideration {
    border: 3px solid #cccccb;
    max-width: 1105px;
    margin: 0 auto;
    padding: 45px 30px 49px 45px;
    position: relative;
    @include clr;
    @include sm {
        padding: 40px;
        padding-right: 0; }
    &__image {
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
        max-width: 300px;
        // margin-left: percentage(50px/1105px)
        @include clr;
        width: 30%;
        @include sm {
            position: static;
            transform: translateY(0);
            float: left;
            margin-left: 0; }
        img {
            width: 100%;
            height: auto; } }
    &__title {
        @include fz-lg-m;
        @include railB;
        letter-spacing: 2.46px;
        color: $dark-gray;
        margin-bottom: 46px;
        margin-top: 20px;
        float: right;
        width: calc(77% - 105px);
        padding-right: 15px;
        @include sm {
            width: 100%;
            float: none;
            text-align: center;
            font-size: 28px;
            margin-top: 0; } }
    &__text {
        font-size: 18px;
        float: right;
        width: calc(77% - 100px);
        @include osR;
        letter-spacing: 1.21px;
        color: $dark-gray;
        @include sm {
            font-size: 14px;
            width: calc(80% - 100px);
            margin-left: 0;
            padding-right: 45px; } } }


