.advice {
    // background-image: url(../img/advice-bg.png)
    // background-repeat: no-repeat

    // background-size: 100% 100%
    // min-height: 580px
    // background-position: 5px -320px
    // background-size: 900px
    // min-height: 580px
    padding-top: 95px;
    // +l
    //     background-position: 5px -185px
    //     background-size: 760px
    //     min-height: 580px
    @include m {
        justify-content: flex-end;
        padding: 50px 20px 0 0;
        display: flex; }
    @include md {
        background-image: none;
        margin-top: 95px;
        width: 100%;
        padding: 62px 26px 100px;
        border-radius: 140px;
        background: $light-aqua;
        min-height: auto; }
    @include rmin(2000) {
        background-position: 0px -320px;
        background-size: cover;
        min-height: 720px; }
    &_pt-sm {
        padding-top: 44px; }
    &_size-lg {
        min-height: 695px;
        background-size: 1000px;
        @include l {
            background-position: 5px -305px; }
        @include rmin(2000) {
            background-position: 0px -320px;
            background-size: cover;
            min-height: 720px; } }

    &_mt-md {
        @include md {
            margin-top: 155px; } }
    @include sm {
        display: none; }

    &__container {
        margin-bottom: 120px;
        margin-left: auto;
        max-width: 305px;
        &_bigger {
            max-width: 365px; }
        &_big {
            max-width: 385px; }
        @include m {
            max-width: 320px;
            margin: 0; }
        &_xl {
            max-width: 515px;
            @include m {
                max-width: 435px; } }
        @include md {
            max-width: 100%;
            padding-left: 0;
            padding-right: 0;
            max-height: none;
            overflow: visible; } }
    &__title, &__title h4 {
        margin-bottom: 21px;
        font-size: 16px;
        letter-spacing: 0.6px;
        @include osB;
        position: relative;
        @include md {
            text-align: center;
            h4 {
                display: inline-block; } } }
    &__icon {
        position: absolute;
        left: -30px;
        top: -3px;
        font-size: 22px;
        @include md {
            position: static;
            display: inline-block;
            vertical-align: middle; } }
    &__textual {
        font-size: 16px;
        letter-spacing: 0.6px;
        line-height: 1.2;
        @include osSB;
        max-height: 330px;
        overflow: auto;
        padding-top: 5px;
        padding-bottom: 5px;
        padding-right: 15px;
        @include m {
            max-height: 250px; }
        @include md {
            max-height: 600px; }
        p {
            margin-bottom: 21px;
            &:last-child {
                margin-bottom: 0; } }
        ol {
            counter-reset: list;
            margin: 0; }

        ol > li {
            list-style: none;
            position: relative;
            margin-bottom: 21px;
            padding-left: 1.4em; }

        ol > li:before {
            counter-increment: list;
            content: counter(list) ") ";
            position: absolute;
            left: 0; } } }

.advice__textual::-webkit-scrollbar {
    width: 10px; }
.advice__textual::-webkit-scrollbar-track {
    -webkit-box-shadow: inset 0 0 6px rgba(51, 102, 154, 0.5);
    border-radius: 10px; }
.advice__textual::-webkit-scrollbar-thumb {
    border-radius: 10px;
    background-color: #33669a; }
