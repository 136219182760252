.flatpickr-booking {
  .flatpickr-day {
    &.disabled {
      background: #dcdcdc;
      border-color: #979797;
      position: relative;
      color: rgba($dark-gray, .5);

      &:hover,
      &:focus,
      &:active {
        background: #dcdcdc;
        color: rgba($dark-gray, .5); }

      &.today {
        background-color: #99cccc !important; } }

    &.selected,
    &.prevMonthDay.selected,
    &.nextMonthDay.selected {
      background-color: #009999;
      font-size: 12px;
      color: white;
      font-weight: bold;

      &:before {
        display: none; } } } }
.flatpickr-months .flatpickr-next-month,
.flatpickr-months .flatpickr-prev-month {
  color: #4a4a4a;
  fill: #4a4a4a; }
