.burger {
    width: 50px;
    height: 30px;
    // width: 23px
    // height: 18px
    position: relative;
    cursor: pointer;
    z-index: 51;
    span {
        position: absolute;
        top: 0;
        left: 50%;
        width: 23px;
        height: 2px;
        background-color: $dark-gray;
        top: 50%;
        transform: translate(-50%, -50%);
        transition: all .25s ease; }
    &::before, &::after {
        content: '';
        position: absolute;
        left: 50%;
        width: 23px;
        height: 2px;
        background-color: $dark-gray;
        transform: translate(-50%, -50%) rotate(0); }
    &::before {
        top: 8px; }
    &::after {
        bottom: 6px; }
    &.is-open {
        span {
            opacity: 0; }
        &:before {
            top: 50%;
            transition: top 0.15s ease-in, transform 0.15s ease 0.25s;
            transform: translate(-50%, -50%) rotate(45deg); }
        &:after {
            top: 50%;
            transition: top 0.15s ease-in, transform 0.15s ease 0.25s;
            transform: translate(-50%, -50%) rotate(-45deg); } } }
