.booking-list {
  &__head {
    display: flex;
    padding-left: 40px;
    text-align: center;
    border-bottom: 1px solid #777;
    width: 100%;

    @include m {
      display: none; }

    .booking-list__title {
      display: block;
      flex: 0 0 16.66%;
      max-width: 16.66%;
      @include osSB;
      font-size: 18px;
      color: $dark-gray;
      padding: 10px;
      letter-spacing: 1.9px; } }

  &__title {

    [class^="icon-"],
    [class*=" icon-"] {
      font-size: 20px;
      margin-left: 10px;

      @include rmin(600) {
        display: none; } }

    @include xs {
      font-weight: 600;
      @include osSB;
      font-size: 14px;
      letter-spacing: 1.5px; } }

  &__text {
    span {
      padding: 0 3px; } }

  &__row {
    display: flex;
    flex-direction: row;
    border-bottom: 1px solid #777;
    align-items: center;
    padding-left: 40px;
    min-height: 150px;
    padding-top: 10px;
    padding-bottom: 10px;

    @include m {
      padding-left: 0; }

    @include xs {
      flex-direction: column; } }

  &__item {
    display: flex;
    flex: 0 0 16.66%;
    width: 16.66%;
    padding: 0 10px;
    text-align: center;
    text-decoration: none;
    color: $dark-gray;
    font-size: 16px;
    letter-spacing: 1.1px;

    @include xs {
      padding: 0 20px; }

    &_info {
      @include xs {
        flex: 0 0 100%;
        min-width: 100%;
        align-items: center;
        justify-content: center;
        margin-top: 15px; } } }

  &__img {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include m {
      flex: 0 0 150px;
      max-width: 150px;

      .booking-list__title {
        display: none; }

      .booking-list__text {
        width: 100% !important;
        text-align: center !important; } }

    @include xs {
      flex: 1 0 100%;
      max-width: 100%;
      width: 100%;

      .booking-list__title {
        display: block; }

      .booking-list__text {
        width: 55% !important;
        text-align: right !important; } }

    img {
      display: block;
      max-width: 120px;
      min-width: 120px;
      width: 100%;
      max-height: 100%;
      object-fit: cover;
      border-radius: 7px;
      border: solid 1px #979797;

      @include xs {
        max-width: 300px; } }

    .booking-list__item_inner {
      @include xs {
        margin: 15px 0; } } }

  &__desc {
    display: flex;
    flex: 0 0 66.66%;
    width: 66.66%;
    padding: 0 10px;
    text-align: center;
    text-decoration: none;
    color: $dark-gray;
    position: relative;

    @include m {
      width: auto;
      flex-direction: column;
      padding: 0 20px; }

    @include md {
      flex: 1 0 0; }

    @include xs {
      flex: 0 0 100%;
      width: 100%; }

    &_item {
      display: flex;
      justify-content: space-between;
      padding: 2px; }

    &_color {
      @include m {
        background-color: #efefef; }

      @include xs {
        background-color: transparent; } } }

  &__item_inner + &__item_inner {
    padding-left: 20px;

    @include m {
      padding-left: 0; } }

  &__item_inner {
    width: 25%;
    max-width: 25%;
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    @include m {
      display: block;
      width: 100%;
      max-width: 100%;

      .booking-list__title {
        width: 40%;
        float: left;
        text-align: left; }

      .booking-list__text {
        width: 55%;
        float: right;
        text-align: right; } }

    @include xs {
      .booking-list__text {
        font-size: 14px; } } }

  &__title {
    display: none;

    @include m {
      display: block; } }

  &__message {
    @include m {
      display: flex;
      justify-content: space-between;
      margin-top: 25px; }

    @include xs {
      .booking-list__title {
        margin-bottom: 10px; } }

    &_wrap {
      position: relative;

      @include m {
        position: static; } }

    &_cut {
      max-height: 85px;
      overflow: hidden;

      @media screen and (-ms-high-contrast: active), screen and (-ms-high-contrast: none) {
        max-width: 250px;

        @media screen and (max-width: 1390px) {
          max-width: 190px; }

        @media screen and (max-width: 1200px) {
          max-width: 100%; } }

      @include md {
        max-height: 70px;
        padding: 0 0 0 10px; }

      &__link {
        margin-top: 4px;

        @include xs {
          position: absolute;
          right: 0;
          bottom: 3px;
          margin-top: 0;
          background-color: #fff;
          box-shadow: -12px 0px 15px #fff; } } }

    &_full {
      opacity: 0;
      pointer-events: none;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 370px;
      min-height: 220px;
      max-height: 250px;
      overflow: auto;
      background-color: $white;
      border: 1px solid #979797;
      @include bdrad;
      @include osR;
      font-size: 16px;
      letter-spacing: 1.8px;
      color: $dark-gray;
      padding: 40px 15px;
      text-align: center;
      z-index: 151;
      transition: opacity .25s ease;

      @include md {
        font-size: 14px;
        padding: 25px 10px;
        width: 320px;
        min-height: 170px; }

      @include sm {
        max-height: 400px; }

      @include xs {
        display: none; }

      &.is-open {
        opacity: 1;
        pointer-events: all; }

      &::-webkit-scrollbar {
        width: 5px; }

      &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #9c9c9c; } } }

  &__expiration {
    @include m {
      margin-top: 15px; } }

  &__payout {
    @include m {
      margin-top: 15px; } }

  &__actions {
    flex-direction: column;
    align-items: center;
    justify-content: center;

    @include m {
      flex: 0 0 150px;
      max-width: 150px;

      .btn {
        width: 100%;
        max-width: 262px;
        height: 35px;
        letter-spacing: 1px;
        font-size: 14px; } }

    @include md {
      flex: 0 0 100px;
      max-width: 100px; }

    @include xs {
      width: 100%;
      margin-top: 5px; }

    .btn {
      &:not(:last-child) {
        margin-bottom: 20px;

        @include xs {
          margin-bottom: 10px; } } } } }
