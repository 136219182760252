.hidden {
    display: none !important; }

.js-git-hash {
    position: absolute;
    left: 0;
    opacity: 0.7;
    word-break: break-all; }

.l {
    width: 100%;
    max-width: 1470px;
    padding-left: 15px;
    padding-right: 15px;
    margin: 0 auto; }

.logo {
    width: 100%;
    height: 100%;
    img {
        width: 100%;
        height: 100%; }

    &.beta {
        position: relative;
        &:before {
            position: absolute;
            content: 'beta';
            color: $aqua;
            font-size: 11px;
            top: 22px;
            right: -25px;
            width: 37px;
            font-weight: 700;
            overflow: hidden;
            line-height: 1;
            @include md {
                top: 12px;
                right: -27px; }
            @include xs {
                top: 4px;
                right: -35px; } } } }

.main {
    width: 100%;
    padding-top: 100px;
    margin: 0 auto;
    @include sm {
        padding-top: 79px; }
    @include xs {
        padding-top: 62px; }
    &_mt-common {
        margin-top: 100px;
        @include md {
            margin-top: 80px; }
        @include xs {
            margin-top: 50px; } }
    &_mb-common {
        margin-bottom: 100px;
        @include xs {
            margin-bottom: 70px; } }
    &_width-common {
        max-width: 1440px; } }

select {
    display: none; }

.is-mob {
    @include rmin(599) {
        display: none; } }

.is-tablet {
    @include rmin(768) {
        display: none; }

    &-hidden {
        @include r(767) {
            display: none; } } }

.is-desktop {
    @include xs {
        display: none; } }

.dis-none {
    display: none; }

.block-center {
    display: block;
    margin: 0 auto; }

.mob-full-w {
    @include xs {
        max-width: 100%;
        min-width: 100%;
        width: 100%; } }

.no-side-mg {
    margin-left: 0;
    margin-right: 0; }

.container_autowidth {
    max-width: none;
    width: auto; }

.col-reverse-mob {
    @include xs {
        display: flex;
        flex-direction: column-reverse; } }
.mt-lg {
    margin-top: 50px; }

.advantage {
    @include clr;
    color: $dark-gray;
    letter-spacing: 1.08px;
    margin-bottom: 31px;
    padding-left: 105px;
    position: relative;
    @include md {
        padding-left: 80px; }
    @include xs {
        padding-left: 0;
        margin-bottom: 10px; }
    &__icon {
        position: absolute;
        top: 0;
        left: 0;
        font-size: 50px;
        min-width: 70px;
        text-align: right;
        @include md {
            font-size: 40px;
            min-width: 60px; }
        @include xs {
            position: static;
            text-align: center;
            font-size: 50px; } }
    &__title {
        @include osB;
        @include fz-common;
        @include md {
            font-size: 14px; }
        @include xs {
            font-size: 16px;
            text-align: center; } }
    &__description {
        @include fz-common;
        @include md {
            font-size: 12px;
            letter-spacing: 0.81px; }
        @include xs {
            font-size: 14px;
            text-align: center; } } }

.menu-dropdown {
    position: absolute;
    padding-top: 20px;
    left: 50%;
    transform: translateX(-50%);
    min-width: 153px;
    text-align: center;
    z-index: 100;
    pointer-events: none;
    opacity: 0;
    visibility: hidden;
    transition: all .2s ease;
    a {
        text-decoration: none;
        color: $dark-gray;
        padding: 9px;
        width: 100%;
        height: 100%;
        display: block;
        transition: color .1s ease; }
    &__inner {
        border: 1px solid #009999;
        @include bdrad;
        overflow: hidden; }
    &__link {
        background-color: $white;
        color: $dark-gray;
        font-size: 16px;
        @include osR;
        letter-spacing: 1.08px;
        transition: all .1s ease;
        &:not(:last-child) {
            border-bottom: 1px solid #009999; }
        &:hover {
            background-color: #009999;
            a {
                color: #fff; } } } }

.social {
    font-size: 0;
    &__link {
        display: inline-block;
        vertical-align: top;
        text-decoration: none;
        color: $white;
        font-size: 36px;
        transition: color .2s ease;
        &:not(:last-child) {
            margin-right: 15px; }
        &:hover {
            color: #5973B1; } }
    &_circle {
        .social__icon {
            display: inline-flex;
            justify-content: center;
            align-items: center;
            text-decoration: none;
            width: 50px;
            height: 50px;
            border-radius: 50%;
            background-color: $white;
            font-size: 32px;
            &:not(:last-child) {
                margin-right: 20px; }
            @include md {
                width: 40px;
                height: 40px;
                font-size: 25px; }
            @include xs {
                width: 30px;
                height: 30px;
                font-size: 20px;
                &:not(:last-child) {
                    margin-right: 10px; } }
            &.is-active {
                .icon-heart {
                    &:before {
                        color: $purple; } } } } } }

.copy-link {
    height: 40px;
    width: 100%;
    border: 1px solid #979797;
    align-items: center;
    justify-content: space-between;
    padding: 12px 17px;
    display: none;
    &.is-open {
        display: flex; }
    &__btn {
        font-size: 16px;
        letter-spacing: 0.61px;
        color: #984a9d;
        @include osR;
        text-decoration: none;
        &:hover {
            text-decoration: underline; } }
    &__text {
        font-size: 12px;
        letter-spacing: 0.81px;
        @include osR;
        color: #9b9b9b; }

    &__message {
        display: none;
        text-align: center;
        color: $aqua;
        margin-top: 10px; } }

.cost-summ {
    padding: 0 35px 0 45px;
    @include md {
        padding: 0;
        color: #fff; }
    &__title {
        margin-bottom: 25px;
        @include md {
            margin-bottom: 15px; } }
    &__row {
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:not(:last-child) {
            margin-bottom: 25px;
            @include md {
                margin-bottom: 15px; } } }
    &__total {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:before {
            content: '';
            position: absolute;
            width: 100%;
            top: -10px;
            right: 0;
            height: 1px;
            background-color: #979797;
            @include md {
                width: 100%;
                top: -6px; } } } }

.contact-user {
    text-align: center;
    @include xs {
        margin-bottom: 22px; }
    &__avatar {
        width: 100px;
        height: 100px;
        border-radius: 50%;
        overflow: hidden;
        margin: 0 auto;
        margin-bottom: 7px;
        @include md {
            width: 80px;
            height: 80px; }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover; } }
    &__name {
        @include osR;
        font-weight: 400;
        letter-spacing: 1.08px;
        color: $dark-gray;
        font-size: 16px;
        margin-bottom: 16px; }
    &__btn {
        .btn {
            font-size: 16px;
            color: $dark-gray;
            @include osR;
            letter-spacing: 1.08px;
            min-height: 60px;
            padding: 5px 15px;
            line-height: 1.4;
            width: 180px;
            @include xs {
                font-size: 12px;
                min-height: 40px;
                width: 145px; }
            &:hover {
                background-color: #009999;
                color: $white; } }
        &_desktop {
            @include md {
                display: none; } }
        &_mob {
            @include rmin(1023) {
                display: none; } } } }

.organization-type {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: 30px;
    padding-top: 13px;
    @include xs {
        padding-top: 4px;
        flex-direction: column-reverse;
        align-items: flex-start; }
    &__right {
        width: 250px;
        margin-left: 10px;
        text-align: center;
        flex-wrap: wrap;
        @include xs {
            width: auto;
            display: flex;
            align-items: center;
            text-align: left;
            margin-left: 0; } }
    &__icon {
        font-size: 36px;
        color: $dark-gray;
        @include xs {
            font-size: 17px;
            margin-right: 10px; } }
    &__info {
        @include osR;
        font-weight: 400;
        font-size: 16px;
        color: $dark-gray;
        letter-spacing: 1.08px;
        margin-bottom: 5px;
        @include xs {
            margin-right: 15px;
            font-size: 10px;
            letter-spacing: 0.67px;
            margin-bottom: 0; } }
    &__name {
        color: #009999;
        font-size: 18px;
        @include osB;
        font-weight: 700;
        letter-spacing: 1.08px;
        margin-top: 15px; }
    &__btn {
        margin: 25px 0;
        @include xs {
            width: 100%; } } }

.icons-about {
    display: flex;
    justify-content: center;
    @include md {
        flex-wrap: wrap; }
    .breaker {
        @include md {
            display: block;
            width: 100%; } }
    .icons-about__circle {
        width: 80px;
        height: 80px;
        border-radius: 50%;
        position: relative;
        margin: 7px;
        @include xs {
            width: 40px;
            height: 40px;
            margin-bottom: 5px;
            margin-top: 5px;
            margin-left: percentage(3px/320px);
            margin-right: percentage(3px/320px); }
        span {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            font-size: 39px;
            @include xs {
                font-size: 20px; }
            &:before {
                color: #fff; } }
        .icon-hands, .icon-hand_heart {
            font-size: 30px;
            @include xs {
                font-size: 20px; } }
        .icon-moneybox, .icon-invalid, .icon-earth, .icon-recycle, .icon-imprint {
            font-size: 50px;
            @include xs {
                font-size: 20px; } }
        .icon-invalid {
            margin-left: 3px; }
        .icon-church {
            font-size: 45px;
            margin-top: -4px;
            @include xs {
                font-size: 23px; } } }
    &_green-bg {
        .icons-about__circle {
            background-color: #009999; } } }

.all-events {
    padding: 15px;
    padding-top: 100px;
    &__title {
        margin-bottom: 35px; } }

.error-msg {
    padding: 10px 20px;
    border: 2px solid $red;
    @include bdrad;
    text-align: center;
    display: flex;
    align-items: center;
    @include sm {
        padding: 10px; }
    @include xs {
        padding: 10px 20px; }
    &__text {
        color: $dark-gray;
        font-size: 24px;
        @include railM;
        letter-spacing: 2.57px;
        @include sm {
            font-size: 22px;
            letter-spacing: 2.36px; }
        @include xs {
            font-size: 12px;
            letter-spacing: 1.29px; } }
    &__icon {
        margin-right: 13px;
        margin-left: 20px;
        font-size: 36px;
        @include sm {
            margin: 0; }
        @include xs {
            font-size: 23px;
            margin-left: 20px; } } }

.like-btn {
    font-size: 25px;
    &.is-active {
        .icon-heart {
            &:before {
                color: #95479C; } } } }

.icon-pin {
    position: relative;
    .path1 {
        position: absolute;
        top: 0;
        left: 0; }
    .path2 {
        &:before {
            margin-left: 0;
            color: $purple;
            text-shadow: 0 2px 4px #4a4a4a; } } }

.complete-icon {
    &__circle {
        margin: 0 auto 3px;
        width: 41px;
        height: 41px;
        border-radius: 50%;
        border: 1px solid $purple;
        position: relative;
        &:before, &:after {
            content: '';
            position: absolute;
            width: 0px;
            height: 4px;
            background: $purple;
            border-radius: 5px; }
        &:before {
            transform: translate(12px, 17px) rotate(45deg);
            transform-origin: left;
            width: 10px; }
        &:after {
            transform: translate(14px, 18px) rotate(-45deg);
            width: 18px; } }
    &__text {
        margin: 0 auto;
        font-size: 11px;
        color: $purple;
        letter-spacing: 0.4px;
        @include osB; } }

.add-btn {
    color: #984a9d;
    letter-spacing: 0.61px;
    font-size: 16px;
    @include osR;
    font-weight: 400;
    text-decoration: none;
    position: relative;
    @include md {
        font-size: 14px;
        letter-spacing: 0.53px;
        max-width: 45px;
        display: inline-block; }
    @include xs {
        max-width: none; } }

.date-result {
    text-align: left;
    margin-top: 20px;
    display: none;
    padding: 10px 20px;
    border: 1px solid #9b9b9b;
    @include bdrad;
    align-items: center;
    font-size: 16px;
    color: $dark-gray;
    letter-spacing: 1.08px;
    @include osR;
    font-weight: 400;
    position: relative;
    @include md {
        font-size: 14px;
        letter-spacing: 0.94px; }
    @include xs {
        margin-top: 30px; }
    &__left {
        &:not(:empty) {
            width: 30%;
            margin-right: 10%; } }
    &__right {
        flex: 1; }
    &__close {
        position: absolute;
        width: 20px;
        height: 20px;
        border-radius: 50%;
        right: -10px;
        top: -10px;
        background-color: #777777;
        letter-spacing: 0.36px;
        cursor: pointer;
        &:before, &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 50%;
            width: 9px;
            height: 1px;
            background-color: $white; }
        &:before {
            transform: translate(-50%, -50%) rotate(55deg); }
        &:after {
            transform: translate(-50%, -50%) rotate(-55deg); } }

    &.has-result {
        display: flex; } }


.pre-message {
    position: relative;
    &__cut {
        max-height: 85px;
        overflow: hidden;
        @include md {
            height: 80px; } }
    &__link {
        margin-top: 4px;
        @include xs {
            position: absolute;
            right: 0;
            bottom: 3px;
            margin-top: 0;
            background-color: #fff;
            // padding: 0 5px
            box-shadow: -12px 0px 15px #fff; } }
    &__full {
        opacity: 0;
        pointer-events: none;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 370px;
        min-height: 220px;
        max-height: 500px;
        overflow: auto;
        background-color: $white;
        border: 1px solid #979797;
        @include bdrad;
        @include osR;
        font-size: 16px;
        letter-spacing: 1.8px;
        color: $dark-gray;
        padding: 40px 15px;
        text-align: center;
        z-index: 151;
        transition: opacity .25s ease;
        @include md {
            font-size: 14px;
            padding: 25px 10px;
            width: 320px;
            min-height: 170px; }
        @include sm {
            max-height: 400px; }
        @include xs {
            max-height: 350px; }
        &.is-open {
            opacity: 1;
            pointer-events: all; } } }

.pre-message__full {
    &::-webkit-scrollbar {
        width: 5px; } }
.pre-message__full {
    &::-webkit-scrollbar-thumb {
        border-radius: 10px;
        background-color: #9c9c9c; } }

.timetable {
    max-width: 380px;
    table {
        @include bdrad;
        border: 1px solid #979797;
        overflow: hidden;
        text-align: center;
        border-collapse: separate; }
    tr:not(:last-child) td {
        border-bottom: 1px solid #979797; }
    thead {
        background-color: #99cccc; }
    th, td {
        color: $dark-gray;
        @include osR;
        font-weight: 400;
        font-size: 16px;
        letter-spacing: 1.08px;
        border-left: 1px solid #979797;
        @include md {
            font-size: 14px; } }
    th {
        border-bottom: 1px solid #979797;
        padding: 10px;
        @include xs {
            padding: 10px 5px; } }
    td {
        padding: 15px 5px; }
    td:first-child, th:first-child {
        border-left: none; }
    &__edit {
        font-size: 16px;
        margin-top: 10px;
        letter-spacing: 0.61px; } }

.hint {
    display: inline-block;
    vertical-align: middle;
    font-size: 16px;
    margin-left: 5px;
    cursor: pointer;
    text-decoration: none;
    color: $dark-gray; }

.get-started {
    &__title {
        margin-bottom: 35px;
        text-align: center;
        @include md {
            margin-bottom: 20px; }
        @include xs {
            margin-bottom: 30px; } }
    &__subtitle {
        margin-bottom: 50px;
        text-align: center;
        @include md {
            margin-bottom: 25px; }
        @include xs {
            margin-bottom: 30px; } }
    &__notification {
        text-align: center;
        @include md {
            margin-top: 65px; }
        @include xs {
            margin-top: 0;
            padding-left: 25px;
            padding-right: 25px; } }
    &__start-steps {
        @include xs {
            margin-top: 50px; } } }

.info-modal {
    width: 100%;
    height: 100%;
    min-height: 60px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    padding: 0 40px;
    text-align: center;
    background-color: #99cccc;
    @include xs {
        padding: 0 33px;
        min-height: 50px; }
    [class^="icon-"], [class*=" icon-"] {
        margin: 0 10px;
        color: $dark-gray;
        font-size: 20px; }
    &__close {
        position: absolute;
        right: 12px;
        top: 50%;
        transform: translateY(-50%);
        width: 24px;
        height: 24px;
        cursor: pointer;
        @include xs {
            width: 20px;
            height: 20px; }
        &:before, &:after {
            content: '';
            position: absolute;
            top: 50%;
            left: 0;
            width: 100%;
            height: 1px;
            background-color: $dark-gray; }
        &:before {
            transform: translateY(-50%) rotate(45deg); }
        &:after {
            transform: translateY(-50%) rotate(-45deg); } }

    &_fixed {
        position: fixed;
        top: 100px;
        height: 50px;
        min-height: 50px;
        z-index: 999;
        transition: .3s;
        @include sm {
            top: 78px; }
        @include xs {
            top: 60px;
            font-size: 14px;

            span {
                display: none; } } }

    &.warning {
        background-color: $red;
        color: white;

        [class^="icon-"] {
            &:before {
                color: white; } } } }

.empty-block {
    $this: &;
    text-align: center;
    &__icon {
        font-size: 116px;
        color: #e2e1e2;
        [class^="icon-"], [class*=" icon-"] {
            &:before {
                color: #e2e1e2; } } }
    &__text {
        font-size: 16px;
        letter-spacing: 1.08px;
        @include osR;
        color: #9b9b9b;
        @include xs {
            font-size: 14px;
            letter-spacing: 0.94px; } }
    a {
        color: #984a9d;
        text-decoration: none;
        &:hover {
            text-decoration: underline; } }
    p {
        margin-bottom: 20px; }
    &_common {
        #{$this}__icon {
            font-size: 149px;
            @include xs {
                font-size: 100px; } }
        #{$this}__text {
            max-width: 545px;
            margin: 0 auto;
            padding: 0 15px;
            @include xs {
                padding: 0 30px; } } } }

.start-steps {
    text-align: center;
    &__cards {
        display: flex;
        @include m {
            flex-wrap: wrap;
            justify-content: center; }
        @include xs {
            &:not(.slick-initialized) {
                .choose-card:not(:first-child) {
                    display: none; } } } // before slider init
        .choose-card {
            width: calc(100%/3);
            @include m {
                width: calc(50% - 35px);
                margin-bottom: 30px; }
            @include md {
                width: 100%;
                max-width: 324px;
                margin-left: auto;
                margin-right: auto;
                margin-bottom: 25px; }
            @include xs {
                margin-bottom: 5px;
                max-width: 269px; }
            @include r(330) {
                max-width: 260px; }
            &:not(:last-child) {
                margin-right: 35px;
                @include md {
                    margin-right: auto; } } } } }

.information {
    max-width: 380px;
    font-size: 14px;
    position: relative;
    padding-left: 8px;
    letter-spacing: 0.9px;
    @include osR;
    @include xs {
        font-size: 12px; }
    &:before {
        position: absolute;
        content: '*';
        top: 0;
        left: 0; } }

.expander {
    &__head {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        .subtitle {
            margin-right: 20px;
            color: $purple; } }
    &__content {
        padding-top: 30px;
        @include md {
            padding-top: 20px; } }
    &__close-icon {
        width: 20px;
        height: 20px;
        position: relative;
        transform: scale(1, 1);
        transition: all .25s ease;
        &:before, &:after {
            content: '';
            position: absolute;
            top: 50%;
            right: 0;
            height: 2px;
            width: 16px;
            background-color: $purple;
            @include sm {
                width: 10px; } }
        &:before {
            transform: rotate(45deg);
            right: 11px;
            @include sm {
                right: 6px; } }
        &:after {
            transform: rotate(-45deg); } }
    &.is-open {
        .expander__close-icon {
            transform: scale(-1, -1);
            margin-left: -7px;
            @include sm {
                margin-left: 4px; } } } }

.hidden-mob {
    @include m {
        display: none; } }


.pac-container.pac-logo {
    &:after {
        display: none !important; } }

.tox .tox-toolbar--scrolling {
  flex-wrap: wrap !important; }

.js-popup-link {
  cursor: pointer; }
