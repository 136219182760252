@font-face {
  font-family: 'icomoon';
  src:  url('fonts/icomoon.eot?895qk2');
  src:  url('fonts/icomoon.eot?895qk2#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?895qk2') format('truetype'),
    url('fonts/icomoon.woff?895qk2') format('woff'),
    url('fonts/icomoon.svg?895qk2#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="icon-"], [class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'icomoon' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow-down:before {
  content: "\e93c";
  color: #515151;
}
.icon-arrow-left:before {
  content: "\e93d";
  color: #515151;
}
.icon-arrow-right:before {
  content: "\e93e";
  color: #515151;
}
.icon-arrow-up:before {
  content: "\e93f";
  color: #515151;
}
.icon-remove:before {
  content: "\e940";
  color: #515151;
}
.icon-attachment:before {
  content: "\e93b";
}
.icon-check:before {
  content: "\e939";
}
.icon-double-check:before {
  content: "\e93a";
}
.icon-img_placeholder:before {
  content: "\e938";
  color: #9b9b9b;
}
.icon-clock:before {
  content: "\e937";
  color: #4a4a4a;
}
.icon-youtube-brands:before {
  content: "\e936";
}
.icon-star-regular:before {
  content: "\e935";
}
.icon-heart-regular:before {
  content: "\e934";
}
.icon-envelope-regular:before {
  content: "\e933";
}
.icon-quotes:before {
  content: "\e932";
  color: #e2e1e2;
}
.icon-camera:before {
  content: "\e930";
  color: #099;
}
.icon-gallery:before {
  content: "\e931";
  color: #099;
}
.icon-bank:before {
  content: "\e92e";
  color: #4a4a4a;
}
.icon-search-solid:before {
  content: "\e92f";
}
.icon-info-circle-solid:before {
  content: "\e92d";
}
.icon-dollar-sign-solid:before {
  content: "\e92c";
}
.icon-clipboard-regular:before {
  content: "\e92b";
}
.icon-star-solid:before {
  content: "\e92a";
}
.icon-calendar-check:before {
  content: "\e929";
}
.icon-gear:before {
  content: "\e926";
}
.icon-profile:before {
  content: "\e927";
  color: #fff;
}
.icon-link:before {
  content: "\e920";
  color: #fff;
}
.icon-letter2:before {
  content: "\e921";
  color: #fff;
}
.icon-share:before {
  content: "\e91f";
  color: #4a4a4a;
}
.icon-user-circle-regular:before {
  content: "\e928";
}
.icon-user-circle-solid:before {
  content: "\e925";
}
.icon-facebook2:before {
  content: "\e922";
}
.icon-pinterest:before {
  content: "\e923";
}
.icon-twitter2:before {
  content: "\e924";
}
.icon-users:before {
  content: "\e91e";
}
.icon-credit-card:before {
  content: "\e91b";
}
.icon-paypal:before {
  content: "\e91c";
}
.icon-stripe:before {
  content: "\e91d";
}
.icon-page_up:before {
  content: "\e91a";
  color: #099;
}
.icon-lightbulb:before {
  content: "\e919";
  color: #4a4a4a;
}
.icon-letter:before {
  content: "\e917";
  color: #fff;
}
.icon-phone:before {
  content: "\e918";
  color: #fff;
}
.icon-hand_heart:before {
  content: "\e916";
  color: #fff;
}
.icon-list:before {
  content: "\e915";
  color: #4a4a4a;
}
.icon-pin .path1:before {
  content: "\e913";
  color: rgb(255, 255, 255);
}
.icon-pin .path2:before {
  content: "\e914";
  margin-left: -1em;
  color: rgb(0, 0, 0);
}
.icon-heart:before {
  content: "\e912";
  color: #4a4a4a;
}
.icon-recycle:before {
  content: "\e911";
  color: #099;
}
.icon-empty_page:before {
  content: "\e903";
  color: #9cc;
}
.icon-warning:before {
  content: "\e909";
  color: #d0021b;
}
.icon-hands:before {
  content: "\e908";
  color: #099;
}
.icon-facebook:before {
  content: "\e900";
}
.icon-instagram:before {
  content: "\e901";
}
.icon-twitter:before {
  content: "\e902";
}
.icon-people:before {
  content: "\e907";
  color: #099;
}
.icon-earth:before {
  content: "\e904";
  color: #099;
}
.icon-moneybox:before {
  content: "\e905";
  color: #099;
}
.icon-coin:before {
  content: "\e906";
  color: #099;
}
.icon-invalid:before {
  content: "\e90f";
  color: #099;
}
.icon-church:before {
  content: "\e90c";
  color: #099;
}
.icon-imprint:before {
  content: "\e90d";
  color: #099;
}
.icon-medical-bag:before {
  content: "\e90e";
  color: #099;
}
.icon-cash:before {
  content: "\e90a";
  color: #9cc;
}
.icon-deal:before {
  content: "\e90b";
  color: #9cc;
}
.icon-mail:before {
  content: "\e910";
  color: #9cc;
}
