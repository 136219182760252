body {
	@include osR;
	@include fz-common;
	line-height: 1.4;
	&.is-hidden {
		position: fixed;
		overflow: hidden;
		left: 0;
		top: 0;
		width: 100%;
		height: 100%; } }

.content-wrapper {
	padding-left: 11.18vw;
	padding-right: 11.18vw;
	padding-bottom: 10px;
	min-height: 687px;
	display: flex;
	flex-direction: column;
	@include md {
		padding-left: 8.6vw;
		padding-right: 8.6vw;
		min-height: 661px; }
	@include sm {
		padding-left: 5vw;
		padding-right: 5vw;
		min-height: 584px; }
	&_mt {
		margin-top: 100px;
		@include md {
			margin-top: 85px; }
		@include sm {
			margin-top: 62px; } }
	&_pt {
		padding-top: 136px;
		@include md {
			padding-top: 108px; }
		@include xs {
			padding-top: 80px; } }
	&_pt-sm {
		padding-top: 101px;
		@include md {
			padding-top: 76px; }
		@include xs {
			padding-top: 42px; } }
	&_pb-lg {
		padding-bottom: 379px; }
	&_pb-md {
		padding-bottom: 118px; }
	&_center {
		align-items: center; }
	&_row {
		flex-direction: row; }
	&_px-lg {
		padding-left: 14.35vw;
		padding-right: 14.35vw;
		@include r(1380) {
			padding-left: 4.95vw;
			padding-right: 4.95vw; } }
	&_height-mob-md {
		@include md {
			min-height: auto;
			padding-bottom: 100px; } } }


