.notification {
    display: inline-flex;
    flex-direction: column;
    text-decoration: none;
    text-align: left;
    &__row {
        display: flex;
        align-items: center;
        text-decoration: none;
        &:not(:last-child) {
            margin-bottom: 20px;
            @include md {
                margin-bottom: 40px; }
            @include xs {
                margin-bottom: 25px; } } }
    &__icon {
        width: 70px;
        height: 70px;
        flex: 0 0 70px;
        background-color: #99cccc;
        border-radius: 50%;
        font-size: 32px;
        display: flex;
        justify-content: center;
        align-items: center;
        margin-right: 10px;
        @include md {
            width: 50px;
            height: 50px;
            flex: 0 0 50px;
            font-size: 22px;
            margin-right: 20px; }
        @include xs {
            width: 35px;
            height: 35px;
            flex: 0 0 35px;
            font-size: 16px; }
        [class^="icon-"], [class*=" icon-"] {
            &:before {
                color: $dark-gray; } }
        .icon-letter {
            font-size: 25px;
            @include md {
                font-size: 18px; }
            @include xs {
                font-size: 14px; } } }
    &__text {
        font-size: 23px;
        @include osR;
        color: $dark-gray;
        letter-spacing: 2.46px;
        padding-left: 10px;
        @include md {
            font-size: 18px;
            letter-spacing: 1.93px; }
        @include xs {
            font-size: 14px;
            letter-spacing: 1.5px; } }
    &__amount {
        margin-right: 10px;
        @include md {
            margin-right: 5px; } } }


