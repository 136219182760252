.modal {
    // margin-top: percentage(107px/953px)
    margin-top: 207px;
    margin-bottom: percentage(141px/953px);
    box-shadow: 0 34px 24px rgba(0, 0, 0, 0.38), 0 15px 14px rgba(0, 0, 0, 0.27), 0 2px 4px rgba(0, 0, 0, 0.11);
    border-radius: 5px;
    margin-left: auto;
    margin-right: auto;
    @include md {
        margin-top: 190px; }
    @include sm {
        margin-top: 200px; }
    @include xs {
        margin-top: 100px; }
    &_default {
        max-width: 628px;
        @include sm {
            max-width: 444px; }
        @include xs {
            max-width: 100%;
            width: 100%; } }
    &_no-shadow-mob {
        @include xs {
            box-shadow: none;
            margin-bottom: 0; } }
    &__content {
        padding: 0 73px 61px 74px;
        position: relative;
        @include sm {
            padding: 0 30px 100px; } }
    &__form {
        padding-top: 55px;
        @include xs {
            padding-top: 30px; } }
    &__title {
        letter-spacing: 2px;
        @include railM;
        color: $dark-gray;
        @include xs {
            font-size: 14px; } } }
