.residence-cards {
    font-size: 0;
    margin-left: -7px;
    margin-right: -7px;
    // width: percentage(1106px/1440px)
    // margin: 0 auto
    text-align: center;
    &__amount {
        font-size: 12px;
        @include osR;
        letter-spacing: 0.92px;
        color: $dark-gray;
        text-align: left;
        padding-bottom: 15px; } }
.residence-card {
    position: relative;
    display: inline-block;
    vertical-align: top;
    text-decoration: none;
    margin: 7px;
    margin-bottom: 38px;
    padding-bottom: 20px;
    width: calc(100%/3 - 14px);
    max-width: 370px;
    text-align: left;
    box-shadow: none;
    transform: translateY(0);
    transition: box-shadow .35s ease, transform .25s ease;
    @include sm {
        margin: 12px;
        width: calc(100%/2 - 24px);
        margin-bottom: 38px; }
    @include xs {
        width: 100%;
        max-width: none;
        margin: 0;
        margin-bottom: 40px; }
    &__inner {
        text-decoration: none;
        display: block;
        overflow: hidden;
        position: relative; }
    &__description {
        margin-top: 5px;
        padding: 0 5px; }

    &__title {
        color: $dark-gray;
        font-size: 16px;
        letter-spacing: 0.88px;
        @include osB; }
    &__subtitle {
        color: $dark-gray;
        font-size: 14px;
        @include osL;
        letter-spacing: 0.61px;
        margin-bottom: 5px; }
    &__price {
        color: $dark-gray;
        font-size: 14px;
        @include osR;
        letter-spacing: 0.77px;
        float: left; }
    &__images {
        width: 100%;
        position: relative;
 }        // height: 225px
    &__image {
        width: 100%;
        // max-width: 360px
        height: 225px;
        position: relative;
        border: 1px solid #979797;
        @include md {
            height: 185px; }
        @include sm {
            height: 225px; }
        img {
            width: 100%;
            height: 100%;
            object-fit: cover; } }
    &__like-btn {
        position: absolute;
        display: block;
        top: 12px;
        right: 13px;
        font-size: 25px;
        z-index: 15;
        cursor: pointer;
        -webkit-transform: translate3d(0,0,0);
        background: white;
        width: 35px;
        height: 35px;
        text-align: center;
        border-radius: 50%;
        line-height: 41px; }

    &__discount {
        width: 220px;
        height: 50px;
        position: absolute;
        top: 10px;
        left: -73px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        background-color: $aqua;
        font-size: 12px;
        @include osSB;
        font-weight: 900;
        color: white;
        letter-spacing: 1px;
        transform: rotate(-45deg);
        text-shadow: 1px 1px rgba(black, .5); }

    &__rating {
        float: right; }
    &__bottom {
        @include clr; }
    .next, .prev {
        top: 115px;
        @include md {
            top: 95px; }
        @include sm {
            top: 115px; } }
    &:hover {
        box-shadow: 0 10px 20px rgba(86,86,86,0.2); }
    &.is-marker-hovered {
        box-shadow: 0 10px 20px rgba(86,86,86,0.2); } }



.infoBox {
    max-width: 317px;
    @include xs {
        max-width: 250px;
        margin-left: 25px; }
    .residence-card__image, .search-map_half-map .residence-card__image {
        height: 206px!important; }
    .residence-card__inner {
        padding-bottom: 10px; }
    & > img {
        position: absolute!important;
        right: -18px;
        top: -18px;
        z-index: 1000; } }

