.videos {
  width: 100%;
  height: 100%;
  position: relative;
  z-index: 10;
  text-align: center;

  @include xs {
    max-width: 260px;
    margin: 0 auto; }

  &__row {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;

    @include xs {
      display: block; } }

  &_sm {
    .video {
      max-width: 344px;
      width: percentage(344px/1255px);
      position: relative;

      @include md {
        max-width: 225px;
        width: 100%;
        margin-bottom: 30px; }

      @include xs {
        max-width: 260px;
        padding-left: 5px;
        padding-right: 5px;
        margin-bottom: 0; }

      &:not(:last-child) {
        margin-right: 17px;

        @include md {
          margin-right: 8px; } }

      &__inner {
        position: relative; }

      &__image {
        max-height: 286px;
        width: 100%;
        height: 100%;

        @include md {
          height: 255px; }

        @include xs {
          height: 220px; }

        img {
          object-fit: cover;
          width: 100%;
          height: 100%; } } } } }


.video {
  height: 100%;
  width: 100%;

  &__title {
    margin-top: 32px;
    @include osR;
    @include fz-md;
    color: $dark-gray;
    letter-spacing: 2.46px;
    text-align: center;

    @include md {
      margin-top: 20px; }

    @include xs {
      font-size: 18px;
      letter-spacing: 1.93px;
      margin-top: 19px; } }

  &__description {
    @include xs {
      @include osR;
      font-size: 12px;
      color: $dark-gray;
      letter-spacing: 1.28px;
      margin-top: 20px;
      text-align: center; } }

  &__bg {
    position: absolute;
    top: 0;
    left: 0;
    background-size: 100% 100%;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat; }

  &__btn {
    display: block;
    position: absolute;
    z-index: 5;
    left: 50%;
    transform: translate(-50%);

    img {
      width: 100%;
      height: 100%; }

    &_main {
      width: 120px;
      height: 120px;
      top: 34%;

      @include md {
        width: 90px;
        height: 90px;
        top: 38%; }

      @include xs {
        width: 41px;
        height: 41px; } }

    &_white {
      width: 78px;
      height: 78px;
      top: 37%;

      @include md {
        width: 60px;
        height: 60px; }

      @include xs {
        width: 40px;
        height: 40px;
        top: 41%; } } } }
