.textarea {
    &__label {
        color: $dark-gray;
        @include osR;
        font-size: 16px;
        letter-spacing: 1.08px;
        margin-bottom: 20px; }
    &__input {
        position: relative;
        textarea::placeholder {
            @include osL; }
        textarea {
            padding: 8px 14px 20px 16px;
            color: $dark-gray;
            font-size: 16px;
            @include osR;
            letter-spacing: 1.08px;
            resize: none;
            height: 100%;
            width: 100%;
            border: 1px solid #979797;
            @include bdrad;
            overflow: hidden;
            @include xs {
                font-size: 14px; } }
        &_italic {
            textarea::placeholder {
                font-style: italic; } }
        &_height-l {
            textarea {
                min-height: 200px; } }
        &_height-md {
            textarea {
                min-height: 160px; } }
        &_height-s {
            textarea {
                min-height: 130px; } }
        &_height-m {
            textarea {
                min-height: 115px; } }
        &_height-sm {
            textarea {
                min-height: 100px; } }
        &_grey {
            textarea, textarea::placeholder {
                color: #9b9b9b;
                font-weight: 400; } } }

    &__counter {
        @include osR;
        position: absolute;
        bottom: 10px;
        right: 10px;
        letter-spacing: 0.88px;
        color: #9b9b9b;
        font-size: 11px; }
    &_width-sm {
        max-width: 380px; }
    &_width-s {
        max-width: 400px; }
    &_width-md {
        max-width: 440px; }
    &_width-m {
        max-width: 470px; }
    &_mob-full {
        @include xs {
            width: 100%; } }
    &_bd-wider {
        textarea {
            border-width: 2px; } }
    &_no-radius {
        textarea {
            border-radius: 0; } } }


