.card {
	max-width: 493px;
	box-shadow: 0 8px 20px rgba(0, 0, 0, 0.5);
	border-radius: 7px;
	border: 3px solid #979797;
	background-color: #ffffff;
	color: #4a4a4a;
	font-size: 23px;
	letter-spacing: 1.08px;
	padding: 54px 45px;
	@include md {
		font-size: 18px;
		letter-spacing: 0.8px;
		padding: 37px 15px 52px 18px; }
	@include sm {
		max-width: 100%;
		font-size: 14px;
		letter-spacing: 0.66px;
		padding: 25px 15px 51px 13px;
		min-height: 313px; }
	@include xs {
		box-shadow: 0 8px 10px rgba(0, 0, 0, 0.35); }
	&__title {
		@include osSB;
		margin-bottom: 30px;
		@include md {
			margin-bottom: 24px; }
		@include sm {
			margin-bottom: 19px; } }
	&__list-item {
		@include osR;
		position: relative;
		padding-left: 16px;
		@include md {
			padding-left: 12px; }
		@include sm {
			padding-left: 9px; }
		&:not(:last-child) {
			margin-bottom: 30px;
			@include md {
				margin-bottom: 24px; }
			@include sm {
				margin-bottom: 19px; } }
		&:before {
			position: absolute;
			content: '-';
			left: 0;
			top: 0; } } }

.two-cards {
	padding: 74px 0 97px;
	background-color: #99cccc;
	@include md {
		padding-bottom: 80px; }
	@include sm {
		padding-top: 39px; }
	.slick-slide {
		margin: 0 10px; }
	.slick-list {
		padding: 0 20px;
		@include xs {
			padding: 0; } }

	&__inner {
		max-width: 1105px;
		margin: 0 auto;
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		@include md {
			padding: 0 25px;
			display: block; }
		@include sm {
			padding: 0; } }
	&__title {
		color: #4a4a4a;
		font-size: 45px;
		letter-spacing: 2.46px;
		@include railR;
		margin-bottom: 67px;
		text-align: center;
		@include md {
			margin-bottom: 37px; }
		@include sm {
			font-size: 32px;
			letter-spacing: 1.75px;
			line-height: 1.2;
			margin-bottom: 20px; } }
	&__content {
		display: flex;
		margin-bottom: 103px;
		@include md {
			margin-bottom: 80px; }
		@include sm {
			display: block;
			margin-bottom: 50px; } }
	&__card {
		max-width: 100%;
		width: calc(50% - 52px);
		@include md {
			width: calc(50% - 12px); }
		@include sm {
			width: 100%;
			margin-bottom: 20px; }
		&:not(:last-child) {
			margin-right: 93px;
			@include md {
				margin-right: 24px; }
			@include sm {
				margin-right: 0; } } } }
