.rating {
	font-size: 0;
	width: auto;
	position: relative;
	overflow: hidden;

	&__wrap {
		float: left;
		width: auto; }

	&__choose {
		.rating__star {
			cursor: pointer;
			float: right;
			&.checked,
			&.checked ~ i,
			&:hover,
			&:hover ~ i {
				svg {
					fill: #009999;
					stroke: #33cccc; } } }
		+ .error-text {
			display: none; }

		&.has-error {
			.rating__wrap {
				margin-bottom: 10px; }

			+ .error-text {
				display: block; }

			svg {
				stroke: $red; } } }

	&__star {
		display: inline-block;
		width: 19px;
		height: 19px;
		padding-right: 3px;

		svg {
			display: block;
			width: 100%;
			height: 100%;
			fill: transparent;
			stroke: #000;
			stroke-width: 50px; }
		&.is-full {
			svg {
				fill: #009999;
				stroke: #33cccc; } }
		&_lg {
			width: 28px;
			height: 28px;
			margin-right: 8px;
			@include md {
				width: 18px;
				height: 18px;
				margin-right: 4px; }
			@include xs {
				width: 16px;
				height: 16px;
				margin-right: 2px; } }
		&_spec {
			svg {
				fill: $white;
				stroke-width: 15px; }
			&.is-full {
				svg {
					fill: #009999;
					stroke: #009999; } } } } }

