.editor {
  ul,
  ol {
    padding-left: 18px;
    margin-bottom: 15px; }

  ul {
    list-style-type: disc; }

  ol {
    list-style-type: decimal; } }
