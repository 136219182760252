.review {
    margin-bottom: 35px;
    @include md {
        margin-bottom: 30px; }
    &__head {
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding-left: 25px;
        margin-bottom: 10px;
        @include xs {
            justify-content: flex-start; } }
    &__info {
        display: flex;
        align-items: center;
        justify-content: space-between;
        flex-grow: 1;
        @include xs {
            flex-direction: column;
            flex-grow: 0; } }
    &__avatar {
        width: 70px;
        height: 70px;
        flex: 0 0 70px;
        border-radius: 50%;
        margin-right: 30px;
        overflow: hidden;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover; } }
    &__rating {
        margin-left: auto;
        //margin-right: 35px
        @include xs {
            margin: 0; }
        .rating__star {
            width: 14px;
            height: 14px; } }
    p {
        margin-bottom: 0; }
    &_answer {
        position: relative;
        max-width: 448px;
        margin-left: auto;
        margin-bottom: 50px;
        background-color: #f2f2f2;
        @include bdrad;
        padding: 15px;
        padding-left: 20px;
        @include md {
            margin-bottom: 40px; }
        @include xs {
            &:before {
                bottom: 100%;
                right: 85%;
                position: absolute;
                content: '';
                background-color: #f2f2f2;
                width: 0;
                height: 0;
                border-left: 10px solid #fff;
                border-right: 10px solid #fff;
                border-bottom: 10px solid #f2f2f2; } }
        .review__head {
            margin-bottom: 30px;
            padding-left: 8px;
            @include md {
                margin-bottom: 10px; } }
        .review__date {
            padding-bottom: 10px; } } }

.reviews {
    &__btn {
        text-align: center;
        margin-top: 80px;
        margin-bottom: 15px;
        @include md {
            margin-top: 50px; }
        @include xs {
            margin-top: 30px; } } }
