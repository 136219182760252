.upload {
    max-width: 440px;
    height: 200px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #979797;
    @include bdrad;
    position: relative;
    &__btn {
        width: 92px;
        height: 34px;
        border-radius: 3px;
        background-color: #d8d8d8;
        @include osR;
        font-weight: 400;
        letter-spacing: 0.6px;
        color: $dark-gray;
        font-size: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer; }
    input[type="file"] {
        width: 100%;
        height: 100%;
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        opacity: 0;
        cursor: pointer; } }

.upload-drag {
    border-radius: 7px;
    border: 2px dashed #D0D9FD;
    height: 125px;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    position: relative;
    input[type="file"] {
        opacity: 0;
        left: 0;
        top: 0;
        visibility: hidden;
        position: absolute;
        width: 100%;
        height: 100%; }
    &__text {
        text-transform: uppercase;
        color: $dark-gray;
        @include osSB;
        font-size: 14px;
        letter-spacing: 1.28px;
        line-height: 2; }
    label {
        color: #7B90F8;
        cursor: pointer;
        &:hover {
            text-decoration: underline; } } }


.upload-avatar {
    &__title {
        @include osSB;
        font-size: 16px;
        letter-spacing: 0.66px;
        color: $dark-gray;
        margin-bottom: 25px;
        @include xs {
            margin-bottom: 20px; } }
    &__row {
        display: flex;
        align-items: flex-start; }
    &__photo {
        width: 116px;
        height: 116px;
        flex: 0 0 116px;
        border-radius: 50%;
        overflow: hidden;
        margin-right: 50px;
        @include md {
            width: 77px;
            height: 77px;
            flex: 0 0 77px;
            margin-right: 40px; }
        @include xs {
            width: 50px;
            height: 50px;
            flex: 0 0 50px;
            margin-right: 10px; }
        span, img {
            display: block;
            width: 100%;
            height: 100%;
            object-fit: cover; } }
    &__default {
        font-size: 116px;
        color: $dark-gray;
        @include md {
            font-size: 77px; }
        @include xs {
            font-size: 50px; } }
    &__btn {
        margin-top: 20px;
        @include md {
            display: none; } }
    &__adapt-btns {
        margin-top: 15px;
        .btn {
            @include xs {
                padding-left: 5px;
                padding-right: 5px; } }
        @include rmin(1024) {
            display: none; } }
    &__desc {
        font-size: 16px;
        @include osR;
        color: $dark-gray;
        letter-spacing: 0.66px;
        max-width: 320px;
        @include xs {
            font-size: 14px;
            letter-spacing: 0.94px; } }
    &__browse {
        margin-top: 20px;
        max-width: 280px;
        padding: 5px;
        @include md {
            display: none; } }
    &__save {
        margin-left: 140px;
        margin-top: 120px;
        @include md {
            margin-left: 117px;
            margin-top: 50px; }
        @include xs {
            margin-left: auto;
            margin-right: auto;
            text-align: center;
            margin-top: 35px; }
        @include r(330) {
            padding-left: 25px; } } }

